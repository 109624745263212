import { usePlaybook } from "../../../controller/hooks/playbook";
import { useOrg } from "../../../../../auth/org-provider";
import {
    Cadence,
    getMeetingCadenceFromSegment,
    getMeetingDurationFromSegment,
    getMeetingParticipantsFromSegment,
} from "../todos";
import { Calendar, CheckIcon, ExternalLink } from "lucide-react";
import React from "react";
import { Todo } from "../todos-page";
import { AiPlug } from "../../../../../components/shared/ai-plug";
import { AiButton } from "../../../../../components/shared/ai-button";
import { prompts } from "../../../../../service/prompts";
import { TekkrMarkdown } from "../../../../../components/shared/markdown/tekkr-markdown";
import { useBlueprint } from "../../../controller/hooks/blueprint";
import { PlaybookTodoType } from "tekkr-common/dist/model/playbook/segment/blueprint";
import { PlaybookTodoBlueprint } from "tekkr-common/dist/model/playbook/stages/implementation/blueprint";
import { useScheduleEvent } from "../../../../../lib/event-scheduling";
import { useMutation } from "@tanstack/react-query";
import { OrgUser } from "../../../../../service/tekkr-service-types";
import {
    ScheduledPlaybookMeeting,
    useScheduledPlaybookMeetings,
} from "../../../controller/hooks/scheduled-playbook-meetings";
import _ from "lodash";
import { DateTime } from "luxon";
import { AvatarForUser } from "../../../../../components/shared/avatar-for-user";

type ScheduleFunctionInput = { group: true } | { userId: string };

type ScheduleFunction = (input: ScheduleFunctionInput) => Promise<void>;

interface Meeting {
    title: string;
    recur: Cadence | null;
    duration: number;
    participants: OrgUser[];
}

type SchedulingViewProps  = {
    schedule: ScheduleFunction;
    meeting: Meeting;
    todo: Todo;
}

function ScheduledMeetingsPreview (props: {
    meetings: ScheduledPlaybookMeeting[];
}) {
    const meetings = _.sortBy(props.meetings ?? [], "start");

    if (!meetings.length) {
        return null;
    }

    return <div className={"flex flex-row items-center gap-2 text-sm font-medium text bg-confirmation/20 rounded-md px-3 py-1"}>
        <CheckIcon className={"text-confirmation w-5 h-5"} />
        <div className={"opacity-85 flex flex-col"}>
            <span>Scheduled for { DateTime.fromISO(meetings![0].start).toLocaleString({ weekday: "short", month: "long", day: "numeric", hour: "numeric", minute: "numeric" }) }</span>
            { meetings.length > 1 && <>
                <span className={"opacity-65"}>also scheduled at { meetings!.length - 1 } other { meetings!.length > 2 ? "times" : "time" }.</span>
            </>}
        </div>
    </div>
}

function GroupScheduling (props: SchedulingViewProps) {
    const { mutate: schedule, isPending } = useMutation({
        mutationFn: async () => {
            await props.schedule({ group: true });
        }
    })
    const { meetings } = useScheduledPlaybookMeetings({
        scopeFilter: meetingScopeGroup(props.todo.id),
    });
    return <div className={"flex flex-row items-center gap-4"}>
        <AiButton variant={meetings?.length ? "outline" : undefined} onClick={() => schedule()} isLoading={isPending}>
            <ExternalLink className={"me-2 h-5 w-5"} />
            Schedule Meeting
        </AiButton>
        { meetings && <ScheduledMeetingsPreview meetings={meetings} /> }
    </div>
}

function IndividualSchedulingItem (props: {
    todoId: string;
    participant: OrgUser;
    schedule: ScheduleFunction;
}) {
    const { mutate: schedule, isPending } = useMutation({
        mutationFn: async () => {
            await props.schedule({ userId: props.participant.id });
        }
    })
    const { meetings } = useScheduledPlaybookMeetings({
        scopeFilter: meetingScopeIndividual(props.todoId, props.participant.id),
    });
    return <tr>
        <td className={"font-medium pe-2"}>
            <div className={"flex flex-row items-center gap-2"}>
                <AvatarForUser className={"w-7 h-7"} user={props.participant} />
                { props.participant.name }
            </div>
        </td>
        <td className={"px-2 py-1"}>
            <AiButton size={"sm"} variant={meetings?.length ? "outline" : undefined} onClick={() => schedule()} isLoading={isPending}>
                <ExternalLink className={"me-2 h-5 w-5"} />
                Schedule Meeting
            </AiButton>
        </td>
        <td>
            { meetings && <ScheduledMeetingsPreview meetings={meetings} /> }
        </td>
    </tr>
}

function IndividualScheduling (props: SchedulingViewProps) {
   if (!props.meeting.participants.length) {
       return <GroupScheduling {...props} />
   }

   return <table>
       <tbody>
           { props.meeting.participants.map(participant => (
               <IndividualSchedulingItem key={participant.id} todoId={props.todo.id} participant={participant} schedule={props.schedule} />
           ))}
       </tbody>
   </table>
}

export function MeetingTodoContent(props: { todo: Todo }) {
    const { blueprint } = useBlueprint();
    const { playbook } = usePlaybook();
    const org = useOrg();

    if (props.todo.blueprint.type !== PlaybookTodoType.meeting) {
        throw new Error("todo is not of type 'meeting'");
    }
    const todoBlueprint = props.todo.blueprint as Extract<PlaybookTodoBlueprint, { type: PlaybookTodoType.meeting }>;

    const segment = blueprint.segments.find((s) => s.id === todoBlueprint.segmentId)!;
    const segmentEdit = playbook.edit?.segments?.[segment.id];

    const meeting = {
        title: todoBlueprint.meetingName ?? segment.title,
        recur: getMeetingCadenceFromSegment(segment, segmentEdit),
        duration: getMeetingDurationFromSegment(segment, segmentEdit).minutes,
        participants: getMeetingParticipantsFromSegment(org, segment, segmentEdit, playbook.peopleGroups),
    };

    const scheduleEventInExternalTool = useScheduleEvent();
    const scheduleFunction: ScheduleFunction = async (input) => {
        const description = await prompts.implementSegmentBasedMeetingDescription({
            playbookId: playbook.id,
            dynamicVars: { segment_id: segment.id },
            format: "html",
        });
        const invitees = "group" in input
            ? meeting.participants.map((u) => u.email)
            : [meeting.participants.find(u => u.id === input.userId)!.email];
        await scheduleEventInExternalTool(org.officeSuiteProvider, {
            description,
            eventName: meeting.title,
            invitees,
            meetingDuration: { minute: meeting.duration },
            recur: meeting.recur?.rfc5545,
            context: {
                playbookId: playbook.id,
                scope: "group" in input ? meetingScopeGroup(props.todo.id) : meetingScopeIndividual(props.todo.id, input.userId),
            },
        });
    }

    const SchedulingView: React.FC<SchedulingViewProps> = props.todo.blueprint.schedulingMode !== "individual" ? GroupScheduling : IndividualScheduling;

    return (
        <div className={"flex flex-col gap-1 rounded-md border p-4"}>
            <div className={"flex flex-row items-center gap-2"}>
                <Calendar className={"h-4 w-4"} />
                <b>
                    <TekkrMarkdown markdown={meeting.title} />
                </b>
            </div>
            <div className={"text-sm font-semibold text-muted-foreground"}>
                {meeting.recur && <span>{meeting.recur.friendlyName} &bull;&nbsp;</span>}
                {meeting.duration} minutes
            </div>
            {meeting.participants.length > 0 && props.todo.blueprint.schedulingMode === "group" && (
                <div className={"text-sm"}>
                    with&nbsp;
                    {meeting.participants
                        .slice(0, 3)
                        .map((u) => u.name)
                        .join(", ")}
                    {meeting.participants.length > 3 && ` and ${meeting.participants.length - 3} more.`}
                </div>
            )}
            <div className={"mt-3"}>
                <SchedulingView todo={props.todo} schedule={scheduleFunction} meeting={meeting} />
            </div>
            <AiPlug>Tekkr will generate a meeting description based on your playbook for this meeting.</AiPlug>
        </div>
    );
}

function meetingScopeIndividual (todoId: string, userId: string): string[] {
    return ["todo", todoId, userId];
}

function meetingScopeGroup (todoId: string) {
    return ["todo", todoId, "group"];
}