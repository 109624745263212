import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { playbookQueries } from "./controller/queries";
import { MockOrgProvider } from "../../auth/org-provider";
import PlaybookView from "../../components/shared/playbook-view/playbook-view";
import { ContentSpacer } from "../common/layout";
import { PlaybookHeader } from "../../components/shared/playbook-view/playbook-header";
import { PeopleGroupsContext } from "./controller/hooks/people-groups";
import Spinner from "../../components/ui/spinner";
import { BlueprintProvider } from "./controller/hooks/blueprint";
import { PlaybookProvider } from "./controller/hooks/playbook";
import { noop } from "lodash";
import { Playbook } from "tekkr-common/dist/model/playbook/playbook";

export function SharedPlaybookPage() {
    const { key } = useParams();
    if (!key) {
        throw new Error("missing playbook id");
    }

    const query = useQuery(playbookQueries.getSharedPlaybook(key));

    if (query.data) {
        const playbook: Playbook = {
            id: "id",
            ...query.data.playbook,
            state: {},
        };
        return (
            <ContentSpacer>
                <ContentSpacer contentWidth={"narrower"}>
                    <MockOrgProvider
                        mockOrg={{
                            id: query.data.org.id,
                            name: "",
                            users: query.data.org.users.map((u) => ({
                                ...u,
                                email: "xxx@xx.xx",
                                hasAccount: false,
                                linkedAccounts: [],
                            })),
                            logo: query.data.org.logo,
                            playbookAccess: { unrestricted: true },
                            officeSuiteProvider: "google",
                        }}
                    >
                        <PeopleGroupsContext.Provider
                            value={{
                                peopleGroups: query.data.peopleGroups ?? {},
                                updatePeopleGroup: () => {},
                            }}
                        >
                            <BlueprintProvider blueprint={query.data.blueprint}>
                                <PlaybookProvider data={{ playbook, refetch: noop }}>
                                    <PlaybookHeader
                                        mode={"playbook"}
                                        blueprint={query.data.blueprint}
                                        playbook={query.data.playbook}
                                    />
                                    <PlaybookView isEditing={false} playbookEdit={query.data.playbook.edit} />
                                </PlaybookProvider>
                            </BlueprintProvider>
                        </PeopleGroupsContext.Provider>
                    </MockOrgProvider>
                </ContentSpacer>
            </ContentSpacer>
        );
    } else {
        return (
            <ContentSpacer>
                <div className={"flex flex-col items-center gap-4"}>
                    <Spinner />
                    <p className={"text-sm font-semibold"}>Loading Playbook...</p>
                </div>
            </ContentSpacer>
        );
    }
}
