import { Playbook } from "tekkr-common/dist/model/playbook/playbook";
import React, { createContext, PropsWithChildren, useContext, useState } from "react";
import { PeopleGroupsContext } from "./people-groups";
import { useMutation } from "@tanstack/react-query";
import { playbookMutations } from "../queries";
import { PlaybookWrapper } from "tekkr-common/dist/lib/playbook-wrapper";
import { useBlueprint } from "./blueprint";

export type PlaybookContextType = {
    playbook: Playbook;
    pw: PlaybookWrapper;
    refetch: () => void;
};
const PlaybookContext = createContext<PlaybookContextType | null>(null);

export function PlaybookProvider(props: PropsWithChildren & { data: Omit<PlaybookContextType, "pw"> }) {
    const [groups, setGroups] = useState(props.data.playbook.peopleGroups ?? {});
    const { mutate: mutatePeopleGroups } = useMutation(playbookMutations.updatePeopleGroups(props.data.playbook.id));
    const { blueprint } = useBlueprint();
    return (
        <PlaybookContext.Provider
            value={{
                ...props.data,
                pw: new PlaybookWrapper(props.data.playbook, blueprint),
            }}
        >
            <PeopleGroupsContext.Provider
                value={{
                    peopleGroups: groups,
                    updatePeopleGroup: (id: string, members: string[]) => {
                        const newGroups = {
                            ...groups,
                            [id]: members,
                        };
                        props.data.playbook.peopleGroups = newGroups;
                        setGroups(newGroups);
                        mutatePeopleGroups(newGroups);
                    },
                }}
            >
                {props.children}
            </PeopleGroupsContext.Provider>
        </PlaybookContext.Provider>
    );
}

export function usePlaybookOptional(): PlaybookContextType | null {
    return useContext(PlaybookContext);
}

export function usePlaybook(): PlaybookContextType {
    const val = useContext(PlaybookContext);
    if (!val?.playbook) {
        throw new Error("playbook context is unavailable");
    }
    return val;
}
