import { ArrowRight, FileEdit, Info } from "lucide-react";
import { Link } from "react-router-dom";
import { Progress } from "../../../components/ui/progress";
import { DateTime } from "luxon";
import React from "react";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";

type LibraryType = ClientInferResponseBody<typeof apiContract.listPlaybooks, 200>["data"];

export function BlueprintPageLibraryBox(props: { library: LibraryType }) {
    // todo show user creator picture next to created-at timestamp (see figma)
    return (
        <div className={"my-4 flex flex-col gap-2"}>
            <div className={"flex flex-row items-center justify-between gap-1"}>
                <h4>In your library</h4>
                <p className={"text-sm text-muted-foreground"}>
                    <Info className={"me-1 inline-block h-4 w-4"} /> You have {props.library.length.toString()}{" "}
                    {props.library.length === 1 ? "copy" : "copies"} of this playbook in your library.
                </p>
            </div>
            <div className={"rounded-md border"}>
                {props.library.map((pb, index) => (
                    <Link key={pb.id} to={`/playbook/${pb.id}`}>
                        {index > 0 && <hr />}
                        <div className={"flex w-full flex-row items-center gap-4 px-4 py-2 hover:bg-accent"}>
                            <div>
                                <FileEdit className={"h-5 w-5 shrink-0"} />
                            </div>
                            <div className={"flex w-full shrink flex-col gap-1"}>
                                <p className={"overflow-hidden overflow-ellipsis text-start"}>{pb.title}</p>
                                <div className={"flex flex-row items-center gap-2"}>
                                    <Progress
                                        color={"confirmation"}
                                        className={"h-2 w-36"}
                                        value={(pb.state.edit ?? 0.0) * 100}
                                    />
                                    <p className={"text-sm text-muted-foreground"}>
                                        {Math.round((pb.state.edit ?? 0.0) * 100)}%
                                    </p>
                                </div>
                            </div>
                            <div className={"flex shrink-0 flex-row items-center gap-2"}>
                                <div className={"shrink-0 text-sm text-muted-foreground"}>
                                    created on{" "}
                                    {DateTime.fromISO(pb.createdAt).toLocaleString({
                                        month: "short",
                                        day: "numeric",
                                        year: "numeric",
                                    })}
                                </div>
                                <ArrowRight className={"h-5 w-5 shrink-0"} />
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}
