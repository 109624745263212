import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

type ContenteditableProps = {
    value: string;
    onChange: (value: string) => void;
} & Pick<React.HTMLProps<HTMLDivElement>, "className" | "style">;

export interface ContenteditableRef {
    focus(): void;
}

const Contenteditable = forwardRef<ContenteditableRef, ContenteditableProps>(function Contenteditable(props, ref) {
    const contentEditableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentEditableRef.current!.textContent !== props.value) {
            contentEditableRef.current!.textContent = props.value;
        }
    });

    useEffect(() => {
        contentEditableRef.current!.onkeydown = (e) => {
            if (e.keyCode == 13) {
                e.preventDefault();
                contentEditableRef.current!.blur();
            }
        };
        contentEditableRef.current!.onpaste = (e) => {
            e.preventDefault();
            const text = e.clipboardData?.getData("text/plain").replaceAll("\n", " ").trim();
            document.execCommand("insertHTML", false, text);
        };
    }, []);

    useImperativeHandle(ref, () => ({
        focus: () => {
            contentEditableRef.current?.focus();
        },
    }));

    return (
        <div
            className={props.className}
            style={props.style}
            contentEditable="plaintext-only"
            ref={contentEditableRef}
            suppressContentEditableWarning={true}
            onInput={(event) => {
                props.onChange((event.target as HTMLDivElement).textContent ?? "");
            }}
        />
    );
});
export default Contenteditable;
