import React from "react";
import { Todo } from "../todos-page";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import {
    ExternalLinkContentBlueprint,
    ExternalLinkStyle,
} from "tekkr-common/dist/model/playbook/segment/content/types/external-link/blueprint";
import ExternalLinkSegmentContentView from "../../../../../components/shared/playbook-view/components/content/types/external-link";
import { useBlueprint } from "../../../controller/hooks/blueprint";
import { PlaybookTodoType } from "tekkr-common/dist/model/playbook/segment/blueprint";
import { PlaybookTodoBlueprint } from "tekkr-common/dist/model/playbook/stages/implementation/blueprint";

export function ToolTodoContent(props: { todo: Todo }) {
    const { blueprint } = useBlueprint();

    if (props.todo.blueprint.type !== PlaybookTodoType.tool) {
        throw new Error("todo is not of type 'tool'");
    }
    const todoBlueprint = props.todo.blueprint as Extract<PlaybookTodoBlueprint, { type: PlaybookTodoType.tool }>;

    const segment = blueprint.segments.find((s) => s.id === todoBlueprint.segmentId)!;
    const toolContent = segment.content.find(
        (c) => c.type === ContentType.externalLink && c.style === ExternalLinkStyle.tool
    )! as ExternalLinkContentBlueprint;
    return <ExternalLinkSegmentContentView content={toolContent} edit={undefined} isEditing={false} />;
}
