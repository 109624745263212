"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.externalLinkContentBlueprintSchema = exports.ExternalLinkStyle = void 0;
const blueprint_1 = require("../../blueprint");
const zod_1 = require("zod");
var ExternalLinkStyle;
(function (ExternalLinkStyle) {
    ExternalLinkStyle["tool"] = "tool";
    ExternalLinkStyle["book"] = "book";
})(ExternalLinkStyle || (exports.ExternalLinkStyle = ExternalLinkStyle = {}));
exports.externalLinkContentBlueprintSchema = blueprint_1.contentBlueprintSchema
    .extend({
    type: zod_1.z.literal(blueprint_1.ContentType.externalLink),
    style: zod_1.z.nativeEnum(ExternalLinkStyle),
    link: zod_1.z.string().url(),
    imgUrl: zod_1.z.string().url(),
    title: zod_1.z.string(),
    description: zod_1.z.string().optional(),
})
    .strict();
