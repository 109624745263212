import { useMutation } from "@tanstack/react-query";
import debounce from "debounce";
import { usePlaybook } from "./playbook";
import { PlaybookTargetsReviewEdit } from "tekkr-common/dist/model/playbook/stages/target-review";
import { playbookMutations } from "../queries";
import { useCallback } from "react";

export function emptyTargetsReviewEdit(): PlaybookTargetsReviewEdit {
    return {
        targetDraftDone: false,
        targetSettingDone: false,
        reviews: {},
        communication: {
            done: false,
            channels: {},
        },
    };
}

export function usePlaybookTargetsReviewEdit() {
    const { playbook } = usePlaybook();

    if (!playbook.targetsReview) {
        playbook.targetsReview = emptyTargetsReviewEdit();
    }
    const edit = playbook.targetsReview;

    const mutation = useMutation(playbookMutations.updateTargetReview(playbook.id));
    const onUpdated = useCallback(
        debounce(() => {
            mutation.mutate(edit);
        }, 500),
        [playbook.id]
    );

    return {
        targetsReviewEdit: edit,
        updateTargetsReview: onUpdated,
    };
}
