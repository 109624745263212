import { SegmentContentViewProps } from "../segment-content";
import { EditableTextContentBlueprint } from "tekkr-common/dist/model/playbook/segment/content/types/editable-text/blueprint";
import { TekkrMarkdown } from "../../../../markdown/tekkr-markdown";
import { useRef, useState } from "react";
import ConfirmationDialog, { ConfirmationDialogConfig } from "../../../../../../modals/confirmation-dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { RotateCw, WandSparkles } from "lucide-react";
import React from "react";
import { useScopedEdit } from "../../../../../hooks/use-edit";
import { useMutation } from "@tanstack/react-query";
import { Skeleton } from "../../../../../ui/skeleton";
import { AiButton } from "../../../../ai-button";
import { CopyButton } from "../../../../copy-button";
import { TekkrMarkdownEditor } from "../../../../markdown/editor";

const defaultCopy = {
    generateButtonText: "Regenerate Text",
    generatingText: "generating...",
};

const copyOverrides: Record<string, Partial<typeof defaultCopy>> = {
    message: {
        generateButtonText: "Regenerate Message",
        generatingText: "generating message...",
    },
};

interface Props {
    textGenerator?: () => Promise<string>;
    copy?: keyof typeof copyOverrides;
}

export default function EditableTextSegmentContentView(
    props: SegmentContentViewProps<EditableTextContentBlueprint, Props>
) {
    const { state: text, updateEdit } = useScopedEdit(props.edit, (e) => e?.textMarkdown ?? props.content.defaultText);
    const [editorKey, setEditorKey] = useState<number>(0);

    const copy = {
        ...defaultCopy,
        ...(props.copy ? copyOverrides[props.copy] : undefined),
    };

    const generateMutation = useMutation({
        mutationFn: async () => {
            const text = await props.textGenerator?.();
            if (text) {
                updateEdit((e) => {
                    e!.textMarkdown = text;
                });
                setEditorKey(editorKey + 1);
            }
            return 1;
        },
    });

    const editorRef = useRef<HTMLDivElement>(null);

    if (!props.isEditing) {
        if (!text) {
            return <></>;
        }
        return <TekkrMarkdown markdown={text} />;
    } else {
        const onChange = (markdown: string) => {
            if (props.edit) {
                updateEdit((e) => {
                    e!.textMarkdown = markdown;
                });
            }
        };
        const reset = () => {
            onChange(props.content.defaultText!);
            setEditorKey(editorKey + 1);
        };
        const confirmResetDialogConfig: ConfirmationDialogConfig = {
            title: "Restore Original Content?",
            body: (
                <p>
                    Are you sure you want to restore the original content? Doing so will replace your changes with the
                    following:
                    <br />
                    <TekkrMarkdown markdown={props.content.defaultText!} />
                </p>
            ),
            confirmButtonText: "Restore",
            confirm: async () => reset(),
        };

        if ((!text || text.length < 1) && generateMutation.isIdle && !generateMutation.isError) {
            setTimeout(() => {
                generateMutation.mutate();
            }, 50);
        }

        const onCopy = () => {
            const contentEditable = editorRef.current?.querySelector(".mdxeditor-root-contenteditable");
            if (!contentEditable) {
                return null;
            }
            return {
                html: contentEditable.innerHTML,
                raw: (contentEditable as HTMLDivElement).innerText ?? "",
            };
        };

        return (
            <div className={"mb-2"}>
                {generateMutation.isPending ? (
                    <Skeleton className="h-24 w-full rounded-md p-4 text-sm font-semibold text-muted-foreground">
                        {copy.generatingText}
                    </Skeleton>
                ) : (
                    <div ref={editorRef}>
                        <TekkrMarkdownEditor key={editorKey} onChange={onChange} markdown={text ?? ""} />
                    </div>
                )}
                <div className={"mt-2 flex flex-row justify-between"}>
                    <div className={"flex flex-row items-start gap-3"}>
                        {props.textGenerator && (
                            <div className={"flex flex-row items-center gap-2"}>
                                <AiButton
                                    isLoading={generateMutation.isPending}
                                    onClick={() => generateMutation.mutate()}
                                    size={"sm"}
                                >
                                    <WandSparkles className={"h05 me-2 w-5"} />
                                    {copy.generateButtonText}
                                </AiButton>
                                <CopyButton onCopy={onCopy} />
                            </div>
                        )}
                        {props.content.defaultText && (
                            <ConfirmationDialog {...confirmResetDialogConfig}>
                                <DialogTrigger asChild>
                                    <p
                                        className={
                                            "group/restore inline-block cursor-pointer text-sm text-muted-foreground transition-all hover:underline"
                                        }
                                    >
                                        <RotateCw
                                            className={
                                                "me-2 inline h-4 w-4 transition-all group-hover/restore:rotate-45 group-active/restore:rotate-180"
                                            }
                                        />
                                        Restore Original Content
                                    </p>
                                </DialogTrigger>
                            </ConfirmationDialog>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
