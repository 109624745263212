import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { useReducer } from "react";
import { DateTime } from "luxon";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function delay(ms: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
}

export function getInitialsForName(name: string): string {
    const split = name.split(" ");
    if (split.length < 2) return split[0].charAt(0).toUpperCase();
    return split[0].charAt(0).toUpperCase() + split[split.length - 1].charAt(0).toUpperCase();
}

export function useComputedReducer<T>(fn: () => T) {
    return useReducer(fn, fn());
}

export function datePlusBusinessDays(businessDays: number, dateInput?: DateTime): DateTime {
    const date = dateInput ?? DateTime.now();

    const weekends = Math.floor(businessDays / 7) * 2;
    const extraInitialWeekend = date.weekday + ((businessDays + weekends) % 7) > 5 ? 2 : 0;
    const daysToAdd = businessDays + weekends + extraInitialWeekend;

    return date.plus({ day: daysToAdd });
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
