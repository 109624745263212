import React, { useState } from "react";
import { usePlaybook } from "../../controller/hooks/playbook";
import { useMutation } from "@tanstack/react-query";
import { PlaybookAttachmentTypes } from "../../controller/hooks/attachments";
import { apiClient } from "../../../../service/tekkr-service";
import { ClientInferRequest, ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../../components/ui/dialog";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../../components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { PlaybookAttachmentReferenceType } from "tekkr-common/dist/model/playbook/enums/enums";
import { useConnectOAuthService } from "../../../../components/shared/connect-oauth-account-button";

type ArtifactCreationInput = ClientInferRequest<typeof apiContract.createPlaybookAttachment>["body"]["attachment"];

interface CreateAttachmentTriggerInput {
    type: PlaybookAttachmentTypes;
    target: string[] | undefined;
}
type CreateAttachmentFunction = (input: CreateAttachmentTriggerInput) => void;

interface Props {
    render: (ctx: { createAttachment: CreateAttachmentFunction; isPending: boolean }) => React.ReactNode;
}

const attachmentBodyForType: Record<
    Exclude<PlaybookAttachmentTypes, PlaybookAttachmentTypes.URL>,
    ArtifactCreationInput
> = {
    [PlaybookAttachmentTypes.GoogleDoc]: {
        type: PlaybookAttachmentReferenceType.GoogleDriveFile,
        fileType: "document",
    },
    [PlaybookAttachmentTypes.GoogleSlides]: {
        type: PlaybookAttachmentReferenceType.GoogleDriveFile,
        fileType: "presentation",
    },
    [PlaybookAttachmentTypes.GoogleSheet]: {
        type: PlaybookAttachmentReferenceType.GoogleDriveFile,
        fileType: "spreadsheet",
    },
    [PlaybookAttachmentTypes.MsWordDoc]: {
        type: PlaybookAttachmentReferenceType.MsOfficeFile,
        fileType: "document",
    },
    [PlaybookAttachmentTypes.MsPowerpointSlides]: {
        type: PlaybookAttachmentReferenceType.MsOfficeFile,
        fileType: "presentation",
    },
    [PlaybookAttachmentTypes.MsExcelSheet]: {
        type: PlaybookAttachmentReferenceType.MsOfficeFile,
        fileType: "spreadsheet",
    },
};

export function CreateAttachmentInterface(props: Props) {
    const { playbook, refetch } = usePlaybook();
    const [pendingUrlAttachmentInput, setPendingUrlAttachmentInput] = useState<{
        target: string[] | undefined;
    } | null>(null);

    const { connect: connectOAuth } = useConnectOAuthService();

    const { mutate, isPending } = useMutation({
        mutationFn: async (input: { target: string[] | undefined; attachment: ArtifactCreationInput }) => {
            if (input.attachment.type === PlaybookAttachmentReferenceType.GoogleDriveFile) {
                await connectOAuth("google");
            } else if (input.attachment.type === PlaybookAttachmentReferenceType.MsOfficeFile) {
                await connectOAuth("microsoft");
            }
            const response = await apiClient.createPlaybookAttachment({
                params: {
                    playbookId: playbook.id,
                },
                body: {
                    target: input.target,
                    attachment: input.attachment,
                },
            });
            const body = response.body as ClientInferResponseBody<typeof apiContract.createPlaybookAttachment, 200>;
            if (body.data.type !== PlaybookAttachmentReferenceType.URL) {
                window.open(body.data.attachmentUrl, "_blank");
            }
            refetch();
        },
    });

    return (
        <>
            {!!pendingUrlAttachmentInput && (
                <CreateUrlAttachmentDialog
                    onClose={() => setPendingUrlAttachmentInput(null)}
                    onAttach={(name, url) => {
                        mutate({
                            target: pendingUrlAttachmentInput!.target,
                            attachment: {
                                type: PlaybookAttachmentReferenceType.URL,
                                name,
                                url,
                            },
                        });
                        setPendingUrlAttachmentInput(null);
                    }}
                />
            )}
            {props.render({
                createAttachment: (input) => {
                    if (input.type === PlaybookAttachmentTypes.URL) {
                        // defer mutation to when dialog is filled
                        setPendingUrlAttachmentInput({ target: input.target });
                    } else {
                        // create document right away
                        mutate({
                            target: input.target,
                            attachment: attachmentBodyForType[input.type],
                        });
                    }
                },
                isPending,
            })}
        </>
    );
}

const formSchema = z.object({
    attachmentName: z.string().min(2, {
        message: "Name must be at least 2 characters.",
    }),
    attachmentUrl: z.string().url(),
});
function CreateUrlAttachmentDialog(props: { onClose: () => void; onAttach: (name: string, url: string) => void }) {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            attachmentName: "",
            attachmentUrl: "",
        },
    });
    const handleSubmit = (values: z.infer<typeof formSchema>) => {
        props.onAttach(values.attachmentName, values.attachmentUrl);
    };
    return (
        <Dialog
            open={true}
            onOpenChange={(o) => {
                if (!o) {
                    props.onClose();
                }
            }}
        >
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Attach URL</DialogTitle>
                    <DialogDescription>
                        Enter a name and paste a URL to attach something available on the internet to this playbook.
                    </DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)} className={"flex flex-col gap-4"}>
                        <FormField
                            control={form.control}
                            name="attachmentName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Attachment Name</FormLabel>
                                    <FormControl>
                                        <Input aria-autocomplete={"none"} placeholder="Link Description" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="attachmentUrl"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>URL</FormLabel>
                                    <FormControl>
                                        <Input placeholder="https://drive.google.com/file/d/1234567" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button onClick={() => props.onClose()} variant={"secondary"}>
                                Cancel
                            </Button>
                            <Button type={"submit"}>Save</Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
