import React from "react";
import { StagePageContainer } from "../stage-container";
import { usePlaybook } from "../../controller/hooks/playbook";
import { PlaybookTodoType } from "tekkr-common/dist/model/playbook/segment/blueprint";
import { CalendarIcon, PaperclipIcon, Plus, User, XIcon } from "lucide-react";
import { useCurrentOrgUser, useOrg, useOrgUserMap } from "../../../../auth/org-provider";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from "../../../../components/ui/select";
import { PlaybookImplementation, PlaybookTodoStatus } from "tekkr-common/dist/model/playbook/stages/implementation";
import { cn } from "../../../../lib/utils";
import { TekkrMarkdown } from "../../../../components/shared/markdown/tekkr-markdown";
import { MeetingTodoContent } from "./components/meeting-todo-content";
import { EditScope, useScopedEdit } from "../../../../components/hooks/use-edit";
import { DateTime } from "luxon";
import { usePlaybookImplementationEdit } from "../../controller/hooks/implementation-edit";
import { Calendar } from "../../../../components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../components/ui/popover";
import { Button } from "../../../../components/ui/button";
import { TekkrCard, TekkrCardStyle } from "../../../../components/shared/tekkr-card";
import { apiClient } from "../../../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { config, Environment } from "../../../../lib/config";
import { SiAsana } from "@icons-pack/react-simple-icons";
import {
    computePlaybookTodos,
    PlaybookTodo,
} from "tekkr-common/dist/model/playbook/stages/implementation/compute-todos";
import { ToolTodoContent } from "./components/tool-todo-content";
import { PlaybookTodoBlueprint } from "tekkr-common/dist/model/playbook/stages/implementation/blueprint";
import {
    getAttachmentType,
    getPlaybookAttachmentTypes,
    PlaybookAttachmentTypes,
    usePlaybookAttachments,
    useResolvedAttachmentReference,
} from "../../controller/hooks/attachments";
import Spinner from "../../../../components/ui/spinner";
import { Link } from "react-router-dom";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import { TooltipTrigger } from "../../../../components/ui/tooltip";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { AttachmentPreviewTooltip } from "../../components/attachments/attachment-preview-tooltip";
import { RemoveAttachmentDialog } from "../../components/attachments/remove-attachment-dialog";
import { CreateAttachmentInterface } from "../../components/attachments/create-attachment-interface";
import BlueprintReferenceSegmentContentView from "../../../../components/shared/playbook-view/components/content/types/blueprint-reference";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";

function TodoItemContent(props: { todo: Todo }) {
    if (props.todo.blueprint.type === PlaybookTodoType.meeting) {
        return <MeetingTodoContent {...props} />;
    } else if (
        props.todo.blueprint.type === PlaybookTodoType.simple ||
        props.todo.blueprint.type === PlaybookTodoType.list
    ) {
        return null;
    } else if (props.todo.blueprint.type === PlaybookTodoType.tool) {
        return <ToolTodoContent {...props} />;
    } else {
        throw new Error(`todo type not supported`);
    }
}

const cardStyle: Record<PlaybookTodoStatus, TekkrCardStyle> = {
    [PlaybookTodoStatus.todo]: "primary",
    [PlaybookTodoStatus.done]: "confirmation",
    [PlaybookTodoStatus.inProgress]: "primary",
    [PlaybookTodoStatus.notDoing]: "disabled",
};

function AttachmentChip(props: { attachmentId: string }) {
    const meta = useResolvedAttachmentReference(props.attachmentId);
    const { AttachmentTypeIcon } = getAttachmentType(meta.type);
    return (
        <div className={"flex flex-row items-stretch gap-1 rounded-md bg-accent transition-all hover:bg-input"}>
            <AttachmentPreviewTooltip attachmentId={props.attachmentId}>
                <TooltipTrigger asChild>
                    <Link to={meta.url} target={"_blank"}>
                        <div className={"flex flex-row items-center gap-2 py-1 pe-1 ps-3 text-sm"}>
                            <PaperclipIcon className={"h-4 w-4 shrink-0 opacity-65"} />
                            {AttachmentTypeIcon ? (
                                <AttachmentTypeIcon className={"h-4 w-4 shrink-0 opacity-65"} />
                            ) : undefined}
                            <div className={"line-clamp-1 max-w-md font-semibold"}>{meta.title}</div>
                        </div>
                    </Link>
                </TooltipTrigger>
            </AttachmentPreviewTooltip>
            <RemoveAttachmentDialog attachmentId={props.attachmentId} attachmentTitle={meta.title}>
                <DialogTrigger asChild>
                    <div className={"group flex cursor-pointer flex-row items-center pe-2 ps-1"}>
                        <XIcon className={"h-4 w-4 opacity-65 transition-all group-hover:opacity-100"} />
                    </div>
                </DialogTrigger>
            </RemoveAttachmentDialog>
        </div>
    );
}

function Artifacts(props: { todoId: string }) {
    const { attachments } = usePlaybookAttachments();
    const relevant = attachments.filter(
        (attachment) => attachment.target?.[0] === "todo" && attachment.target?.[1] === props.todoId
    );
    return (
        <div className={"flex flex-row flex-wrap gap-2"}>
            {relevant?.map((attachment) => <AttachmentChip key={attachment.id} attachmentId={attachment.id} />)}
        </div>
    );
}

type SimpleTodo = Extract<PlaybookTodoBlueprint, { type: PlaybookTodoType.simple }>;
const attachmentQuickActions: Record<Exclude<SimpleTodo["createArtifact"], undefined>, Set<PlaybookAttachmentTypes>> = {
    document: new Set([PlaybookAttachmentTypes.GoogleDoc, PlaybookAttachmentTypes.MsWordDoc]),
    presentation: new Set([PlaybookAttachmentTypes.GoogleSlides, PlaybookAttachmentTypes.MsPowerpointSlides]),
};

function AddAttachmentBar(props: {
    todoId: string;
    blueprint: PlaybookTodoBlueprint & { type: PlaybookTodoType.simple };
}) {
    const org = useOrg();
    const attachmentTarget = ["todo", props.todoId];
    return (
        <CreateAttachmentInterface
            render={({ createAttachment, isPending }) => {
                const allActions = getPlaybookAttachmentTypes(org.officeSuiteProvider);
                const quickActions = props.blueprint.createArtifact
                    ? allActions.filter((a) => attachmentQuickActions[props.blueprint.createArtifact!]!.has(a))
                    : [];

                return (
                    <div className={"flex flex-row"}>
                        <div
                            className={cn(
                                "max-w-full overflow-hidden transition-all duration-500",
                                !isPending && "max-w-0"
                            )}
                        >
                            <Spinner className={"me-4"} />
                        </div>
                        <div
                            className={cn(
                                "flex flex-row items-center gap-2",
                                isPending && "pointer-events-none opacity-65"
                            )}
                        >
                            {quickActions.map((attachmentType) => {
                                const { AttachmentTypeIcon, attachmentTypeCopy } = getAttachmentType(attachmentType);
                                return (
                                    <div
                                        onClick={() =>
                                            createAttachment({
                                                type: attachmentType,
                                                target: attachmentTarget,
                                            })
                                        }
                                        className={
                                            "flex cursor-pointer flex-row items-center gap-2 rounded-full px-3 py-0.5 text-muted-foreground outline-dotted outline-muted transition-all hover:bg-muted"
                                        }
                                        key={123}
                                    >
                                        {AttachmentTypeIcon ? (
                                            <AttachmentTypeIcon className={"h-4 w-4"} />
                                        ) : (
                                            <PaperclipIcon className={"h-4 w-4"} />
                                        )}
                                        <span className={"text-sm font-medium"}>{attachmentTypeCopy.createAction}</span>
                                    </div>
                                );
                            })}
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <div
                                        className={
                                            "flex cursor-pointer flex-row items-center gap-2 rounded-full px-3 py-0.5 text-sm font-medium text-muted-foreground outline-dotted outline-muted transition-all hover:bg-muted"
                                        }
                                    >
                                        <Plus className={"h-4 w-4"} /> Attach Other
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-auto">
                                    <div className={"flex flex-col gap-1"}>
                                        {allActions
                                            .filter((a) => !quickActions.includes(a))
                                            .map((attachmentType) => {
                                                const { AttachmentTypeIcon, attachmentTypeCopy } =
                                                    getAttachmentType(attachmentType);
                                                return (
                                                    <DropdownMenuItem
                                                        className={"gap-2"}
                                                        onClick={() =>
                                                            createAttachment({
                                                                type: attachmentType,
                                                                target: attachmentTarget,
                                                            })
                                                        }
                                                        key={attachmentType}
                                                    >
                                                        {AttachmentTypeIcon ? (
                                                            <AttachmentTypeIcon className={"h-4 w-4"} />
                                                        ) : (
                                                            <PaperclipIcon className={"h-4 w-4"} />
                                                        )}
                                                        {attachmentTypeCopy.description}
                                                    </DropdownMenuItem>
                                                );
                                            })}
                                    </div>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    </div>
                );
            }}
        />
    );
}

function RelatedPlaybooksList(props: {
    todoId: string;
    blueprint: Extract<PlaybookTodoBlueprint, { type: PlaybookTodoType.simple }>;
}) {
    if (!props.blueprint.relatedPlaybooks?.length) {
        return null;
    }
    return (
        <div className={"flex flex-col gap-2"}>
            {props.blueprint.relatedPlaybooks.map((relatedBlueprintId) => (
                <BlueprintReferenceSegmentContentView
                    key={relatedBlueprintId}
                    content={{
                        type: ContentType.blueprintReference,
                        blueprintId: relatedBlueprintId,
                    }}
                    className={"mt-0"}
                    edit={undefined}
                    isEditing={false}
                />
            ))}
        </div>
    );
}

function TodoItem(props: { todo: Todo }) {
    const org = useOrg();
    const orgUserMap = useOrgUserMap();

    const { implementationEdit } = usePlaybookImplementationEdit();
    const todosEdit = implementationEdit.todos;

    if (!todosEdit[props.todo.id]) {
        todosEdit[props.todo.id] = {};
    }

    const { state, updateEdit } = useScopedEdit(todosEdit[props.todo.id], (e) => ({
        status: e.status ?? PlaybookTodoStatus.todo,
        assignee: e.assignee,
        dueDate: e.dueDate ? DateTime.fromISO(e.dueDate) : undefined,
    }));
    const setStatus = (status: PlaybookTodoStatus) => {
        updateEdit((e) => {
            e.status = status;
        });
    };
    const setAssignee = (assigneeUserId: string) => {
        updateEdit((e) => {
            e.assignee = assigneeUserId;
        });
    };
    const setDueDate = (dueDate?: Date) => {
        updateEdit((e) => {
            e.dueDate = dueDate?.toISOString();
        });
    };

    const collapsed = !(state.status !== PlaybookTodoStatus.notDoing && state.status !== PlaybookTodoStatus.done);

    return (
        <TekkrCard cardStyle={cardStyle[state.status]}>
            <div className={cn("flex flex-row justify-between gap-4", collapsed && "items-center")}>
                <div className={"grow"}>
                    <div
                        className={cn(
                            "flex flex-col items-start gap-2",
                            state.status === PlaybookTodoStatus.notDoing && "opacity-65 transition-all duration-300"
                        )}
                    >
                        <h4>
                            <TekkrMarkdown markdown={props.todo.title} />
                        </h4>
                    </div>
                    {!collapsed && (
                        <TekkrMarkdown
                            markdown={props.todo.description}
                            className={"mt-1 overflow-clip text-sm text-muted-foreground"}
                        />
                    )}
                </div>
                <div className={"grow-0"}>
                    <select
                        value={state.status}
                        onChange={(e) => setStatus(e.target.value as PlaybookTodoStatus)}
                        className={cn(
                            "cursor-pointer rounded-md border-r-8 border-r-transparent bg-accent px-2 py-1 text-sm font-semibold text-foreground transition-all duration-300 focus:outline-none active:outline-none",
                            state.status === PlaybookTodoStatus.done && "bg-confirmation text-white",
                            state.status === PlaybookTodoStatus.inProgress && "bg-primary text-white"
                        )}
                    >
                        <option value={PlaybookTodoStatus.todo}>To do</option>
                        <option value={PlaybookTodoStatus.inProgress}>In Progress</option>
                        <option value={PlaybookTodoStatus.done}>Done</option>
                        <option value={PlaybookTodoStatus.notDoing}>Not Doing</option>
                    </select>
                </div>
            </div>

            <div
                className={"transition-all duration-300"}
                style={{
                    maxHeight: collapsed ? "0" : "9999pt",
                    opacity: collapsed ? 0 : 1,
                    visibility: collapsed ? "hidden" : "visible",
                    transform: collapsed ? "scaleY(0.5)" : "scaleY(1.0)",
                    transformOrigin: "top",
                }}
            >
                <div className={"pt-3"}>
                    <TodoItemContent todo={props.todo} />
                </div>
                <div className={"flex flex-col gap-2"}>
                    <Artifacts todoId={props.todo.id} />
                    {props.todo.blueprint.type === PlaybookTodoType.simple &&
                        "relatedPlaybooks" in props.todo.blueprint && (
                            <RelatedPlaybooksList todoId={props.todo.id} blueprint={props.todo.blueprint} />
                        )}
                    {props.todo.blueprint.type === PlaybookTodoType.simple &&
                        "createArtifact" in props.todo.blueprint && (
                            <AddAttachmentBar todoId={props.todo.id} blueprint={props.todo.blueprint} />
                        )}
                </div>
                <hr className={"-mx-4 my-4"} />
                <div className={"flex flex-row gap-6"}>
                    <div className={"flex flex-row items-center gap-2"}>
                        <User className={"h-5 w-5"} />
                        <Select value={state.assignee} onValueChange={(userId) => setAssignee(userId)}>
                            <SelectTrigger className={"h-8 w-[180px] border-0 bg-accent"}>
                                {state.assignee ? (
                                    orgUserMap[state.assignee]!.name
                                ) : (
                                    <span className={"text-muted-foreground"}>Owner</span>
                                )}
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    {org.users.map((u) => (
                                        <SelectItem key={u.id} value={u.id}>
                                            <div className={"flx flex-col"}>
                                                <div>{u.name}</div>
                                                <div className={"text-sm text-muted-foreground"}>{u.title}</div>
                                            </div>
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                    <div className={"flex flex-row items-center gap-2"}>
                        <CalendarIcon className={"h-5 w-5"} />
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                    variant={"secondary"}
                                    className={"h-8 w-[180px] justify-start bg-accent text-left font-normal"}
                                >
                                    {state.dueDate ? (
                                        state.dueDate.toLocaleString({
                                            month: "long",
                                            day: "numeric",
                                        })
                                    ) : (
                                        <span className={"text-muted-foreground"}>Due Date</span>
                                    )}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                                <Calendar
                                    mode="single"
                                    defaultMonth={state.dueDate?.toJSDate()}
                                    selected={state.dueDate?.toJSDate()}
                                    onSelect={setDueDate}
                                    initialFocus
                                />
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>
            </div>
        </TekkrCard>
    );
}

export type Todo = PlaybookTodo;

function usePlaybookTodos() {
    const { pw } = usePlaybook();

    return computePlaybookTodos(pw);
}

function countTodosNotDone(todos: Todo[], edit: PlaybookImplementation["todos"]): number {
    let count = 0;
    todos.forEach((todo) => {
        const state = edit?.[todo.id]?.status ?? PlaybookTodoStatus.todo;
        if (state !== PlaybookTodoStatus.done && state !== PlaybookTodoStatus.notDoing) {
            count++;
        }
    });
    return count;
}

/*function ExportToAsanaButton() {
    const { playbook } = usePlaybook();
    const exportToAsana = async () => {
        const res = await apiClient.exportImplementationTasks({
            params: {
                playbookId: playbook.id,
            },
            body: {
                service: "asana",
            }
        });
        const body = res.body as ClientInferResponseBody<typeof apiContract.exportImplementationTasks>;
        window.open(body.exportResultUrl, "_blank");
    }

    const currentUser = useCurrentOrgUser();
    const asanaConnected = currentUser.linkedAccounts.includes("asana");

    const buttonContent = <>
        <SiAsana className={"w-4 h-4 me-3"} />
        Export to Asana
    </>

    if (!asanaConnected) {
        return <ConnectOauthAccountButton onConnected={exportToAsana} service={"asana"}>{ buttonContent }</ConnectOauthAccountButton>
    }

    return <>
        <Button variant={"secondary"} size={"sm"} onClick={exportToAsana} className={`hover:text-[#F06A6A]`}>{ buttonContent }</Button>
    </>
}*/

export function ImplementationTodosPage() {
    const { updateImplementation, implementationEdit } = usePlaybookImplementationEdit();
    const todos = usePlaybookTodos();
    const todosNotDoneCount = countTodosNotDone(todos, implementationEdit.todos);

    return (
        <EditScope onEditUpdated={updateImplementation}>
            <StagePageContainer
                header={"Implement Changes"}
                explainer={
                    "The items below represent tasks that need to be completed by you or others to complete this playbook implementation. They're based on your custom playbook."
                }
                nextButtonDisabled={todosNotDoneCount > 0}
            >
                <div className={"flex flex-row items-center gap-4"}>
                    <h3>Todos</h3>
                    {!!todosNotDoneCount && (
                        <div
                            className={
                                "rounded-md border-foreground bg-primary px-1 text-center text-sm font-semibold text-white"
                            }
                        >
                            {todosNotDoneCount}
                        </div>
                    )}
                </div>
                <div className={"flex flex-col gap-6"}>
                    {todos.map((todo) => (
                        <TodoItem key={todo.id} todo={todo} />
                    ))}
                </div>
            </StagePageContainer>
        </EditScope>
    );
}
