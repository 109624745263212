"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toolTemplateContentEditSchema = void 0;
const zod_1 = require("zod");
exports.toolTemplateContentEditSchema = zod_1.z.strictObject({
    reference: zod_1.z.discriminatedUnion("type", [
        zod_1.z.strictObject({
            type: zod_1.z.literal("google-sheet"),
            fileId: zod_1.z.string(),
        }),
    ]),
});
