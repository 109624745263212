"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookImplementationSchema = exports.PlaybookTodoStatus = void 0;
const zod_1 = require("zod");
var PlaybookTodoStatus;
(function (PlaybookTodoStatus) {
    PlaybookTodoStatus["todo"] = "todo";
    PlaybookTodoStatus["done"] = "done";
    PlaybookTodoStatus["inProgress"] = "in_progress";
    PlaybookTodoStatus["notDoing"] = "not_doing";
})(PlaybookTodoStatus || (exports.PlaybookTodoStatus = PlaybookTodoStatus = {}));
const playbookTodoSchema = zod_1.z.strictObject({
    status: zod_1.z.nativeEnum(PlaybookTodoStatus).optional(),
    assignee: zod_1.z.string().optional(),
    dueDate: zod_1.z.string().optional(),
});
exports.playbookImplementationSchema = zod_1.z.strictObject({
    todos: zod_1.z.record(zod_1.z.string(), playbookTodoSchema),
});
