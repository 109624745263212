import ConfirmationDialog, { ConfirmationDialogConfig } from "../../../../modals/confirmation-dialog";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../../../service/tekkr-service";
import { usePlaybook } from "../../controller/hooks/playbook";
import { usePlaybookAttachments } from "../../controller/hooks/attachments";

interface Props {
    attachmentTitle: string;
    attachmentId: string;
}

export function RemoveAttachmentDialog(props: React.PropsWithChildren<Props>) {
    const { playbook } = usePlaybook();
    const { refetch } = usePlaybookAttachments();
    const mutation = useMutation({
        mutationFn: async () => {
            await apiClient.removePlaybookAttachment({
                params: {
                    playbookId: playbook.id,
                    attachmentId: props.attachmentId,
                },
                body: undefined,
            });
            await refetch();
        },
    });
    const config: ConfirmationDialogConfig = {
        title: "Remove Attachment?",
        body: (
            <p>
                Are you sure you want to remove <i>{props.attachmentTitle}</i>?
                <br />
                <span className={"text-sm text-muted-foreground"}>
                    The document will not be deleted, it will just be unattached from the playbook.
                </span>
            </p>
        ),
        confirmButtonText: "Remove",
        confirmButtonVariant: "destructive",
        confirm: async () => await mutation.mutateAsync(),
    };
    return <ConfirmationDialog {...config}>{props.children}</ConfirmationDialog>;
}
