import React, { ReactElement, useEffect, useRef } from "react";
import { Button } from "../../../components/ui/button";
import { cn } from "../../../lib/utils";
import { usePlaybookPageNavigation } from "../playbook-page";
import { ChevronLast, SkipForwardIcon } from "lucide-react";

interface Props {
    header: string;
    skipButtonText?: string;
    variant?: "plain";
    explainer?: string;
    nextButtonDisabled?: boolean;
    onNext?: () => boolean | void;
    hideNavigationButtons?: boolean;
    className?: string;
    overlayDialog?: ReactElement;
    skipBanner?: {
        title: string;
        description: string;
        skipButtonText: string;
        onSkip: () => void;
    };
}

export function StagePageContainer(props: React.PropsWithChildren & Props) {
    const playbookPageNavigation = usePlaybookPageNavigation();

    const contentContainerRef = useRef<HTMLDivElement>(null);
    const overlayContainerRef = useRef<HTMLDivElement>(null);

    // center overlay dialog over content
    useEffect(() => {
        if (contentContainerRef.current && overlayContainerRef.current) {
            const rect = contentContainerRef.current.getBoundingClientRect();
            overlayContainerRef.current.style.left = `${rect.left}px`;
            overlayContainerRef.current.style.right = `${window.innerWidth - rect.right}px`;
        }
    }, [contentContainerRef.current, overlayContainerRef.current]);

    /*const banner: Props["skipBanner"] = {
        title: "Targets already defined?",
        description: "If the targets for this playbook have been discussed already and everyone is on the same page about them, you can skip target setting.",
        skipButtonText: "Skip Setting Targets",
        onSkip: () => {},
    };*/

    return (
        <>
            <div
                ref={contentContainerRef}
                className={cn(
                    "flex flex-col gap-4",
                    props.overlayDialog && "pointer-events-none max-h-[70vh] overflow-hidden blur-lg",
                    props.className
                )}
            >
                <h1 className={cn("duration-300 animate-in fade-in-70", props.variant === "plain" && "mb-2")}>
                    {props.header}
                </h1>
                {props.explainer && (
                    <p className={"duration-500 animate-in fade-in-70 slide-in-from-bottom-1"}>{props.explainer}</p>
                )}
                {props.variant !== "plain" && <hr className={"duration-500 animate-in zoom-in-50"} />}
                <div className={"flex flex-col gap-3 duration-500 animate-in fade-in-50 slide-in-from-top-0.5"}>
                    {props.children}
                </div>
                {!props.hideNavigationButtons && (
                    <div className={"flex flex-row justify-end gap-4"}>
                        {/* <Button variant={"outline"}>{ props.skipButtonText ?? "Skip" } <ChevronLast className={"w-5 h-5 ms-2"} /></Button> */}
                        <Button
                            disabled={props.nextButtonDisabled}
                            onClick={() => {
                                if (!props.onNext || props.onNext() !== false) {
                                    playbookPageNavigation?.goToNext();
                                }
                            }}
                        >
                            Next
                        </Button>
                    </div>
                )}
                {props.skipBanner && (
                    <div
                        className={"mt-8 flex flex-row items-center gap-4 rounded-md border p-4 text-muted-foreground"}
                    >
                        <div className={"flex grow flex-col items-start"}>
                            <div className={"text-base font-medium"}>{props.skipBanner.title}</div>
                            <div className={"text-sm"}>{props.skipBanner.description}</div>
                        </div>
                        <Button variant={"outline"} size={"sm"} onClick={props.skipBanner.onSkip}>
                            {props.skipBanner.skipButtonText}
                            <ChevronLast className={"ms-2 h-5 w-5"} />
                        </Button>
                    </div>
                )}
            </div>
            {props.overlayDialog && (
                <div
                    ref={overlayContainerRef}
                    className={"absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center"}
                >
                    <div className={"max-w-xl rounded-xl border bg-background px-12 py-6 shadow-black"}>
                        {props.overlayDialog}
                    </div>
                </div>
            )}
        </>
    );
}
