import { DateTime, DurationLike } from "luxon";
import { apiClient } from "../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { useGlobalDialogs } from "../modals/global-dialogs";
import { useLocalStorage } from "@uidotdev/usehooks";
import { localStorageKeys } from "./local-storage-keys";

export type Rfc5545FrequencyConfig = {
    freq: "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY";
    interval?: number;
} | null;

interface GoogleCalendarLinkOptions {
    eventName: string;
    context: {
        playbookId: string;
        scope: string[];
    };
    description?: string;
    invitees?: string[];
    meetingStart?: Date;
    meetingDuration: DurationLike;
    recur?: Rfc5545FrequencyConfig;
}

function formatGoogleDate(dt: DateTime): string {
    const tzed = dt.setZone("UTC");
    return `${tzed.toFormat("yyyyMMdd")}T${tzed.toFormat("HHmmss")}Z`;
}

function formatOutlookDate(dt: DateTime): string {
    // outlook uses local/user timezone
    return `${dt.toFormat("yyyy-MM-dd")}T${dt.toFormat("HH:mm:ss")}`;
}

function defaultStartTime(): Date {
    return DateTime.now().startOf("hour").plus({ hour: 1 }).toJSDate();
}

// todo text formatting for each of the platforms should happen here (backend should just return MD or HTML)

const urlFactories: Record<
    "google" | "microsoft",
    (options: GoogleCalendarLinkOptions, trackerAddress: string) => Promise<string>
> = {
    google: async (options, trackerAddress) => {
        const url = new URL("https://calendar.google.com/calendar/render?action=TEMPLATE");
        // meeting descriptions
        url.searchParams.append("text", options.eventName);
        if (options.description) {
            url.searchParams.append("details", options.description);
        }

        // attendees
        url.searchParams.append("add", [`Tekkr Bot <${trackerAddress}>`, ...(options.invitees ?? [])].join(","));

        if (options.recur) {
            const props = {
                FREQ: options.recur.freq,
                ...(options.recur.interval ? { INTERVAL: options.recur.interval } : {}),
            };
            const val = `RRULE:${Object.entries(props)
                .map(([k, v]) => `${k}=${v}`)
                .join(";")}`;
            url.searchParams.append("recur", val);
        }

        // times
        const start = DateTime.fromJSDate(options.meetingStart ?? defaultStartTime());
        const startFormatted = formatGoogleDate(start);
        const endFormatted = formatGoogleDate(start.plus(options.meetingDuration));
        url.searchParams.append("dates", `${startFormatted}/${endFormatted}`);

        return url.toString();
    },
    microsoft: async (options, trackerAddress) => {
        // https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/outlook-web.md?ref=academy.glow.build
        // there doesn't seem to be a way to add recurring meetings via deeplinks in outlook
        // todo add instructions to meeting description for recur?

        const url = new URL(
            "https://outlook.office.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent"
        );
        // meeting descriptions
        url.searchParams.append("subject", options.eventName);
        if (options.description) {
            url.searchParams.append("body", options.description);
        }
        // add meeting link
        url.searchParams.append("online", "true");

        url.searchParams.append("to", [`Tekkr Bot <${trackerAddress}>`, ...(options.invitees ?? [])].join(","));

        // times
        const start = DateTime.fromJSDate(options.meetingStart ?? defaultStartTime());
        const startFormatted = formatOutlookDate(start);
        const endFormatted = formatOutlookDate(start.plus(options.meetingDuration));
        url.searchParams.append("startdt", startFormatted);
        url.searchParams.append("enddt", endFormatted);

        return url.toString();
    },
};

type ScheduleEvent = (officeSuiteProvider: "google" | "microsoft", options: GoogleCalendarLinkOptions) => Promise<void>;

export function useScheduleEvent(): ScheduleEvent {
    const globalDialogs = useGlobalDialogs();
    const [disclaimerDismissed, setDisclaimerDismissed] = useLocalStorage<boolean>(
        localStorageKeys.tekkrEmailBotDisclaimerDismissed,
        false
    );

    return async (officeSuiteProvider, options) => {
        const meetingTrackerEmailResponse = await apiClient.createMeetingTrackerEmail({
            body: options.context,
        });
        const meetingTrackerEmail = meetingTrackerEmailResponse.body as ClientInferResponseBody<
            typeof apiContract.createMeetingTrackerEmail,
            200
        >;

        const factory = urlFactories[officeSuiteProvider];
        const url = await factory(options, meetingTrackerEmail.emailAddress);

        const openWindow = () => {
            setDisclaimerDismissed(true);
            window.open(url, "_blank");
        };
        if (!disclaimerDismissed) {
            globalDialogs!.showTekkrEmailBotDisclaimer({
                trackerAddress: meetingTrackerEmail.emailAddress,
                callback: openWindow,
            });
        } else {
            openWindow();
        }
    };
}
