"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checklistContentBlueprintSchema = void 0;
const blueprint_1 = require("../../blueprint");
const zod_1 = require("zod");
const schema_refinements_1 = require("../../../../../../utils/schema-refinements");
exports.checklistContentBlueprintSchema = blueprint_1.editableContentBlueprintSchema
    .extend({
    type: zod_1.z.literal(blueprint_1.ContentType.list),
    title: zod_1.z.string().optional(),
    prompt: zod_1.z.string().optional(),
    description: zod_1.z.string().optional(),
    singleMode: zod_1.z.boolean().optional(),
    items: zod_1.z
        .array(zod_1.z.object({
        id: zod_1.z.string(),
        title: zod_1.z.string(),
        description: zod_1.z.string().optional(),
        link: zod_1.z
            .string()
            .optional()
            .describe("Add a URL to link to for this item. When using the link property, the title does NOT support markdown formatting."),
    }))
        .superRefine((0, schema_refinements_1.validateUniqueIds)((item) => item.id)),
    extendable: zod_1.z.boolean(),
})
    .strict();
