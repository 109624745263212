"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookTargetsReviewEditSchema = exports.playbookTargetsReviewBlueprintSchema = exports.playbookTargetsReviewExpectationReviewSchema = exports.TargetReviewTemplate = void 0;
const zod_1 = require("zod");
const schema_refinements_1 = require("../../../utils/schema-refinements");
const enums_1 = require("../enums/enums");
const edit_1 = require("../segment/content/types/select/edit");
const edit_2 = require("../segment/content/types/checklist/edit");
var TargetReviewTemplate;
(function (TargetReviewTemplate) {
    TargetReviewTemplate["seniorStakeholderReview"] = "senior-stakeholders";
    TargetReviewTemplate["keyImpactedStakeholderReview"] = "key-impacted-stakeholders";
    TargetReviewTemplate["techLeadership"] = "tech-leadership";
})(TargetReviewTemplate || (exports.TargetReviewTemplate = TargetReviewTemplate = {}));
exports.playbookTargetsReviewExpectationReviewSchema = zod_1.z.strictObject({
    id: zod_1.z.string().regex(schema_refinements_1.formats.kebabCaseRegex),
    peopleGroup: zod_1.z.string(),
    copy: zod_1.z.strictObject({
        template: zod_1.z.nativeEnum(TargetReviewTemplate),
    }),
    suggestedMeetingQuestions: zod_1.z.array(zod_1.z.strictObject({
        id: zod_1.z.string(),
        title: zod_1.z.string(),
    })),
});
exports.playbookTargetsReviewBlueprintSchema = zod_1.z.strictObject({
    expectationReviews: zod_1.z
        .array(exports.playbookTargetsReviewExpectationReviewSchema)
        .superRefine((0, schema_refinements_1.validateUniqueIds)((group) => group.id))
        .optional(),
    communication: zod_1.z
        .strictObject({
        channels: zod_1.z.array(zod_1.z.nativeEnum(enums_1.CommunicationChannel)).min(1),
        peopleGroups: zod_1.z.array(zod_1.z.string()),
    })
        .optional(),
});
exports.playbookTargetsReviewEditSchema = zod_1.z.object({
    targetDraftDone: zod_1.z.boolean(),
    targetSettingDone: zod_1.z.boolean(),
    reviews: zod_1.z.record(zod_1.z.string(), zod_1.z.object({
        done: zod_1.z.boolean().optional(),
        hidden: zod_1.z.boolean().optional(),
        meetingDuration: edit_1.selectContentEditSchema,
        meetingQuestions: edit_2.checklistContentEditSchema,
        meetingsScheduled: zod_1.z.array(zod_1.z.string()),
    })),
    communication: zod_1.z.object({
        done: zod_1.z.boolean().optional(),
        channels: zod_1.z.record(zod_1.z.nativeEnum(enums_1.CommunicationChannel), zod_1.z.strictObject({
            enabled: zod_1.z.boolean(),
            content: zod_1.z.record(zod_1.z.string(), zod_1.z.any()).optional(),
        })),
    }),
});
