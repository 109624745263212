"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computePlaybookTodos = computePlaybookTodos;
exports.isCompletedTodoState = isCompletedTodoState;
const implementation_1 = require("../implementation");
const blueprint_1 = require("../../segment/blueprint");
const blueprint_2 = require("../../segment/content/blueprint");
function todoApplies(todo, pw) {
    if ("basedOn" in todo && todo.basedOn) {
        if ("segment" in todo.basedOn) {
            if (pw.getSegmentAndEdit(todo.basedOn.segment).edit?.hidden) {
                return false;
            }
        }
        else if ("listItem" in todo.basedOn) {
            const ref = todo.basedOn.listItem;
            const selectedItems = pw.getContentAndEdit(ref.segment, ref.list, blueprint_2.ContentType.list).edit?.selectedItems;
            return !!selectedItems?.includes(ref.item);
        }
    }
    return true;
}
function computeTodosFromBlueprint(blueprint, pw) {
    if (blueprint.type === blueprint_1.PlaybookTodoType.list) {
        const { content, edit } = pw.getContentAndEdit(blueprint.segmentId, blueprint.contentId, blueprint_2.ContentType.list);
        const checkedItems = edit?.selectedItems ?? [];
        const todos = [];
        checkedItems.forEach((id) => {
            const contentItem = content.items.find((i) => i.id === id);
            if (!contentItem) {
                // this happens if someone selected an item that we removed from the blueprint later on. -> bad, but we can't really avoid.
                return;
            }
            todos.push({
                title: (content.title ? content.title + ": " : "") + contentItem.title,
                description: contentItem.description ?? "",
                id: `${blueprint.id}.${id}`,
                blueprint,
            });
        });
        return todos;
    }
    else {
        return [
            {
                id: blueprint.id,
                title: blueprint.title,
                description: blueprint.description,
                blueprint,
            },
        ];
    }
}
function computePlaybookTodos(pw) {
    const todos = [];
    if (!pw.blueprint.todos) {
        return [];
    }
    for (const todo of pw.blueprint.todos) {
        if (todoApplies(todo, pw)) {
            todos.push(...computeTodosFromBlueprint(todo, pw));
        }
    }
    return todos;
}
function isCompletedTodoState(status) {
    return status === implementation_1.PlaybookTodoStatus.done || status === implementation_1.PlaybookTodoStatus.notDoing;
}
