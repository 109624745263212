import { PlaybookCategory, playbookCategoryConfig } from "tekkr-common/dist/model/playbook/enums/enums";
import { Badge } from "../ui/badge";
import React from "react";

interface Props {
    categories: PlaybookCategory[];
    className?: string;
    noContainer?: boolean;
}

export function PlaybookCategoryBadges(props: Props) {
    const items = props.categories.map((category) => {
        const config = playbookCategoryConfig[category];
        return (
            <Badge
                key={category}
                style={{
                    backgroundColor: config.color,
                }}
            >
                {config.title}
            </Badge>
        );
    });

    if (props.noContainer) {
        return <>{items}</>;
    } else {
        return <div className={"flex flex-row flex-wrap gap-1"}>{items}</div>;
    }
}
