import { ContentSpacer } from "../common/layout";
import { Button } from "../../components/ui/button";
import { DialogTrigger } from "../../components/ui/dialog";
import { useOrg, useOrgController } from "../../auth/org-provider";
import CreatePersonDialog from "../../modals/create-person-dialog";
import { Mail, Slack, UserPlus, X } from "lucide-react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../components/ui/table";
import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import Spinner from "../../components/ui/spinner";
import ConfirmationDialog from "../../modals/confirmation-dialog";
import { useSearchParams } from "react-router-dom";
import { Org } from "../../service/tekkr-service-types";
import { AvatarForUser } from "../../components/shared/avatar-for-user";
import { formats } from "tekkr-common/dist/utils/formatting";
import { companyStageNames } from "tekkr-common/dist/model/playbook/enums/enums";
import { config } from "../../lib/config";

function UserRow({ u }: { u: Org["users"][0]; org: Org }) {
    const inviteMutation = useMutation({
        mutationFn: async () => {
            await apiClient.createUserInvite({
                params: { userId: u.id },
                query: {
                    sendEmail: "true",
                },
                body: null,
            });
        },
    });
    return (
        <TableRow>
            <TableCell>
                <div className={"flex flex-row items-center gap-2"}>
                    <AvatarForUser className={"h-8 w-8"} user={u} />
                    {u.name}
                </div>
            </TableCell>
            <TableCell className={"text-muted-foreground"}>
                {u.title ? u.title : <span className={"opacity-45"}>&mdash;</span>}
            </TableCell>
            <TableCell>{u.email}</TableCell>
            <TableCell className={"flex flex-row items-center justify-end gap-2"}>
                {!u.hasAccount && (
                    <>
                        <ConfirmationDialog
                            title={"Send email invite?"}
                            body={<p>Do you want to send an email invite to {u.name}?</p>}
                            confirm={async () => inviteMutation.mutate()}
                        >
                            <DialogTrigger asChild>
                                <Button
                                    variant={"outline-primary"}
                                    className={inviteMutation.isPending ? "invisible" : undefined}
                                    size={"sm"}
                                >
                                    <Mail className={"me-2 h-5 w-5"} />
                                    Invite
                                </Button>
                            </DialogTrigger>
                        </ConfirmationDialog>
                        {inviteMutation.isPending && <Spinner />}
                    </>
                )}
                <Button className={"hidden"} variant={"ghost"} size={"sm"}>
                    <X />
                </Button>
            </TableCell>
        </TableRow>
    );
}

export default function OrgPage() {
    const org = useOrg();
    const orgController = useOrgController();
    const [queryParams, setQueryParams] = useSearchParams();

    const importSlackUsersMutation = useMutation({
        mutationFn: async () => {
            await (
                await apiClient
            ).importSlackUsers({
                body: undefined,
            });
            await orgController.refetch();
            queryParams.delete("import_users");
            setQueryParams(queryParams);
        },
    });

    useEffect(() => {
        document.title = "Manage Organization - Tekkr";
    });

    return (
        <ContentSpacer>
            <h1 className={"slide-in-top-variant-1"}>Your Organization: {org.name}</h1>
            <div className={"flex flex-row items-center gap-4"}>
                <div className={"flex flex-row flex-wrap items-start gap-8"}>
                    <div className={"flex flex-col"}>
                        <span className={"text-xs font-semibold text-muted-foreground"}>Tech Org Size</span>
                        <span className={"font-medium"}>
                            {org.meta?.techOrgSize ?? <span className={"opacity-35"}>&mdash;</span>}
                        </span>
                    </div>
                    <div className={"flex flex-col"}>
                        <span className={"text-xs font-semibold text-muted-foreground"}>Company Size</span>
                        <span className={"font-medium"}>
                            {org.meta?.companySize ?? <span className={"opacity-35"}>&mdash;</span>}
                        </span>
                    </div>
                    <div className={"flex flex-col"}>
                        <span className={"text-xs font-semibold text-muted-foreground"}>Funding Stage</span>
                        <span className={"font-medium"}>
                            {org.meta?.stage ? (
                                companyStageNames[org.meta.stage]
                            ) : (
                                <span className={"opacity-35"}>&mdash;</span>
                            )}
                        </span>
                    </div>
                </div>
                {config.env !== "prod" && (
                    <div
                        className={
                            "construction-tape-bg flex flex-row flex-wrap items-start gap-8 rounded-lg px-3 py-1"
                        }
                    >
                        <div className={"flex flex-col"}>
                            <span className={"text-xs font-semibold text-muted-foreground"}>Office Suite</span>
                            <span className={"font-medium"}>{org.officeSuiteProvider}</span>
                        </div>
                    </div>
                )}
            </div>
            <hr className={"zoom-in-line mb-4 mt-4"} />
            <p className={"slide-in-bottom-variant-2"}>
                Need to bring more team members on board? You can easily add new users to your organization right from
                this page. Expand your team's capabilities and keep everyone in the loop.
            </p>
            <div className={"slide-in-bottom-variant-1 flex flex-row items-center gap-4"}>
                <h3>Members</h3>
                <CreatePersonDialog context={"org"}>
                    <DialogTrigger asChild>
                        <Button size={"sm"} className={"duration-300 animate-in fade-in-50 slide-in-from-left-1"}>
                            <UserPlus className={"h-5 w-5"} />
                            <span className={"ms-2"}>Add Member</span>
                        </Button>
                    </DialogTrigger>
                </CreatePersonDialog>
                <Button
                    size={"sm"}
                    variant={"secondary"}
                    asChild
                    className={"duration-500 animate-in fade-in-50 slide-in-from-left-4"}
                >
                    <a
                        href={`https://slack.com/oauth/v2/authorize?client_id=7950302676806.7950312898054&scope=users:read,users:read.email&user_scope=&redirect_uri=https%3A%2F%2F${window.location.host}/install-slack`}
                    >
                        <Slack className={"me-2 h-5 w-5"} /> Import Members from Slack
                    </a>
                </Button>
            </div>

            <ConfirmationDialog
                open={queryParams.get("import_users") === "slack"}
                title={"Import Slack Members?"}
                body={<p>Do you want to import all members of your slack workspace?</p>}
                confirm={async () => {
                    await importSlackUsersMutation.mutateAsync();
                }}
            />

            <Table className={"slide-in-bottom-variant-3"}>
                <TableBody>
                    <TableRow>
                        <TableHead className="w-[200px]">Name</TableHead>
                        <TableHead className="w-[200px]">Title</TableHead>
                        <TableHead>Email</TableHead>
                        <TableHead></TableHead>
                    </TableRow>
                    {org.users.map((u) => (
                        <UserRow key={u.id} u={u} org={org} />
                    ))}
                </TableBody>
            </Table>
        </ContentSpacer>
    );
}
