"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookCommunicationSchema = exports.playbookCommunicationBlueprintSchema = void 0;
const zod_1 = require("zod");
const edit_1 = require("../segment/content/types/editable-text/edit");
exports.playbookCommunicationBlueprintSchema = zod_1.z.strictObject({
    announcement: zod_1.z
        .strictObject({
        audience: zod_1.z
            .string()
            .describe('This should be a string representing the audience for the announcement. Examples are "first line managers", "tech leadership", "the entire company". This will be used in sentences like "Make sure you announce your playbook to [audience] before you proceed."'),
    })
        .optional(),
});
exports.playbookCommunicationSchema = zod_1.z.strictObject({
    informStakeholdersDone: zod_1.z.boolean(),
    affectedStakeholders: zod_1.z.record(zod_1.z.string(), zod_1.z.object({
        message: edit_1.editableTextContentEditSchema,
        done: zod_1.z.boolean(),
    })),
    announcement: zod_1.z.object({
        message: edit_1.editableTextContentEditSchema,
        done: zod_1.z.boolean(),
    }),
});
