import React from "react";
import { BlueprintWrapper } from "tekkr-common/dist/lib/blueprint-wrapper";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import { createContext, useContext } from "react";

export type BlueprintContextType = {
    blueprint: PlaybookBlueprint;
    bw: BlueprintWrapper;
};
const BlueprintContext = createContext<BlueprintContextType | null>(null);

export function BlueprintProvider(props: React.PropsWithChildren & { blueprint: PlaybookBlueprint }) {
    return (
        <BlueprintContext.Provider
            value={{
                blueprint: props.blueprint,
                bw: new BlueprintWrapper(props.blueprint),
            }}
        >
            {props.children}
        </BlueprintContext.Provider>
    );
}

export function useBlueprint() {
    const val = useContext(BlueprintContext);
    if (!val?.blueprint) {
        throw new Error("blueprint context is unavailable");
    }
    return val;
}
