"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.categorizationContentBlueprintSchema = exports.CategorizationContentMode = void 0;
const zod_1 = require("zod");
const blueprint_1 = require("../../blueprint");
const schema_refinements_1 = require("../../../../../../utils/schema-refinements");
var CategorizationContentMode;
(function (CategorizationContentMode) {
    CategorizationContentMode["tiered"] = "tiered";
    CategorizationContentMode["single"] = "single";
    CategorizationContentMode["multi"] = "multi";
})(CategorizationContentMode || (exports.CategorizationContentMode = CategorizationContentMode = {}));
exports.categorizationContentBlueprintSchema = blueprint_1.editableContentBlueprintSchema
    .extend({
    type: zod_1.z.literal(blueprint_1.ContentType.categorization),
    categories: zod_1.z
        .array(zod_1.z.strictObject({
        id: zod_1.z.string(),
        title: zod_1.z.string(),
    }))
        .superRefine((0, schema_refinements_1.validateUniqueIds)((i) => i.id)),
    items: zod_1.z
        .array(zod_1.z.strictObject({
        id: zod_1.z.string(),
        title: zod_1.z.string(),
        description: zod_1.z
            .string()
            .optional()
            .describe("description of the item (might be collapsable, supports markdown)"),
    }))
        .superRefine((0, schema_refinements_1.validateUniqueIds)((i) => i.id))
        .describe("Each item will be listed and the user is required to pick one of the categories"),
    title: zod_1.z.string().optional().describe("Title shown above the entire table"),
    prompt: zod_1.z.string().optional().describe("Prompt shown only while editing the selection"),
    itemsTitle: zod_1.z.string().optional().describe("Title shown above the list of items"),
    mode: zod_1.z
        .nativeEnum(CategorizationContentMode)
        .describe("single: select one category for each item\nmulti: select multiple categoris for each item\ntiered: select a category for each item and all lower categories are automatically selected"),
    optionalSelection: zod_1.z.boolean().optional().describe("allows the user to semi-check items"),
})
    .strict();
