import { Check, Copy } from "lucide-react";
import { Button } from "../ui/button";
import React from "react";
import { cn } from "../../lib/utils";
import { useFlashVisibility } from "../hooks/flash-visibility";

function copyRichText(rich: string, plain: string) {
    if (typeof ClipboardItem !== "undefined") {
        // Shiny new Clipboard API, not fully supported in Firefox.
        // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#browser_compatibility
        const html = new Blob([rich], { type: "text/html" });
        const text = new Blob([plain], { type: "text/plain" });
        const data = new ClipboardItem({
            "text/html": html,
            "text/plain": text,
        });
        void navigator.clipboard.write([data]);
    } else {
        // Fallback using the deprecated `document.execCommand`.
        // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
        const cb = (e: ClipboardEvent) => {
            e.clipboardData?.setData("text/html", rich);
            e.clipboardData?.setData("text/plain", plain);
            e.preventDefault();
        };
        document.addEventListener("copy", cb);
        document.execCommand("copy");
        document.removeEventListener("copy", cb);
    }
}

const copyConfirmationClasses = ["bg-confirmation/40", "hover:bg-confirmation/40"];

export function CopyButton(props: { onCopy: () => { html: string; raw: string } | null }) {
    const [confirming, showConfirmation] = useFlashVisibility();
    const onCopy = () => {
        showConfirmation();
        const content = props.onCopy();
        if (content) {
            copyRichText(content.html, content.raw);
        }
    };
    return (
        <Button
            variant={"ghost"}
            size={"sm"}
            className={cn("transition-colors", confirming && copyConfirmationClasses)}
            onClick={onCopy}
        >
            <div className={cn("me-2 transition-all", confirming && "")}>
                {confirming ? (
                    <Check className={"h-5 w-5 animate-in spin-in-45"} />
                ) : (
                    <Copy className={"h-5 w-5 animate-in zoom-in-90"} />
                )}
            </div>
            <span className={"transition-all"}>Copy</span>
        </Button>
    );
}
