import React from "react";
import { usePlaybook } from "./playbook";
import { PlaybookAttachment } from "tekkr-common/dist/model/playbook/attachments";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { PlaybookAttachmentReferenceType } from "tekkr-common/dist/model/playbook/enums/enums";
import { SiGoogledocs, SiGooglesheets, SiGoogleslides } from "react-icons/si";
import { FaFileExcel, FaFilePowerpoint, FaFileWord } from "react-icons/fa";
import { undefined } from "zod";

export function usePlaybookAttachments(): {
    attachments: PlaybookAttachment[];
    refetch: () => Promise<void>;
} {
    const { playbook, refetch } = usePlaybook();
    return {
        attachments: playbook.attachments ?? [],
        refetch: async () => refetch(), //todo refetch only attachments
    };
}

interface BaseAttachmentReference {
    type: PlaybookAttachmentTypes;
    url: string;
    title: string;
    createdBy: string;
    createdAt: string;
}

interface AttachmentReference extends BaseAttachmentReference {
    description?: string;
    imageUrl?: string;
}

function getBaseAttachmentReference(attachment: PlaybookAttachment): BaseAttachmentReference {
    const base = {
        createdAt: attachment.createdAt,
        createdBy: attachment.createdBy,
    };
    if (attachment.reference.type === PlaybookAttachmentReferenceType.URL) {
        return {
            ...base,
            type: PlaybookAttachmentTypes.URL,
            title: attachment.reference.name,
            url: attachment.reference.url,
        };
    } else if (attachment.reference.type === PlaybookAttachmentReferenceType.GoogleDriveFile) {
        switch (attachment.reference.fileType) {
            case "document":
                return {
                    ...base,
                    type: PlaybookAttachmentTypes.GoogleDoc,
                    title: "Google Doc",
                    url: `https://docs.google.com/document/d/${attachment.reference.fileId}`,
                };
            case "spreadsheet":
                return {
                    ...base,
                    type: PlaybookAttachmentTypes.GoogleSheet,
                    title: "Google Sheet",
                    url: `https://docs.google.com/spreadsheets/d/${attachment.reference.fileId}`,
                };
            case "presentation":
                return {
                    ...base,
                    type: PlaybookAttachmentTypes.GoogleSlides,
                    title: "Google Slide",
                    url: `https://docs.google.com/presentation/d/${attachment.reference.fileId}`,
                };
        }
    } else if (attachment.reference.type === PlaybookAttachmentReferenceType.MsOfficeFile) {
        const titleAndTypeByDocType: Record<
            "document" | "spreadsheet" | "presentation",
            {
                title: string;
                type: PlaybookAttachmentTypes;
            }
        > = {
            document: {
                title: "Word Document",
                type: PlaybookAttachmentTypes.MsWordDoc,
            },
            presentation: {
                title: "PowerPoint Presentation",
                type: PlaybookAttachmentTypes.MsPowerpointSlides,
            },
            spreadsheet: {
                title: "Excel Sheet",
                type: PlaybookAttachmentTypes.MsExcelSheet,
            },
        };
        return {
            ...base,
            ...titleAndTypeByDocType[attachment.reference.fileType],
            url: attachment.reference.webUrl,
        };
    } else {
        throw new Error(`reference type not supported`);
    }
}

export function useResolvedAttachmentReference(id: string): AttachmentReference {
    const { playbook } = usePlaybook();
    const { attachments } = usePlaybookAttachments();
    const attachment = attachments.find((a) => a.id === id);
    if (!attachment) {
        throw new Error(`attachment with id ${id} does not exist`);
    }

    const extraMetaQuery = useQuery({
        queryKey: [playbook.id, attachment.id, "metadata"],
        queryFn: async () => {
            const res = await apiClient.getPlaybookAttachmentMetadata({
                params: {
                    playbookId: playbook.id,
                    attachmentId: attachment.id,
                },
            });
            return res.body as ClientInferResponseBody<typeof apiContract.getPlaybookAttachmentMetadata, 200>;
        },
    });

    return {
        ...getBaseAttachmentReference(attachment),
        ...extraMetaQuery.data,
    };
}

export enum PlaybookAttachmentTypes {
    GoogleDoc = "googleDoc",
    GoogleSheet = "googleSheet",
    GoogleSlides = "googleSlides",
    MsWordDoc = "msWordDoc",
    MsExcelSheet = "msExcelSheet",
    MsPowerpointSlides = "msPowerpointSlides",
    URL = "url",
}

type AttachmentTypeConfig = {
    icon?: React.ComponentType<{ className?: string }>;
    officeSuiteProvider?: "google" | "microsoft";
    createActionName: string;
    typeDescription: string;
};
const playbookAttachmentTypeConfig: Record<PlaybookAttachmentTypes, AttachmentTypeConfig> = {
    [PlaybookAttachmentTypes.GoogleDoc]: {
        icon: SiGoogledocs as React.ComponentType<{ className?: string }>,
        officeSuiteProvider: "google",
        createActionName: "Create Google Doc",
        typeDescription: "Google Doc",
    },
    [PlaybookAttachmentTypes.GoogleSlides]: {
        icon: SiGoogleslides as React.ComponentType<{ className?: string }>,
        officeSuiteProvider: "google",
        createActionName: "Create Google Slides",
        typeDescription: "Google Slides",
    },
    [PlaybookAttachmentTypes.GoogleSheet]: {
        icon: SiGooglesheets as React.ComponentType<{ className?: string }>,
        officeSuiteProvider: "google",
        createActionName: "Create Google Sheet",
        typeDescription: "Google Sheet",
    },
    [PlaybookAttachmentTypes.MsWordDoc]: {
        icon: FaFileWord as React.ComponentType<{ className?: string }>,
        officeSuiteProvider: "microsoft",
        createActionName: "Create Word Document",
        typeDescription: "Word Document",
    },
    [PlaybookAttachmentTypes.MsPowerpointSlides]: {
        icon: FaFilePowerpoint as React.ComponentType<{ className?: string }>,
        officeSuiteProvider: "microsoft",
        createActionName: "Create PowerPoint Presentation",
        typeDescription: "PowerPoint Presentation",
    },
    [PlaybookAttachmentTypes.MsExcelSheet]: {
        icon: FaFileExcel as React.ComponentType<{ className?: string }>,
        officeSuiteProvider: "microsoft",
        createActionName: "Create Excel Sheet",
        typeDescription: "Excel Sheet",
    },
    [PlaybookAttachmentTypes.URL]: {
        createActionName: "Attach URL",
        typeDescription: "URL",
    },
} as const;

export function getPlaybookAttachmentTypes(officeSuiteProvider: "google" | "microsoft"): PlaybookAttachmentTypes[] {
    const all = Object.values(PlaybookAttachmentTypes);
    return all.filter((t) => {
        const config = playbookAttachmentTypeConfig[t];
        return !config.officeSuiteProvider || config.officeSuiteProvider === officeSuiteProvider;
    });
}

export function getAttachmentType(type: PlaybookAttachmentTypes): {
    AttachmentTypeIcon?: React.ComponentType<{ className?: string }>;
    attachmentTypeCopy: {
        createAction: string;
        description: string;
    };
} {
    const config = playbookAttachmentTypeConfig[type];
    return {
        AttachmentTypeIcon: config.icon,
        attachmentTypeCopy: {
            createAction: config.createActionName,
            description: config.typeDescription,
        },
    };
}
