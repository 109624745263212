import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import * as React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { getInitialsForName } from "../../lib/utils";

function LegacyAvatar(props: { name: string; userId: string; className?: string }) {
    const { data } = useQuery({
        queryKey: ["profile", "picture", props.userId],
        staleTime: Infinity,
        queryFn: async () => {
            const imgData = await apiClient.getProfilePicture({
                params: {
                    userId: props.userId,
                },
            });
            if (imgData.status !== 200) {
                return null;
            }
            return imgData.body;
        },
    });
    return (
        <Avatar className={props.className}>
            <AvatarImage src={data ? `data:image/png;base64,${data}` : undefined} />
            <AvatarFallback>{getInitialsForName(props.name)}</AvatarFallback>
        </Avatar>
    );
}

function NewAvatar(props: { name: string; imageUrl: string; className?: string }) {
    return (
        <Avatar className={props.className}>
            <AvatarImage src={props.imageUrl} />
            <AvatarFallback>{getInitialsForName(props.name)}</AvatarFallback>
        </Avatar>
    );
}

export function AvatarForUser(props: {
    user: {
        id: string;
        name?: string;
        displayName?: string;
        imageUrl?: string;
    };
    className?: string;
}) {
    const name = props.user.displayName ?? props.user.name ?? "";

    if (props.user.imageUrl) {
        return <NewAvatar name={name} imageUrl={props.user.imageUrl} className={props.className} />;
    } else {
        return <LegacyAvatar name={name} userId={props.user.id} className={props.className} />;
    }
}
