import { Copyright } from "lucide-react";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import React from "react";

function Footer() {
    return (
        <div className={"mt-6 flex w-full justify-between px-8 py-3 opacity-45"}>
            <div className={"flex items-center gap-3"}>
                <Copyright className={"h-3 w-3"} />
                <div className={"text-xs"}>Tekkr GmbH</div>
            </div>
            <div className={"flex items-center gap-3"}>
                <Button asChild variant={"ghost"} size={"sm2"}>
                    <a href={"https://www.tekkr.io/contact-us"} target={"_blank"} rel={"noreferrer"}>
                        Contact Us
                    </a>
                </Button>
                <Button variant={"ghost"} size={"sm2"} asChild>
                    <Link to={"https://tekkr.io/terms-of-use"} target={"_blank"}>
                        Terms of Service
                    </Link>
                </Button>
                <Button variant={"ghost"} size={"sm2"}>
                    <Link to={"https://tekkr.io/privacy-policy"} target={"_blank"}>
                        Privacy Policy
                    </Link>
                </Button>
            </div>
        </div>
    );
}

export default Footer;
