import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { BookMarked, Check, Dot, LucideIcon } from "lucide-react";

import { cn } from "../../lib/utils";

type CheckStyle = "regular" | "optional";

const variants = {
    primary: "border-primary data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
    confirmation:
        "border-confirmation data-[state=checked]:bg-confirmation data-[state=checked]:text-confirmation-foreground",
    secondary: "border-secondary data-[state=checked]:bg-secondary data-[state=checked]:text-secondary-foreground",
};

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
        variant?: keyof typeof variants;
        checkStyle?: CheckStyle;
    }
>(({ className, ...props }, ref) => {
    return (
        <CheckboxPrimitive.Root
            ref={ref}
            className={cn(
                "peer h-4 w-4 shrink-0 rounded-sm border ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                variants[props.variant ?? "primary"],
                className
            )}
            {...props}
        >
            <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-current")}>
                {(!props.checkStyle || props.checkStyle === "regular") && <Check className="h-4 w-4" />}
                {props.checkStyle === "optional" && <Dot className="h-3 w-3" strokeWidth={6} />}
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
    );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
