import { icons } from "lucide-react";
import Rating from "../../rating";
import { Avatar, AvatarFallback, AvatarImage } from "../../../ui/avatar";
import { Progress } from "../../../ui/progress";
import React from "react";
import { PlaybookMeta } from "../../playbook-card";
import { getInitialsForName } from "../../../../lib/utils";
import { formats } from "tekkr-common/dist/utils/formatting";
import { useOrg } from "../../../../auth/org-provider";
import { Org } from "../../../../service/tekkr-service-types";

function getRelevance(org: Org, pb: Pick<PlaybookMeta, "companyStages" | "teamSize">): "high" | "medium" | "low" {
    const stageMatch = !org.meta?.stage || pb.companyStages.includes(org.meta.stage);
    const techOrgSizeMatch =
        !org.meta?.techOrgSize ||
        ((pb.teamSize.min ?? 0) < org.meta.techOrgSize && (pb.teamSize.max ?? Infinity) > org.meta.techOrgSize);
    const matches = (stageMatch ? 1 : 0) + (techOrgSizeMatch ? 1 : 0);
    return (["low", "medium", "high"] as const)[matches];
}

const relevanceConfigs: Record<
    "high" | "medium" | "low",
    {
        icon: typeof icons.Users;
        iconColor: string;
        name: string;
    }
> = {
    high: { icon: icons.ArrowUp, iconColor: "green", name: "High" },
    medium: { icon: icons.ArrowRight, iconColor: "yellow", name: "Medium" },
    low: { icon: icons.ArrowDown, iconColor: "orange", name: "Low" },
};

export default function BlueprintStats(props: {
    pb: Pick<PlaybookMeta, "companyStages" | "teamSize" | "author" | "rating">;
}) {
    const org = useOrg();

    const relevance = getRelevance(org, props.pb);
    const stats = [
        {
            icon: icons.Users,
            description: "Tech Team Size",
            value: formats.friendly.teamSizeApplicability(props.pb.teamSize),
        },
        {
            icon: icons.Building,
            description: "Company Stage",
            value: formats.friendly.companyStageApplicability(props.pb.companyStages),
        },
        {
            icon: relevanceConfigs[relevance].icon,
            iconColor: relevanceConfigs[relevance].iconColor,
            description: `Relevance for ${org.name}`,
            value: relevanceConfigs[relevance].name,
        },
    ];

    return (
        <div className={"duration-300 animate-in fade-in slide-in-from-bottom-4"}>
            <hr />
            <div className={"mt-3 flex flex-row justify-between"}>
                <div>
                    <div className={"flex flex-row flex-wrap items-center gap-2"}>
                        <Rating rating={props.pb.rating.score} />
                        {!!props.pb.rating.reviewCount && (
                            <div className={"text-muted-foreground"}>
                                {formats.friendly.rating(props.pb.rating)} (used by {props.pb.rating.reviewCount}{" "}
                                companies)
                            </div>
                        )}
                        {!props.pb.rating.reviewCount && (
                            <div className={"rounded-md bg-primary px-1.5 py-0.5 text-xs font-semibold text-white"}>
                                NEW
                            </div>
                        )}
                    </div>
                    <div className={"mt-2 flex flex-row items-center gap-2"}>
                        <span className={"text-muted-foreground"}>By </span>
                        <Avatar className={"h-6 w-6"}>
                            <AvatarImage src={props.pb.author.imageUrl}></AvatarImage>
                            <AvatarFallback>{getInitialsForName(props.pb.author.name)}</AvatarFallback>
                        </Avatar>
                        <span>
                            <span className={"font-semibold"}>{props.pb.author.name}</span>
                            {props.pb.author.role ? <span>&nbsp;({props.pb.author.role})</span> : null}
                        </span>
                    </div>
                </div>
                <div className={"flex flex-col items-end gap-2"}>
                    <Progress value={0} className={"h-2.5 w-24"} color={"confirmation"}></Progress>
                    <div className={"text-muted-foreground"}>Not Started</div>
                </div>
            </div>
            <div className={"mt-8 flex rounded-lg border bg-accent px-4 py-2"}>
                {stats.map((stat) => {
                    const StatIcon = stat.icon;
                    return (
                        <div key={stat.description} className={"flex w-full flex-row gap-1"}>
                            <StatIcon className={"px-1 py-1"} color={stat.iconColor} />
                            <div className={"flex flex-col"}>
                                <div>{stat.value}</div>
                                <div className={"text-sm text-muted-foreground"}>{stat.description}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
