"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookAttachmentsSchema = exports.attachmentReferenceSchema = void 0;
const zod_1 = require("zod");
const enums_1 = require("./enums/enums");
exports.attachmentReferenceSchema = zod_1.z.discriminatedUnion("type", [
    zod_1.z.strictObject({
        type: zod_1.z.literal(enums_1.PlaybookAttachmentReferenceType.URL),
        url: zod_1.z.string(),
        name: zod_1.z.string(),
    }),
    zod_1.z.strictObject({
        type: zod_1.z.literal(enums_1.PlaybookAttachmentReferenceType.GoogleDriveFile),
        fileId: zod_1.z.string(),
        fileType: zod_1.z.enum(["document", "spreadsheet", "presentation"]),
    }),
    zod_1.z.strictObject({
        type: zod_1.z.literal(enums_1.PlaybookAttachmentReferenceType.MsOfficeFile),
        driveItemId: zod_1.z.string(),
        webUrl: zod_1.z.string(),
        fileType: zod_1.z.enum(["document", "spreadsheet", "presentation"]),
    }),
]);
exports.playbookAttachmentsSchema = zod_1.z.array(zod_1.z.strictObject({
    id: zod_1.z.string(),
    target: zod_1.z.array(zod_1.z.string()).optional(),
    reference: exports.attachmentReferenceSchema,
    createdBy: zod_1.z.string(),
    createdAt: zod_1.z.string(),
}));
