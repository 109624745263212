"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookTodoBlueprintSchema = void 0;
const zod_1 = require("zod");
const blueprint_1 = require("../../segment/blueprint");
const schema_refinements_1 = require("../../../../utils/schema-refinements");
const baseTodoBlueprintSchema = zod_1.z.strictObject({
    id: zod_1.z.string().regex(schema_refinements_1.formats.kebabCaseRegex),
});
const basedOnSchema = zod_1.z.union([
    zod_1.z.object({
        segment: zod_1.z.string(),
    }),
    zod_1.z.object({
        listItem: zod_1.z.object({
            segment: zod_1.z.string(),
            list: zod_1.z.string(),
            item: zod_1.z.string(),
        }),
    }),
]);
const simpleTodoBlueprintSchema = baseTodoBlueprintSchema
    .extend({
    type: zod_1.z.literal(blueprint_1.PlaybookTodoType.simple),
    title: zod_1.z.string(),
    description: zod_1.z.string(),
    basedOn: basedOnSchema.optional(),
    createArtifact: zod_1.z.enum(["document", "presentation"]).optional(),
    relatedPlaybooks: zod_1.z.array(zod_1.z.string()).optional(),
})
    .strict();
const meetingTodoBlueprintSchema = baseTodoBlueprintSchema
    .extend({
    type: zod_1.z.literal(blueprint_1.PlaybookTodoType.meeting),
    description: zod_1.z.string(),
    title: zod_1.z.string(),
    meetingName: zod_1.z.string().optional().describe("segment title is used if no explicit meeting title is specified"),
    schedulingMode: zod_1.z.enum(["individual", "group"]).optional().describe("setting to 'individual' shows one button per person, 'group' shows one button with all people."),
    segmentId: zod_1.z.string(),
})
    .strict();
const listTodoBlueprintSchema = baseTodoBlueprintSchema.extend({
    type: zod_1.z.literal(blueprint_1.PlaybookTodoType.list),
    segmentId: zod_1.z.string(),
    contentId: zod_1.z.string(),
});
const toolTodoBlueprintSchema = baseTodoBlueprintSchema.extend({
    type: zod_1.z.literal(blueprint_1.PlaybookTodoType.tool),
    title: zod_1.z.string(),
    description: zod_1.z.string(),
    segmentId: zod_1.z.string(),
});
exports.playbookTodoBlueprintSchema = zod_1.z.discriminatedUnion("type", [
    simpleTodoBlueprintSchema,
    meetingTodoBlueprintSchema,
    listTodoBlueprintSchema,
    toolTodoBlueprintSchema,
]);
