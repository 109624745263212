import { apiClient, publicApiClient } from "../../../service/tekkr-service";
import { PlaybookTargetsReviewEdit } from "tekkr-common/dist/model/playbook/stages/target-review";
import { PlaybookTargets } from "tekkr-common/dist/model/playbook/targets";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { PlaybookPeopleGroups } from "tekkr-common/dist/model/playbook/people-groups";
import { PlaybookAlignmentEdit } from "tekkr-common/dist/model/playbook/stages/alignment";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import { PlaybookImplementation } from "tekkr-common/dist/model/playbook/stages/implementation";
import { PlaybookCommunication } from "tekkr-common/dist/model/playbook/stages/communication";
import { publicApiContract } from "tekkr-common/dist/model/api/public.contract";

const playbookMutationKeys: Record<keyof typeof playbookMutations, string> = {
    updateTargetReview: "update-target-review",
    updateTargets: "update-targets",
    updateEdit: "update-edit",
    updatePeopleGroups: "update-people-groups",
    updateAlignment: "update-alignment",
    updateImplementation: "update-implementation",
    updateCommunication: "update-communication",
};

export const playbookQueries = {
    getPlaybook: (playbookId: string) => ({
        queryKey: ["get-playbook", playbookId],
        queryFn: async () => {
            const playbookResponse = await apiClient.getPlaybookById({
                params: { playbookId },
            });
            const playbook = (playbookResponse.body as ClientInferResponseBody<typeof apiContract.getPlaybookById, 200>)
                .data;
            const blueprintResponse = await apiClient.getBlueprintById({
                params: { id: playbook.blueprintId },
            });
            const blueprint = blueprintResponse.body as ClientInferResponseBody<
                typeof apiContract.getBlueprintById,
                200
            > as PlaybookBlueprint;
            return { playbook, blueprint };
        },
    }),
    getSharedPlaybook: (key: string) => ({
        queryKey: ["get-shared-playbook", key],
        queryFn: async () => {
            const sharedPlaybookResponse = await publicApiClient.getSharedPlaybook({
                params: {
                    key: key,
                },
            });
            return sharedPlaybookResponse.body as ClientInferResponseBody<
                typeof publicApiContract.getSharedPlaybook,
                200
            >;
        },
    }),
};

export const playbookMutations = {
    updateTargetReview: (playbookId: string) => ({
        mutationKey: [playbookMutationKeys.updateTargetReview, playbookId],
        mutationFn: async (edit: PlaybookTargetsReviewEdit) => {
            if (!edit) throw new Error("missing edit");
            await apiClient.updatePlaybookTargetsReview({
                params: { playbookId },
                body: edit,
            });
            return true;
        },
        meta: {
            indicatePlaybookEditing: true,
        },
    }),
    updateTargets: (playbookId: string) => ({
        mutationKey: [playbookMutationKeys.updateTargets, playbookId],
        mutationFn: async (targets: PlaybookTargets) => {
            await (
                await apiClient
            ).updatePlaybookTargets({
                body: targets,
                params: {
                    playbookId: playbookId,
                },
            });
        },
        meta: {
            indicatePlaybookEditing: true,
        },
    }),
    updateEdit: (playbookId: string) => ({
        mutationKey: [playbookMutationKeys.updateEdit, playbookId],
        mutationFn: async (edit: PlaybookEdit) => {
            if (!edit) throw new Error("missing edit");
            await apiClient.updatePlaybookEdit({
                params: { playbookId },
                body: edit,
            });
            return true;
        },
        meta: {
            indicatePlaybookEditing: true,
        },
    }),
    updatePeopleGroups: (playbookId: string) => ({
        mutationKey: [playbookMutationKeys.updatePeopleGroups, playbookId],
        mutationFn: async (peopleGroups: PlaybookPeopleGroups) => {
            await apiClient.updatePlaybookPeopleGroups({
                params: { playbookId },
                body: peopleGroups,
            });
            return true;
        },
        meta: {
            indicatePlaybookEditing: true,
        },
    }),
    updateAlignment: (playbookId: string) => ({
        mutationKey: [playbookMutationKeys.updateAlignment, playbookId],
        mutationFn: async (alignment: PlaybookAlignmentEdit) => {
            await apiClient.updatePlaybookAlignment({
                params: { playbookId },
                body: alignment,
            });
            return true;
        },
        meta: {
            indicatePlaybookEditing: true,
        },
    }),
    updateImplementation: (playbookId: string) => ({
        mutationKey: [playbookMutationKeys.updateImplementation, playbookId],
        mutationFn: async (implementation: PlaybookImplementation) => {
            await apiClient.updatePlaybookImplementation({
                params: { playbookId },
                body: implementation,
            });
            return true;
        },
        meta: {
            indicatePlaybookEditing: true,
        },
    }),
    updateCommunication: (playbookId: string) => ({
        mutationKey: [playbookMutationKeys.updateCommunication, playbookId],
        mutationFn: async (communication: PlaybookCommunication) => {
            await apiClient.updatePlaybookCommunication({
                params: { playbookId },
                body: communication,
            });
            return true;
        },
        meta: {
            indicatePlaybookEditing: true,
        },
    }),
};
