import { cn } from "../../lib/utils";
import { Progress } from "../ui/progress";
import { Check } from "lucide-react";
import { Link } from "react-router-dom";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";
import React from "react";
import { PlaybookState } from "tekkr-common/dist/model/playbook/state";
import { IconForPlaybookStage } from "./icon-playbook-stage";
import { formats } from "tekkr-common/dist/utils/formatting";
import { PlaybookCategoryBadges } from "./playbook-category-badges";
import { useOrg } from "../../auth/org-provider";
import { AvatarForUser } from "./avatar-for-user";

export type PlaybookMeta = ClientInferResponseBody<typeof apiContract.listPlaybooks, 200>["data"][0];

interface PlaybookCardProps {
    layout: "vertical" | "horizontal";
    hide?: "summary"[];
    data: PlaybookMeta;
    state: PlaybookState;
    linkTo?: string;
    className?: string;
}

function TransitionStagePlaybookStateFooter(props: { stage: PlaybookStage }) {
    return (
        <div className={"flex flex-row items-center gap-1.5 text-sm"}>
            <div className={"me-1 text-muted-foreground"}>Next:</div>
            <IconForPlaybookStage stage={props.stage} className={"inline-block h-4 w-4"} />
            <div>{playbookStageTitles.upcoming[props.stage]}</div>
        </div>
    );
}

function ProgressBarPlaybookStateFooter(props: { stage: PlaybookStage; progress: number }) {
    return (
        <div className={"flex flex-row items-center gap-3 text-sm"}>
            <div className={"flex flex-row items-center gap-1.5 text-muted-foreground"}>
                <IconForPlaybookStage stage={props.stage} className={"inline-block h-4 w-4 shrink-0"} />
                <div className={"shrink-0"}>{playbookStageTitles.ongoing[props.stage]}:</div>
            </div>
            <Progress
                value={props.progress * 100}
                className={"h-2 w-auto shrink grow"}
                color={"confirmation"}
            ></Progress>
            <div className={"flex-shrink-0"}>{Math.round(props.progress * 100)}%</div>
        </div>
    );
}

function PlaybookStateFooter(props: { state: PlaybookState }) {
    const firstNonCompleted = Object.values(PlaybookStage).find((stage) => {
        return props.state[stage] !== undefined && props.state[stage]! < 1;
    });

    if (!firstNonCompleted) {
        return (
            <div className={"flex flex-row items-center gap-2 text-sm font-semibold text-confirmation"}>
                <Check className={"h-4 w-4"} /> completed
            </div>
        );
    }

    if (props.state[firstNonCompleted] === 0) {
        return <TransitionStagePlaybookStateFooter stage={firstNonCompleted} />;
    } else {
        return <ProgressBarPlaybookStateFooter stage={firstNonCompleted} progress={props.state[firstNonCompleted]!} />;
    }
}

function UserHighlight(props: { userIds: string[] }) {
    const relevantIds = new Set(props.userIds.slice(0, 3));
    const org = useOrg();
    const users = org.users.filter((u) => relevantIds.has(u.id));

    return (
        <div className={"flex flex-row items-center gap-2 text-sm font-medium"}>
            <div className={"flex shrink-0 flex-row ps-1.5"}>
                {users.map((u) => (
                    <AvatarForUser key={u.id} user={u} className={"-ms-1.5 h-6 w-6 outline outline-background"} />
                ))}
            </div>
            <div className={"line-clamp-1 grow"}>{formats.friendly.commaList(users.map((u) => u.name))}</div>
        </div>
    );
}

function PlaybookCard(props: PlaybookCardProps) {
    const highlightUsers = [props.data.contributors.owner];
    if (props.data.contributors.driver) {
        highlightUsers.push(props.data.contributors.driver);
    }

    const card = (
        <div
            data-testid="playbook-card"
            className={cn(
                "group relative overflow-hidden rounded-lg border bg-background p-4 transition-all duration-300 animate-in fade-in slide-in-from-bottom-3 hover:bg-accent",
                props.layout === "vertical" ? "min-w-48" : null,
                props.className
            )}
        >
            <div className={cn("flex gap-4", props.layout === "horizontal" ? "flex-row" : "flex-col")}>
                <img
                    alt={"Playbook Cover"}
                    className={cn(
                        "aspect-3/2 rounded-lg border-0 bg-accent object-cover",
                        props.layout === "horizontal" ? "w-1/3 shrink-0" : null
                    )}
                    src={props.data.imageUrl}
                />
                <div className={cn("flex flex-col gap-2", props.layout === "vertical" ? "h-64" : undefined)}>
                    <div>
                        <PlaybookCategoryBadges categories={props.data.categories} />
                        <h4 className={cn("mt-2 line-clamp-3")}>{props.data.title}</h4>
                    </div>
                    <UserHighlight userIds={highlightUsers} />
                    <p className={"line-clamp-5 shrink overflow-hidden overflow-ellipsis"}>{props.data.summary}</p>
                    <div className={"grow"}></div>
                    <hr />
                    {props.state !== undefined && <PlaybookStateFooter state={props.state} />}
                </div>
            </div>
        </div>
    );

    return <Link to={props.linkTo ?? `/playbook/${props.data.id}`}>{card}</Link>;
}

export default PlaybookCard;
