import React from "react";
import { StagePageContainer } from "../stage-container";
import { TekkrCard } from "../../../../components/shared/tekkr-card";
import { usePlaybook } from "../../controller/hooks/playbook";
import { usePlaybookCommunicationEdit } from "../../controller/hooks/communication-edit";
import { EditScope } from "../../../../components/hooks/use-edit";
import EditableTextSegmentContentView from "../../../../components/shared/playbook-view/components/content/types/editable-text";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import { prompts } from "../../../../service/prompts";
import { useBlueprint } from "../../controller/hooks/blueprint";
import { formats } from "tekkr-common/dist/utils/formatting";

export function AnnouncementCommunicationPage() {
    const { blueprint } = useBlueprint();
    const { playbook } = usePlaybook();
    const { communicationEdit, updateCommunication } = usePlaybookCommunicationEdit();
    const communication = blueprint.communication!;

    const generateMessage = async () => {
        return await prompts.communicateAnnouncementMessage({
            playbookId: playbook.id,
        });
    };

    const onEditUpdated = () => {
        updateCommunication();
    };

    const onNext = () => {
        communicationEdit.announcement.done = true;
        updateCommunication();
    };

    return (
        <StagePageContainer
            onNext={onNext}
            header={`Announce Playbook`}
            explainer={
                "It's time to celebrate 🎉  Your playbook is implemented and you're on the final stretch. Time to announce what you have done!"
            }
        >
            <EditScope onEditUpdated={onEditUpdated}>
                <TekkrCard cardStyle={communicationEdit.announcement.done ? "confirmation" : "primary"}>
                    <h3>
                        {formats.friendly.titleCase(`Announce Playbook to ${communication.announcement!.audience}`)}
                    </h3>
                    <p className={"mb-4 mt-2"}>
                        Adjust and then send this message to {communication.announcement!.audience.toLowerCase()} to let
                        everyone know that your playbook is completed (and to celebrate a bit!)
                    </p>
                    <EditableTextSegmentContentView
                        content={{
                            type: ContentType.editableText,
                            id: "message",
                        }}
                        copy={"message"}
                        textGenerator={generateMessage}
                        edit={communicationEdit.announcement.message}
                        isEditing={true}
                    />
                </TekkrCard>
            </EditScope>
        </StagePageContainer>
    );
}
