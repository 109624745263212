import React from "react";
import { useColorScheme } from "../../../lib/color-scheme";
import { useLocalStorage } from "@uidotdev/usehooks";
import { localStorageKeys } from "../../../lib/local-storage-keys";
import { Button } from "../../../components/ui/button";
import { MoonStarIcon, SunIcon, XIcon } from "lucide-react";
import { Tabs, TabsList, TabsTrigger } from "../../../components/ui/tabs";

export function DiscoveryPageThemeBanner() {
    const { isDark, setIsDark } = useColorScheme();
    const [dismissed, setDismissed] = useLocalStorage<boolean>(localStorageKeys.discoveryThemeBannerDismissed, false);

    if (dismissed) {
        return null;
    }

    return (
        <div className={"mb-8 flex flex-col items-center self-stretch align-middle"}>
            <div
                className={
                    "flex w-full max-w-2xl flex-row items-center gap-4 rounded-md px-4 py-2 text-sm outline outline-accent"
                }
            >
                {isDark ? <SunIcon /> : <MoonStarIcon />}
                <div className={"flex grow flex-col"}>
                    <span className={"font-semibold"}>Prefer {isDark ? "Light" : "Dark"} Mode?</span>
                    <span>Choose your preferred color scheme for Tekkr.</span>
                </div>
                <Tabs value={isDark ? "dark" : "light"} onValueChange={(mode: string) => setIsDark(mode === "dark")}>
                    <TabsList className={"h-auto"}>
                        <TabsTrigger value="light" className={"flex flex-col gap-1 text-xs"}>
                            <SunIcon className={"h-5 w-5"} />
                            Light
                        </TabsTrigger>
                        <TabsTrigger value="dark" className={"flex flex-col gap-1 text-xs"}>
                            <MoonStarIcon className={"h-5 w-5"} />
                            Dark
                        </TabsTrigger>
                    </TabsList>
                </Tabs>
                <Button variant={"ghost"} className={"px-2 py-2"} onClick={() => setDismissed(true)}>
                    <XIcon className={"h-5 w-5"} />
                </Button>
            </div>
        </div>
    );
}
