import React from "react";
import { Dialog, DialogContent, DialogTitle } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { useAuth } from "../auth/auth-provider";
import { useOrg } from "../auth/org-provider";
import { PlaybookSidebarNavigation } from "../pages/playbook/sidebar";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";
import { usePlaybookPageNavigation } from "../pages/playbook/playbook-page";

export function PlaybookEditCompletedDialog(
    props: React.PropsWithChildren & {
        open?: boolean;
        onOpenChange?: (open: boolean) => void;
        nav: PlaybookSidebarNavigation;
    }
) {
    const { user } = useAuth();
    const org = useOrg();
    const nextStage = props.nav.stages.find((s) => s.stage !== PlaybookStage.edit)!;

    const pageNav = usePlaybookPageNavigation();

    const getStarted = () => {
        props.onOpenChange?.(false);
        pageNav!.goToNext();
    };

    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            {props.children}
            <DialogContent className="flex max-w-lg flex-col items-center gap-8 p-12 text-center">
                <DialogTitle>
                    <div className={"flex flex-col gap-2"}>
                        <h2>Great job, {user.displayName?.split(" ")[0]}! 🥇</h2>
                        <p className={"text-muted-foreground"}>You've completed editing your playbook.</p>
                    </div>
                </DialogTitle>
                <p>
                    Well done editing this playbook based on your company's needs. It's likely that you need to make
                    small changes later, but that is super easy. Now it’s time to make real progress at {org.name}! Next
                    up: {playbookStageTitles.upcoming[nextStage.stage]}.
                </p>
                <Button onClick={getStarted} className={"transition-all duration-500 hover:drop-shadow-glow-primary"}>
                    Get started
                </Button>
            </DialogContent>
        </Dialog>
    );
}
