import { usePlaybook } from "./playbook";
import { PlaybookAlignmentEdit } from "tekkr-common/dist/model/playbook/stages/alignment";
import { useMutation } from "@tanstack/react-query";
import { playbookMutations } from "../queries";
import { useCallback } from "react";
import debounce from "debounce";

function emptyAlignmentEdit(): PlaybookAlignmentEdit {
    return {
        playbookReviews: {},
        roastings: {},
    };
}

export function usePlaybookAlignmentEdit() {
    const { playbook } = usePlaybook();

    if (!playbook.alignment) {
        playbook.alignment = emptyAlignmentEdit();
    }
    const edit = playbook.alignment!;

    const mutation = useMutation(playbookMutations.updateAlignment(playbook.id));
    const onUpdated = useCallback(
        debounce(() => {
            mutation.mutate(edit);
        }, 500),
        [playbook.id]
    );

    return {
        alignmentEdit: edit,
        updateAlignment: onUpdated,
    };
}
