export enum PlaybookAttachmentReferenceType {
    URL = "url",
    GoogleDriveFile = "google-drive-file",
    MsOfficeFile = "ms-office-file",
}

export enum PlaybookVisibility {
    public = "public",
    paid = "paid",
    dev = "dev",
}

export enum CompanyStage {
    bootstrapped = "bootstrapped",
    preSeed = "pre_seed",
    seed = "seed",
    seriesA = "series_a",
    seriesB = "series_b",
    seriesC = "series_c",
}

export const companyStageNames: Record<CompanyStage, string> = {
    [CompanyStage.bootstrapped]: "Bootstrapped",
    [CompanyStage.preSeed]: "Pre-Seed",
    [CompanyStage.seed]: "Seed",
    [CompanyStage.seriesA]: "Series A",
    [CompanyStage.seriesB]: "Series B",
    [CompanyStage.seriesC]: "Series C",
};

export enum PlaybookCategory {
    product_management = "product_management",
    people_management = "people_management",
    stakeholder_management = "stakeholder_management",
    sdlc = "sdlc",
    target_setting = "target_setting",
    personal_growth = "personal_growth",
    engineering = "engineering",
    fundamentals = "fundamentals",
}

export const playbookCategoryConfig: Record<
    PlaybookCategory,
    {
        color: string;
        title: string;
    }
> = {
    [PlaybookCategory.people_management]: { color: "#483263", title: "People Management" },
    [PlaybookCategory.product_management]: { color: "#80423E", title: "Product Management" },
    [PlaybookCategory.sdlc]: { color: "#3B6495", title: "SDLC" },
    [PlaybookCategory.stakeholder_management]: { color: "#7C6351", title: "Stakeholder Management" },
    [PlaybookCategory.target_setting]: { color: "#5B7F8C", title: "Target Setting" },
    [PlaybookCategory.personal_growth]: { color: "#884263", title: "Personal Growth" },
    [PlaybookCategory.engineering]: { color: "#4B7163", title: "Engineering" },
    [PlaybookCategory.fundamentals]: { color: "#2D4B5C", title: "Fundamentals" },
};

export enum PlaybookStage {
    edit = "edit",
    targets = "targets",
    alignment = "alignment",
    implementation = "implementation",
    communication = "communication",
    tracking = "tracking",
}

export enum CommunicationChannel {
    message = "message",
    meeting = "meeting",
}
