import { ContentSpacer } from "../common/layout";
import { BookMarked, BookOpen, BookUserIcon, Search } from "lucide-react";
import { useAuth } from "../../auth/auth-provider";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import LoadingCard from "../../components/shared/loading-card";
import { useDebouncedCallback } from "use-debounce";
import { Input } from "../../components/ui/input";
import { cn } from "../../lib/utils";
import { useOrg } from "../../auth/org-provider";
import BlueprintCard from "../../components/shared/cards/blueprint-card";
import { MyPlaybooksList } from "./components/my-playbooks-list";
import { PlaybookRecommendations } from "./components/recommendations";
import { DiscoveryPageThemeBanner } from "./components/theme-banner";
import _ from "lodash";

type Blueprint = ClientInferResponseBody<typeof apiContract.listBlueprints, 200>["data"][0];

const allBlueprintsQuery = (orgId: string) => ({
    queryKey: ["all-blueprints", orgId],
    queryFn: async () => {
        const response = await apiClient.listBlueprints({ query: {} });
        const list = (response.body as ClientInferResponseBody<typeof apiContract.listBlueprints, 200>).data;
        return _.sortBy(list, (it) => (it.access === "access" ? 0 : 1));
    },
});
const searchBlueprintsQuery = (orgId: string, term: string) => ({
    queryKey: ["blueprints-search", orgId],
    queryFn: async () => {
        const response = await apiClient.listBlueprints({
            query: {
                term,
            },
        });
        return (response.body as ClientInferResponseBody<typeof apiContract.listBlueprints, 200>).data;
    },
});

function BlueprintsList(props: { display?: "grid" | "list"; blueprints: Blueprint[]; header?: string }) {
    const layoutClassName = cn(
        props.display === "list" ? "flex flex-col gap-6" : "grid lg:grid-cols-3 sm:grid-cols-2 gap-3"
    );

    // We can assume by this point that `isSuccess === true`
    return (
        <div>
            <DiscoveryPageSectionTitle icon={BookOpen}>
                {props.header ?? "Browse All Playbooks"}
            </DiscoveryPageSectionTitle>
            <div className={layoutClassName}>
                {props.blueprints.map((blueprint) => (
                    <BlueprintCard
                        key={blueprint.id}
                        layout={props.display === "list" ? "horizontal" : "vertical"}
                        data={blueprint}
                    />
                ))}
            </div>
        </div>
    );
}

function getFeaturedBlueprint(blueprints: Blueprint[]): Blueprint | undefined {
    const accessible = blueprints.filter((bp) => bp.access === "access");
    if (accessible.length === 1) {
        return accessible[0];
    }
    return undefined;
}

function DiscoveryPage() {
    const auth = useAuth();
    const org = useOrg();

    useEffect(() => {
        document.title = "Tekkr";
    });

    const bpQuery = useQuery(allBlueprintsQuery(org.id));

    const [searchTerm, setSearchTerm] = useState("");
    const searchQuery = useQuery(searchBlueprintsQuery(org.id, searchTerm));
    const debouncedRefetch = useDebouncedCallback(() => {
        searchQuery.refetch();
    }, 250);
    function onSearchTermChange(term: string) {
        setSearchTerm(term);
        debouncedRefetch();
    }

    const featured = bpQuery.data ? getFeaturedBlueprint(bpQuery.data) : undefined;

    // todo nicer empty state for search results

    return (
        <ContentSpacer>
            <div className={"flex flex-col items-center gap-4 py-8"}>
                <DiscoveryPageThemeBanner />
                <h1 className={"w-full max-w-3xl text-center duration-300 animate-in fade-in slide-in-from-top-1"}>
                    Hey {auth.user?.displayName?.trim().split(" ")[0]}!<br />
                    What can we help you master today?
                </h1>
                <p className={"max-w-3xl text-center duration-500 animate-in fade-in slide-in-from-bottom-1"}>
                    Tekkr Playbooks is a curation of the most advanced ideas, practices and tools brought together by
                    Tekkr and other tech organizations to make tech teams work better.
                </p>
                <div className={"relative mt-4 w-full max-w-md duration-500 animate-in fade-in slide-in-from-bottom-2"}>
                    <Input
                        value={searchTerm}
                        onChange={(e) => onSearchTermChange(e.target.value)}
                        className={"bg-input pe-3 ps-10"}
                        placeholder={"Search for title, topics or pain points..."}
                    ></Input>
                    <div className={"absolute bottom-0 left-0 top-0 px-3 py-3"}>
                        <Search className={"h-4 w-4"} />
                    </div>
                </div>
            </div>

            {searchTerm.trim() === "" && (
                <>
                    {featured && (
                        <>
                            <div className={"mt-8 flex flex-row justify-start gap-2"}>
                                <BookMarked />
                                <h4>Your personal playbook awaits!</h4>
                            </div>
                            <BlueprintCard layout={"horizontal"} data={featured} />
                        </>
                    )}

                    <MyPlaybooksList />
                    <PlaybookRecommendations />

                    {bpQuery.isError && (
                        <div className={"rounded-lg border border-destructive p-4 text-center"}>
                            <p className={"font-semibold text-muted-foreground"}>Error: {bpQuery.error.message}</p>
                        </div>
                    )}
                    {bpQuery.isPending && (
                        <div className={"grid gap-3 duration-700 animate-in fade-in sm:grid-cols-2 md:grid-cols-3"}>
                            {Array.from({ length: 4 }).map((_, index) => (
                                <LoadingCard key={index} />
                            ))}
                        </div>
                    )}
                    {!bpQuery.isPending && !bpQuery.isError && (
                        <BlueprintsList display={"grid"} blueprints={bpQuery.data!} />
                    )}
                </>
            )}

            {searchTerm !== "" && (
                <>
                    {searchQuery.isError && (
                        <div className={"rounded-lg border border-destructive p-4 text-center"}>
                            <p className={"font-semibold text-muted-foreground"}>Error: {searchQuery.error.message}</p>
                        </div>
                    )}
                    {searchQuery.isPending && (
                        <div className={"flex flex-col gap-3"}>
                            {Array.from({ length: 4 }).map((_, index) => (
                                <LoadingCard key={index} />
                            ))}
                        </div>
                    )}
                    {!searchQuery.isPending && !searchQuery.isError && (
                        <>
                            {searchQuery.data!.length > 0 && (
                                <BlueprintsList
                                    header={
                                        searchQuery.data?.length !== 0
                                            ? `Search results for "${searchTerm.trim()}"`
                                            : undefined
                                    }
                                    display={"list"}
                                    blueprints={searchQuery.data!}
                                />
                            )}
                            {searchQuery.data!.length === 0 && (
                                <>
                                    <div className={"text-center"}>
                                        <p className={"font-semibold"}>
                                            We couldn't find any playbooks related to your search 😢
                                        </p>
                                        <p className={"mt-4 text-center text-sm text-muted-foreground"}>
                                            Make sure all words are spelled correctly.
                                            <br />
                                            Try different or more general keywords.
                                        </p>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </ContentSpacer>
    );
}

export default DiscoveryPage;

export function DiscoveryPageSectionTitle(
    props: React.PropsWithChildren<{
        icon: typeof BookUserIcon;
    }>
) {
    const Icon = props.icon;
    return (
        <div className={"mb-6 mt-12 flex flex-row justify-start gap-2 duration-500 animate-in fade-in"}>
            <Icon />
            <h4>{props.children}</h4>
        </div>
    );
}
