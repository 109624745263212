import React, { ReactElement, useRef } from "react";
import { usePlaybook } from "../pages/playbook/controller/hooks/playbook";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { Button } from "../components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { BookMarked, Copy } from "lucide-react";
import Spinner from "../components/ui/spinner";
import { config } from "../lib/config";

export function SharePlaybookDialog(props: { open: boolean; onOpenChange: (open: boolean) => void }) {
    const { playbook } = usePlaybook();

    const shareMutation = useMutation({
        mutationFn: async () => {
            const res = await (
                await apiClient
            ).sharePlaybook({
                params: {
                    playbookId: playbook.id,
                },
                body: undefined,
            });
            return res.body as ClientInferResponseBody<typeof apiContract.sharePlaybook, 201>;
        },
    });

    if (shareMutation.isIdle && !shareMutation.data && props.open) {
        shareMutation.mutate();
    }

    const linkInputRef = useRef<HTMLInputElement>(null);

    let dialogContent: ReactElement;
    if (shareMutation.data) {
        const link =
            `https://${config.env === "dev" ? "link-dev" : "link"}.tekkr.io/shared/playbook/` + shareMutation.data.key;
        const confirmationCopyButtonClasses = [
            "text-confirmation",
            "bg-confirmation/20",
            "border-transparent",
            "opacity-100",
        ];
        const runConfirmCopyAnimation = () => {
            linkInputRef.current?.classList.add(...confirmationCopyButtonClasses);
            setTimeout(() => {
                linkInputRef.current?.classList.remove(...confirmationCopyButtonClasses);
            }, 1_000);
        };
        const copyUrl = () => {
            runConfirmCopyAnimation();
            void navigator.clipboard.writeText(link);
        };
        dialogContent = (
            <>
                <div
                    ref={linkInputRef}
                    className={
                        "relative overflow-hidden text-nowrap rounded-lg border-2 border-accent px-3 py-2 text-sm font-semibold transition-all"
                    }
                >
                    {link}
                    <div
                        onClick={copyUrl}
                        className={
                            "absolute bottom-0 right-0 top-0 flex cursor-pointer flex-col justify-center bg-gradient-to-r from-transparent to-background pe-3 ps-20 text-foreground"
                        }
                    >
                        <Copy className={"h-5 w-5"} />
                    </div>
                </div>
                <p className={"text-sm text-muted-foreground"}>
                    Anyone with this link can view this playbook. They will not be able to make any changes unless
                    they're a member of your organization.
                </p>
            </>
        );
    } else {
        dialogContent = (
            <div className={"flex w-full flex-col items-center rounded-md border border-input p-6"}>
                <Spinner />
            </div>
        );
    }

    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            <DialogContent className="max-w-lg">
                <DialogHeader>
                    <DialogTitle className={"text-start"}>Share Playbook</DialogTitle>
                    <DialogHeader>
                        <div className={"mt-2 flex flex-row gap-2"}>
                            <BookMarked />
                            {playbook.edit!.title}
                        </div>
                    </DialogHeader>
                </DialogHeader>
                {dialogContent}
                <DialogFooter>
                    <Button onClick={() => props.onOpenChange(false)}>Close</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
