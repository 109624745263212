import React, { HTMLProps, PropsWithChildren } from "react";
import { cn } from "../../lib/utils";

export type TekkrCardStyle = "primary" | "confirmation" | "disabled";

interface Props {
    cardStyle?: TekkrCardStyle;
}

const lineBgClasses: Record<TekkrCardStyle, string> = {
    primary: "bg-primary",
    confirmation: "bg-confirmation",
    disabled: "bg-accent",
};

export function TekkrCard(props: PropsWithChildren<Pick<HTMLProps<HTMLDivElement>, "className">> & Props) {
    return (
        <div className={"relative overflow-clip rounded-lg border p-4 ps-5"}>
            <div
                className={cn(
                    "absolute bottom-0 left-0 top-0 w-0.5 transition-all duration-500",
                    lineBgClasses[props.cardStyle ?? "primary"]
                )}
            ></div>
            <div className={props.className}>{props.children}</div>
        </div>
    );
}
