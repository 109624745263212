import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { useCurrentOrgUser, useOrgController } from "../../auth/org-provider";

type OAuthService = "google" | "asana" | "microsoft";

export function useConnectOAuthService(force: boolean = false): {
    isPending: boolean;
    connect: (service: OAuthService) => Promise<void>;
} {
    const orgController = useOrgController();

    const mutation = useMutation({
        mutationFn: async (service: OAuthService) => {
            const res = await apiClient.linkOauthAccount({
                params: {
                    service: service,
                },
            });
            const body = res.body as ClientInferResponseBody<typeof apiContract.linkOauthAccount>;
            return body.authUrl;
        },
    });

    const currentUser = useCurrentOrgUser();

    return {
        isPending: mutation.isPending,
        connect: (service) => {
            if (currentUser.linkedAccounts.includes(service) && !force) {
                // already connected
                return Promise.resolve();
            }
            return new Promise((resolve) => {
                mutation.mutateAsync(service).then((url) => {
                    const w = window.open(url);
                    const i = setInterval(async () => {
                        if (w?.closed) {
                            void orgController.refetch();
                            // todo re-check here if we're actually connected
                            clearInterval(i);
                            resolve();
                        }
                    }, 500);
                });
            });
        },
    };
}

// wrap some sort of action (a function) in some logic that connects the given OAuth service before executing the action
export function useOAuthServiceAction(
    service: OAuthService,
    action: () => Promise<void> | void
): {
    action: () => Promise<void>;
    serviceConnected: boolean;
    isPending: boolean;
} {
    const currentUser = useCurrentOrgUser();
    const serviceConnected = currentUser.linkedAccounts.includes(service);

    const { isPending, connect } = useConnectOAuthService();

    return {
        serviceConnected,
        isPending: isPending,
        action: async () => {
            await connect(service);
            await action();
        },
    };
}
