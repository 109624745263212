import React from "react";
import { Tooltip, TooltipContent } from "../ui/tooltip";
import { DateTime } from "luxon";

interface Props {
    data: {
        title: string;
        summary: string;
        imageUrl: string;
        createdOn: Date;
    };
}

export function PlaybookTooltip(props: React.PropsWithChildren<Props>) {
    const createdOnString = DateTime.fromJSDate(props.data.createdOn).toLocaleString({
        year: "numeric",
        month: "long",
        day: "numeric",
    });
    return (
        <Tooltip disableHoverableContent={true}>
            {props.children}
            <TooltipContent className={"flex w-80 flex-col gap-0 p-4 shadow-black/50 drop-shadow-md"}>
                <img
                    src={props.data.imageUrl}
                    alt={"Playbook Cover"}
                    className={"h-52 rounded-md border-0 bg-accent object-cover"}
                />
                <div className={"mt-2 text-base font-semibold"}>{props.data.title}</div>
                <div className={"text-sm font-medium text-muted-foreground"}>Created on {createdOnString}</div>
            </TooltipContent>
        </Tooltip>
    );
}
