import { ReactComponent as Parloa } from "../../static/partner-logos/parloa.svg";
import { ReactComponent as Prima } from "../../static/partner-logos/prima.svg";
import { ReactComponent as Workpath } from "../../static/partner-logos/workpath.svg";
import { ReactComponent as Forto } from "../../static/partner-logos/forto.svg";
import { ReactComponent as Vimcar } from "../../static/partner-logos/vimcar.svg";
import React from "react";

export default function CustomerLogos(props: React.HTMLProps<HTMLDivElement>) {
    return (
        <div {...props}>
            <p className={"mb-2 text-center text-xs font-semibold text-muted-foreground"}>Trusted by Tech Leaders</p>
            <div className={"grid grid-cols-5 items-stretch justify-center gap-x-3"}>
                <div className={"flex flex-col items-center justify-center"}>
                    <Parloa className={"[&_*]:fill-foreground"} />
                </div>
                <div className={"flex flex-col items-center justify-center"}>
                    <Prima className={"[&_*]:fill-foreground"} />
                </div>
                <div className={"flex flex-col items-center justify-center"}>
                    <Workpath className={"[&_*]:fill-foreground"} />
                </div>
                <div className={"flex flex-col items-center justify-center"}>
                    <Forto className={"[&>_*]:fill-foreground"} />
                </div>
                <div className={"flex flex-col items-center justify-center"}>
                    <Vimcar className={"[&_*]:fill-foreground"} />
                </div>
            </div>
        </div>
    );
}
