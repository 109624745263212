import {
    Calendar,
    CalendarClock,
    ChartLine,
    Check,
    Flame,
    LightbulbIcon,
    ListTodo,
    Megaphone,
    MessagesSquare,
    Pen,
    Target,
} from "lucide-react";
import React from "react";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { cn } from "../../lib/utils";
import { IconForPlaybookStage } from "../../components/shared/icon-playbook-stage";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";
import { Button } from "../../components/ui/button";
import AnimateHeight from "react-animate-height";
import { useFlashVisibility } from "../../components/hooks/flash-visibility";

type SidebarButtonVariant = "active" | "upcoming" | "done";

type StepIcon = "target" | "communicate" | "schedule" | "edit" | "roasting" | "todos" | "messages" | "graphs" | "wait";

type NavState = "done" | "todo";

interface SubNavItem {
    step: string;
    icon: StepIcon;
    name: string;
    id?: string;
    state: NavState;
    blocked?: boolean;
}

export interface PlaybookSidebarNavigation {
    stages: {
        stage: PlaybookStage;
        state: NavState;
        steps: SubNavItem[];
    }[];
}

type NavPosition = {
    stage: PlaybookStage;
    step?: string;
    stepId?: string;
};

type PlaybookSidebarProps = {
    nav: PlaybookSidebarNavigation;
    selected: NavPosition;
    recommendedPosition?: NavPosition;
    editDone: boolean;
    onSelect: (selected: NavPosition) => void;
};

function isSamePosition(pos1: NavPosition, pos2: NavPosition): boolean {
    return pos1.stage === pos2.stage && pos1.step === pos2.step && pos1.stepId === pos2.stepId;
}

function StepIconView(props: React.HTMLProps<SVGSVGElement> & { name: StepIcon }) {
    const { name } = props;
    if (name === "target") {
        return <Target {...props} />;
    } else if (name === "schedule") {
        return <Calendar {...props} />;
    } else if (name === "communicate") {
        return <Megaphone {...props} />;
    } else if (name === "edit") {
        return <Pen {...props} />;
    } else if (name === "roasting") {
        return <Flame {...props} />;
    } else if (name === "todos") {
        return <ListTodo {...props} />;
    } else if (name === "messages") {
        return <MessagesSquare {...props} />;
    } else if (name === "graphs") {
        return <ChartLine {...props} />;
    } else if (name === "wait") {
        return <CalendarClock {...props} />;
    }
    throw new Error(`unsupported step icon ${name}`);
}

function isStageDone(stage: { stage: PlaybookStage; state: NavState }) {
    return stage.state === "done";
}

const sidebarButtonStyles: Record<SidebarButtonVariant, string> = {
    active: "hover:cursor-pointer hover:bg-accent font-semibold",
    upcoming: "opacity-65 cursor-not-allowed",
    done: "text-confirmation hover:cursor-pointer hover:bg-accent",
};
type SidebarStageButtonProps = {
    stage: PlaybookStage;
    variant: SidebarButtonVariant;
    expanded: boolean;
    selected: boolean;
    onSelect: () => void;
    onUpcomingClick?: () => void;
};
function SidebarStageButton(props: SidebarStageButtonProps) {
    const onClick = props.variant === "upcoming" ? props.onUpcomingClick : props.onSelect;
    return (
        <div
            onClick={onClick}
            className={cn(
                "flex flex-row items-center gap-2 rounded-md px-4 py-2 transition-all",
                sidebarButtonStyles[props.variant],
                props.selected ? "bg-accent" : undefined
            )}
        >
            <IconForPlaybookStage className={"h-5 w-5"} stage={props.stage} />
            {playbookStageTitles.upcoming[props.stage]}
        </div>
    );
}

interface SidebarStepButtonProps {
    onSelect: PlaybookSidebarProps["onSelect"];
    stage: PlaybookStage;
    item: SubNavItem;
    selected: boolean;
    recommended?: boolean;
    enabled: boolean;
}
function SidebarStepButton(props: SidebarStepButtonProps) {
    const isDone = props.item.state === "done";
    const Icon = isDone ? Check : StepIconView;

    const onClick = props.enabled
        ? () =>
              props.onSelect({
                  stage: props.stage,
                  step: props.item.step,
                  stepId: props.item.id,
              })
        : undefined;

    return (
        <div
            className={cn(
                "ms-1 flex w-full flex-row items-start gap-2 rounded-md px-2 py-1 text-sm text-muted-foreground transition-all",
                props.recommended && "font-semibold text-foreground",
                props.enabled ? "cursor-pointer hover:bg-accent" : "cursor-not-allowed",
                props.selected && "bg-accent text-foreground"
            )}
            onClick={onClick}
        >
            <Icon className={"mt-0.5 h-4 w-4 shrink-0"} name={props.item.icon} />
            {props.item.name}
        </div>
    );
}

function ResumePlaybookProcessShortcut(props: {
    onResume: () => void;
    recommendedPosition: NavPosition;
    nav: PlaybookSidebarNavigation;
    hide?: boolean;
}) {
    const stage = props.recommendedPosition.stage;
    const step = props.nav.stages
        .find((s) => s.stage === stage)!
        .steps?.find((s) => s.step === props.recommendedPosition.step && s.id === props.recommendedPosition.stepId);
    return (
        <AnimateHeight height={props.hide ? 0 : "auto"}>
            <div className={"flex w-full flex-col pt-4"}>
                <div
                    className={
                        "mb-2 text-center text-sm text-muted-foreground duration-500 animate-in fade-in slide-in-from-top-1"
                    }
                >
                    Pick up where you left:
                </div>
                <div
                    className={
                        "flex flex-col items-center rounded-md bg-accent px-3 py-2 duration-500 animate-in fade-in slide-in-from-top-3"
                    }
                >
                    <div className={"mb-1 flex flex-row items-center text-sm font-semibold"}>
                        <IconForPlaybookStage stage={stage} className={"me-1 h-4 w-4"} />
                        {playbookStageTitles.upcoming[stage]}
                    </div>
                    <div className={"text-sm"}>{step?.name}</div>
                    <Button onClick={props.onResume} variant={"secondary"} size={"sm2"} className={"mt-2 w-full"}>
                        Resume
                    </Button>
                </div>
            </div>
        </AnimateHeight>
    );
}

type NavStage = PlaybookSidebarNavigation["stages"][0];
function stageButtonVariant(stage: NavStage, previousStage: NavStage | undefined): SidebarButtonVariant {
    if (isStageDone(stage)) {
        return "done";
    }
    if (!previousStage || isStageDone(previousStage)) {
        return "active";
    }
    return "upcoming";
}

function StepButtonContainerAndLine(
    props: React.PropsWithChildren<{
        done: boolean;
        hideLine: boolean;
        selected: boolean;
    }>
) {
    return (
        <div className={"flex flex-row"}>
            <div
                className={cn(
                    "my-1 ms-6 w-0.5 shrink-0 rounded-md",
                    props.done ? "bg-confirmation" : "bg-border",
                    !props.hideLine && "min-h-4",
                    props.hideLine && !props.selected && "invisible"
                )}
            ></div>
            <AnimateHeight className={"mb-1 mt-2 w-full"} height={props.selected ? "auto" : 0}>
                <div className={"flex min-h-6 w-full flex-col gap-1 pb-1"}>{props.children}</div>
            </AnimateHeight>
        </div>
    );
}

export function PlaybookSidebar(props: PlaybookSidebarProps) {
    const [completeEditHintVisible, showCompleteEditHint] = useFlashVisibility(6_000);

    const stageButtons = props.nav.stages.map((stage, index) => {
        const prev = index === 0 ? undefined : props.nav.stages[index - 1];
        const variant = !props.editDone ? "upcoming" : stageButtonVariant(stage, prev);
        const stageSelected = props.selected.stage === stage.stage;
        const isLast = index === props.nav.stages.length - 1;

        const stepButtons = stage.steps.map((i, index) => {
            const prev = index === 0 ? undefined : stage.steps?.[index - 1];
            const stepSelected = props.selected.step === i.step && (!i.id || props.selected.stepId === i.id);
            const position: NavPosition = { stage: stage.stage, step: i.step, stepId: i.id };
            return (
                <SidebarStepButton
                    enabled={(!prev || prev.state !== "todo") && !i.blocked}
                    recommended={props.recommendedPosition && isSamePosition(props.recommendedPosition, position)}
                    key={i.step + "-" + i.id}
                    onSelect={() => props.onSelect(position)}
                    stage={stage.stage}
                    item={i}
                    selected={stepSelected}
                />
            );
        });

        return (
            <div key={stage.stage}>
                <SidebarStageButton
                    key={stage.stage}
                    stage={stage.stage}
                    variant={variant}
                    expanded={stageSelected}
                    selected={stageSelected && !props.selected.step}
                    onSelect={() => props.onSelect({ stage: stage.stage })}
                    onUpcomingClick={!props.editDone ? () => showCompleteEditHint() : undefined}
                />
                <StepButtonContainerAndLine done={variant === "done"} hideLine={isLast} selected={stageSelected}>
                    {stepButtons}
                </StepButtonContainerAndLine>
            </div>
        );
    });

    return (
        <div
            className={
                "w-64 shrink-0 select-none border-r-2 border-r-accent p-8 py-0 duration-500 animate-in fade-in slide-in-from-left"
            }
        >
            <div className={"py-9 sticky top-16"}>
                {stageButtons}
                {props.recommendedPosition && (
                    <ResumePlaybookProcessShortcut
                        nav={props.nav}
                        hide={props.selected.stage !== PlaybookStage.edit}
                        recommendedPosition={props.recommendedPosition}
                        onResume={() => props.onSelect(props.recommendedPosition!)}
                    />
                )}
                <AnimateHeight height={completeEditHintVisible ? "auto" : 0}>
                    <div className={"mt-4 flex flex-col gap-1 rounded-md bg-accent p-3 text-sm font-medium"}>
                        <div className={"flex flex-row items-center gap-2 font-semibold text-muted-foreground"}>
                            <LightbulbIcon className={"h-4 w-4 shrink-0"} />
                            Hint
                        </div>
                        Set all sections of the playbook to "done", to begin implementing your playbook.
                    </div>
                </AnimateHeight>
            </div>
        </div>
    );
}
