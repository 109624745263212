import Markdown, { Components } from "react-markdown";
import { Info } from "lucide-react";
import React, { ReactElement, ReactNode } from "react";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
import remarkGfm from "remark-gfm";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "../../ui/table";
import { cn } from "../../../lib/utils";
import { Badge } from "../../ui/badge";
import { ArrowMarkdownComponent } from "./components/arrow";
import { InlineEditableMarkdownComponent } from "./components/inline-editable";
import { ReferenceMarkdownComponent } from "./components/reference";

const customMarkdownComponents: Record<string, Components["p"]> = {
    arrow(props) {
        return <ArrowMarkdownComponent {...props} />;
    },
    numbered(props) {
        return (
            <div className={"my-1 flex flex-row items-start gap-2"}>
                <div
                    className={
                        "mr-1 inline-block h-6 w-6 shrink-0 rounded-xl bg-accent text-center text-sm font-semibold leading-6 text-muted-foreground"
                    }
                >
                    {props.id}
                </div>
                <div>{props.children}</div>
            </div>
        );
    },
    info(props) {
        return (
            <div className={"flex flex-row items-start gap-1 space-x-1 text-sm text-muted-foreground"}>
                <Info className={"-mt-0.5 shrink-0 p-1"} />
                <div>{props.children}</div>
            </div>
        );
    },
    infobox(props) {
        return (
            <div
                className={
                    "flex flex-row items-start gap-1 space-x-1 rounded-md border p-3 text-sm text-muted-foreground"
                }
            >
                <Info className={"-mt-0.5 shrink-0 p-1"} />
                <div>{props.children}</div>
            </div>
        );
    },
    br() {
        return <br />;
    },
    editable(props) {
        if (!props.id) {
            throw new Error("editable directive requires an id.");
        }
        return <InlineEditableMarkdownComponent id={props.id} text={props.children as string} />;
    },
    reference(props) {
        if (typeof props.children !== "string") {
            throw new Error(`reference must have string content`);
        }
        const [type, ref] = props.children.split(/\s*;\s*/gi);

        return <ReferenceMarkdownComponent type={type} id={ref} />;
    },
};

const markdownComponents: Components = {
    h1(props) {
        return <h1 className={"mt-4"}>{props.children}</h1>;
    },
    h2(props) {
        return <h2 className={"mt-4"}>{props.children}</h2>;
    },
    h3(props) {
        return <h3 className={"mt-3"}>{props.children}</h3>;
    },
    h4(props) {
        return <h4>{props.children}</h4>;
    },
    table(props) {
        if (!props.children) {
            return <></>;
        }
        const firstRow = (props.children as ReactElement[])[1].props.children[0].props.children;
        const width = firstRow.length <= 4 ? 100 : 30 * firstRow.length;
        return (
            <div className={"overflow-x-scroll"}>
                <div style={{ width: `${width}%` }}>
                    <Table>{props.children}</Table>
                </div>
            </div>
        );
    },
    thead(props) {
        return <TableHeader>{props.children}</TableHeader>;
    },
    tbody(props) {
        return <TableBody>{props.children}</TableBody>;
    },
    tr(props) {
        return <TableRow>{props.children}</TableRow>;
    },
    td(props) {
        return <TableCell>{props.children}</TableCell>;
    },
    a(props) {
        return <a className={"underline"} target={"_blank"} rel={"noreferrer"} {...props}></a>;
    },
    p(props) {
        const isSimpleArrowParagraph = typeof props.children === "string" && props.children.startsWith("->");
        const isComplexArrowParagraph =
            props.children &&
            typeof props.children === "object" &&
            "0" in props.children &&
            typeof props.children[0] === "string" &&
            props.children[0].startsWith("->");
        if (isSimpleArrowParagraph || isComplexArrowParagraph) {
            let children = props.children;
            if (isSimpleArrowParagraph) {
                children = (children as string).slice(2);
            } else if (isComplexArrowParagraph) {
                children = [((children as ReactNode[])[0] as string).slice(2), ...(children as ReactNode[]).slice(1)];
            }
            return <ArrowMarkdownComponent {...props}>{children}</ArrowMarkdownComponent>;
        }
        return <p {...props}>{props.children}</p>;
    },
    blockquote(props) {
        return <blockquote className={"border-0 border-l-4 border-secondary pl-2 italic"}>{props.children}</blockquote>;
    },
};

const allMarkdownComponents = {
    ...markdownComponents,
    ...customMarkdownComponents,
};

export function TekkrMarkdown(props: { markdown: string; className?: string }) {
    return (
        <div className={cn("flex w-full flex-col gap-2", props.className)}>
            <Markdown
                remarkPlugins={[remarkDirective, remarkDirectiveRehype, remarkGfm]}
                components={allMarkdownComponents}
            >
                {props.markdown}
            </Markdown>
        </div>
    );
}
