import {
    PlaybookTargetsReviewEdit,
    PlaybookTargetsReviewExpectationReview,
} from "tekkr-common/dist/model/playbook/stages/target-review";
import React, { useState } from "react";
import { usePlaybookPeopleGroups } from "../../controller/hooks/people-groups";
import { SegmentContainer } from "../../../../components/shared/playbook-view/components/segment";
import { Org } from "../../../../service/tekkr-service-types";
import { useScheduleEvent } from "../../../../lib/event-scheduling";
import { useScopedEdit } from "../../../../components/hooks/use-edit";
import { getCopyForTargetReviewTemplate } from "../../../../copy/target-reviews";
import {
    SelectContentSubject,
    selectSubjectConfigs,
} from "tekkr-common/dist/model/playbook/segment/content/types/select/blueprint";
import { usePlaybook } from "../../controller/hooks/playbook";
import PeopleListSegmentContentView from "../../../../components/shared/playbook-view/components/content/types/people-list";
import ListSegmentContentView from "../../../../components/shared/playbook-view/components/content/types/list";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import SelectSegmentContentView from "../../../../components/shared/playbook-view/components/content/types/select";
import { Badge } from "../../../../components/ui/badge";
import { MeetingSchedulingMatrix } from "../../components/meeting-scheduling-matrix-view";
import { AiPlug } from "../../../../components/shared/ai-plug";
import { prompts } from "../../../../service/prompts";
import { useBlueprint } from "../../controller/hooks/blueprint";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { useOrg } from "../../../../auth/org-provider";

export function ExpectationReviewView(props: {
    reviewBlueprint: PlaybookTargetsReviewExpectationReview;
    reviewEdit: PlaybookTargetsReviewEdit;
}) {
    if (!(props.reviewBlueprint.id in props.reviewEdit.reviews)) {
        props.reviewEdit.reviews[props.reviewBlueprint.id] = {
            meetingsScheduled: [],
            meetingDuration: {},
            meetingQuestions: {},
        };
    }

    const edit = props.reviewEdit.reviews[props.reviewBlueprint.id];

    const { blueprint: playbookBlueprint } = useBlueprint();
    const { playbook } = usePlaybook();
    const org = useOrg();
    const peopleGroupBlueprint = playbookBlueprint.peopleGroups!.find(
        (it) => it.id === props.reviewBlueprint.peopleGroup
    )!;
    const peopleGroups = usePlaybookPeopleGroups();
    const [userIdsMeetingScheduled, setUserIdsMeetingScheduled] = useState<string[]>(edit["meetingsScheduled"] ?? []);

    const [meetingUsers] = peopleGroups.byId(props.reviewBlueprint.peopleGroup);

    const { state, updateEdit } = useScopedEdit(edit, (e) => ({
        done: e.done ?? false,
        hidden: e.hidden ?? false,
        meetingDuration: selectSubjectConfigs.meeting_duration.options.find(
            (option) => option.id === (e.meetingDuration.selected ?? "15min")
        )!.numeric!,
    }));
    const setDone = (done: boolean) => {
        updateEdit((e) => {
            e.done = done;
        });
    };

    const onMeetingsScheduledUpdated = (meetingsScheduled: string[]) => {
        setUserIdsMeetingScheduled(meetingsScheduled);
        updateEdit((e) => {
            e.meetingsScheduled = meetingsScheduled;
        });
    };

    const copy = getCopyForTargetReviewTemplate(props.reviewBlueprint.copy.template);

    // todo this should invalidate when the selected questions change
    const [meetingDescriptionCache] = useState<{ value?: string }>({
        value: undefined,
    });

    const scheduleEventInExternalTool = useScheduleEvent();
    const schedule = async (user: Org["users"][0]) => {
        const questions: string[] = [];
        edit?.meetingQuestions?.customItems?.forEach((question) => questions.push(question.title));
        edit?.meetingQuestions?.selectedItems?.forEach((selected) => {
            const question = props.reviewBlueprint.suggestedMeetingQuestions.find((q) => q.id === selected);
            if (question) {
                questions.push(question.title);
            }
        });
        if (!meetingDescriptionCache.value) {
            meetingDescriptionCache.value = await prompts.targetsExpectationReviewMeetingDescription({
                playbookId: playbook.id,
                dynamicVars: {
                    questions: questions.map((q) => "- " + q).join("\n"),
                },
                format: "html",
            });
        }
        await scheduleEventInExternalTool(org.officeSuiteProvider, {
            description: meetingDescriptionCache.value,
            eventName: `Expectation Review for "${copy.title}"`,
            invitees: [user.email],
            meetingDuration: { minute: state.meetingDuration },
            context: {
                playbookId: playbook.id,
                scope: [PlaybookStage.targets, "review", props.reviewBlueprint.id, user.id],
            },
        });
    };

    return (
        <SegmentContainer
            id={props.reviewBlueprint.id}
            name={"Expectation Review Meeting"}
            title={copy.title}
            hidden={state.hidden}
            expanded={true}
            done={state.done}
            setDone={setDone}
            isEditing={true}
            options={{
                hideControls: true,
            }}
        >
            <div className={"flex flex-col gap-4 pb-4"}>
                <h3>Select {peopleGroupBlueprint.name}</h3>

                <p>
                    Select the {peopleGroupBlueprint.name.toLowerCase()} that you want to schedule review meeting with.
                    For this playbook, Tekkr recommends you include the following roles:
                </p>
                <div className={"flex flex-row flex-wrap gap-2"}>
                    {peopleGroupBlueprint.roles?.map((r) => (
                        <Badge variant={"secondary"} key={r}>
                            {r}
                        </Badge>
                    ))}
                </div>

                <PeopleListSegmentContentView
                    content={{
                        id: "people",
                        peopleGroupId: peopleGroupBlueprint.id,
                        communicate: false,
                        title: peopleGroupBlueprint.name,
                        type: ContentType.peopleList,
                    }}
                    edit={{}}
                    isEditing={true}
                    options={{ hideTitle: true }}
                />
            </div>
            <hr className={"-mx-10"} />
            <div className={"flex flex-col gap-4 pt-4"}>
                <h3>Meeting Setup</h3>
                <p>
                    You should run a target review meeting with each of your {peopleGroupBlueprint.name.toLowerCase()}.
                    You can configure the setup and content of those meetings below:
                </p>
                <SelectSegmentContentView
                    content={{
                        default: "15min",
                        descriptionMarkdown: "::info[Tekkr recommends 15 minutes]",
                        id: "duration",
                        subject: SelectContentSubject.meetingDuration,
                        type: ContentType.select,
                    }}
                    edit={edit.meetingDuration}
                    isEditing={true}
                />
                <ListSegmentContentView
                    content={{
                        extendable: true,
                        id: "questions",
                        items: props.reviewBlueprint.suggestedMeetingQuestions,
                        title: "Questions to Discuss",
                        prompt: "Identify 2-3 questions to add to the meeting agenda so you can run the meeting effectively.",
                        type: ContentType.list,
                    }}
                    edit={edit.meetingQuestions}
                    isEditing={true}
                />
            </div>
            <br />
            <hr className={"-mx-10"} />
            <h3 className={"pt-4"}>Schedule Review Meetings</h3>
            <AiPlug className={"rounded-md border px-4 py-2"}>
                Tekkr will generate a meeting description based on your targets and the questions you picked above.
            </AiPlug>
            <MeetingSchedulingMatrix
                scheduled={userIdsMeetingScheduled}
                onScheduledUpdated={onMeetingsScheduledUpdated}
                users={meetingUsers}
                schedule={schedule}
            />
        </SegmentContainer>
    );
}
