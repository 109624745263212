import { ExpectationReviewView } from "./expectation-review-view";
import React from "react";
import {
    PlaybookTargetsReviewEdit,
    PlaybookTargetsReviewExpectationReview,
} from "tekkr-common/dist/model/playbook/stages/target-review";
import { StagePageContainer } from "../stage-container";
import { EditScope, useScopedEdit } from "../../../../components/hooks/use-edit";
import { usePlaybookTargetsReviewEdit } from "../../controller/hooks/target-review-edit";
import { getCopyForTargetReviewTemplate } from "../../../../copy/target-reviews";
import { useBlueprint } from "../../controller/hooks/blueprint";

function PageContent(props: { edit: PlaybookTargetsReviewEdit; review: PlaybookTargetsReviewExpectationReview }) {
    const { updateEdit } = useScopedEdit(props.edit, () => {});

    const copy = getCopyForTargetReviewTemplate(props.review.copy.template);

    const onNext = () => {
        updateEdit(() => {
            props.edit.reviews[props.review.id].done = true;
        });
    };
    return (
        <StagePageContainer header={copy.navigationTitle} explainer={copy.description} onNext={onNext}>
            <ExpectationReviewView reviewBlueprint={props.review} reviewEdit={props.edit} />
        </StagePageContainer>
    );
}

export function ScheduleTargetReviewsPage(props: { targetReviewId: string }) {
    const { blueprint } = useBlueprint();
    const { targetsReviewEdit, updateTargetsReview } = usePlaybookTargetsReviewEdit();

    const review = blueprint.targetReview?.expectationReviews?.find((it) => it.id === props.targetReviewId);
    if (!review) {
        throw new Error(`could not find target review "${props.targetReviewId}" in blueprint`);
    }

    return (
        <EditScope onEditUpdated={updateTargetsReview}>
            <PageContent edit={targetsReviewEdit} review={review} />
        </EditScope>
    );
}
