import { Button } from "../ui/button";
import {
    BookLock,
    BookMarked,
    Building,
    Cloud, ConstructionIcon,
    GraduationCapIcon,
    LifeBuoy,
    LineChart,
    LogOut,
    MoonStar,
    Sun,
} from "lucide-react";
import { ReactComponent as TekkrLogoWhite } from "../../static/tekkr-logo-white.svg";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import React from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Link, useNavigate } from "react-router-dom";
import { useAuthController, useAuthOptional } from "../../auth/auth-provider";
import { useColorScheme } from "../../lib/color-scheme";
import { OrgSelector, useOrgController, useOrgOptional } from "../../auth/org-provider";
import { config } from "../../lib/config";
import { getInitialsForName } from "../../lib/utils";
import { useGlobalDialogs } from "../../modals/global-dialogs";
import { localStorageKeys } from "../../lib/local-storage-keys";

function Navbar() {
    const { isDark, setIsDark } = useColorScheme();

    function toggleDarkMode() {
        setIsDark(!isDark);
    }

    const auth = useAuthOptional();
    const authController = useAuthController();
    const orgController = useOrgController();
    const org = useOrgOptional();

    const globalDialogs = useGlobalDialogs();

    const navigate = useNavigate();

    function logout() {
        authController
            .logout()
            .then(() => {
                navigate("/login");
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function selectOrg(id: string) {
        OrgSelector.setSelectedOrg(id);
        orgController.refetch();
    }

    return (
        <div className={"z-50 duration-300 animate-in fade-in slide-in-from-top-2"}>
            <div className={"h-16"}></div>
            <div className={"fixed left-0 right-0 top-0 z-40 h-16 border-b-2 border-b-accent bg-background px-8"}>
                <div className={"flex items-center justify-between py-3"}>
                    <div className={"flex items-center gap-2"}>
                        <Link to={"/"}>
                            <div
                                className={
                                    "me-2 flex h-10 w-10 cursor-pointer flex-col items-center justify-center rounded-lg bg-primary"
                                }
                            >
                                <TekkrLogoWhite />
                                {config.envTitle ? (
                                    <code
                                        className={"font-semibold text-primary-foreground"}
                                        style={{ fontSize: "0.5rem" }}
                                    >
                                        {config.envTitle}
                                    </code>
                                ) : null}
                            </div>
                        </Link>
                        {org && (
                            <Button asChild variant={"ghost"}>
                                <Link to={"/library"}>
                                    <BookMarked className={"me-2 h-5 w-5"} />
                                    My Playbooks
                                </Link>
                            </Button>
                        )}
                        <Button asChild variant={"ghost"}>
                            <Link to={"/analytics"}>
                                <LineChart className={"me-2 h-5 w-5"} />
                                Analytics
                            </Link>
                        </Button>
                        {org && !("unrestricted" in org.playbookAccess) && (
                            <Button
                                onClick={() => globalDialogs?.showUnlockAllPlaybooksCta()}
                                variant={"default"}
                                className={"transition-all duration-500 hover:drop-shadow-glow-primary"}
                            >
                                <BookLock className={"mr-2 h-4 w-4"} />
                                <span>Unlock all Playbooks</span>
                            </Button>
                        )}
                    </div>
                    {auth && (
                        <div className={"flex items-center gap-4"}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Avatar data-testid={"button-navbar-account"} className={"h-9 w-9 cursor-pointer"}>
                                        <AvatarImage src={auth.user?.photoURL ?? undefined} alt="Profile" />
                                        <AvatarFallback>
                                            {getInitialsForName(auth.user.displayName ?? "")}
                                        </AvatarFallback>
                                    </Avatar>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="me-4 mt-1 w-56">
                                    <DropdownMenuLabel
                                        onClick={() => {
                                            if (config.env !== "prod") auth.user.getIdToken().then(console.log);
                                        }}
                                    >
                                        {auth.user?.displayName}
                                        <br />
                                        <span className={"text-xs text-muted-foreground"}>{auth.user?.email}</span>
                                    </DropdownMenuLabel>
                                    <DropdownMenuSeparator />
                                    <DropdownMenuGroup>
                                        <DropdownMenuItem onClick={() => toggleDarkMode()}>
                                            {isDark ? (
                                                <Sun className="mr-2 h-4 w-4" />
                                            ) : (
                                                <MoonStar className="mr-2 h-4 w-4" />
                                            )}
                                            <span>{isDark ? "Light" : "Dark"} Mode</span>
                                        </DropdownMenuItem>
                                    </DropdownMenuGroup>
                                    {!!org && (
                                        <>
                                            <DropdownMenuSeparator />
                                            <DropdownMenuItem onClick={() => navigate(`/org/${org.id}`)}>
                                                <div className={"flex flex-col"}>
                                                    <div className={"flex flex-row items-center"}>
                                                        <Building className="mr-2 h-4 w-4" />
                                                        <span>Manage Organization</span>
                                                    </div>
                                                    <div className={"ml-6 text-sm text-muted-foreground"}>
                                                        {org?.name}
                                                    </div>
                                                </div>
                                            </DropdownMenuItem>
                                        </>
                                    )}
                                    {auth.account.orgs.length > 1 && (
                                        <DropdownMenuSub>
                                            <DropdownMenuSubTrigger>
                                                <span>Switch Organization</span>
                                            </DropdownMenuSubTrigger>
                                            <DropdownMenuPortal>
                                                <DropdownMenuSubContent>
                                                    {auth.account.orgs.map((org) => (
                                                        <DropdownMenuItem
                                                            key={org.id}
                                                            onClick={() => selectOrg(org.id)}
                                                        >
                                                            <Building className={"me-2 h-4 w-4"} />
                                                            <span>{org.name}</span>
                                                        </DropdownMenuItem>
                                                    ))}
                                                </DropdownMenuSubContent>
                                            </DropdownMenuPortal>
                                        </DropdownMenuSub>
                                    )}
                                    <DropdownMenuSeparator />
                                    {(config.env !== "prod" || localStorage.getItem("tekkr-show-dev-options")) && (
                                        <DropdownMenuSub>
                                            <DropdownMenuSubTrigger className={"construction-tape-bg"}>
                                                <ConstructionIcon className={"w-4 h-4 me-2"} />
                                                <span>Dev Options</span>
                                            </DropdownMenuSubTrigger>
                                            <DropdownMenuPortal>
                                                <DropdownMenuSubContent>
                                                    <NavbarDevOptions />
                                                </DropdownMenuSubContent>
                                            </DropdownMenuPortal>
                                        </DropdownMenuSub>
                                    )}
                                    <Link to={"https://tekkr.io/contact-us"} target={"_blank"}>
                                        <DropdownMenuItem>
                                            <LifeBuoy className="mr-2 h-4 w-4" />
                                            <span>Support</span>
                                        </DropdownMenuItem>
                                    </Link>
                                    <DropdownMenuItem className={"hidden"} disabled>
                                        <Cloud className="mr-2 h-4 w-4" />
                                        <span>API</span>
                                    </DropdownMenuItem>
                                    <DropdownMenuSeparator />
                                    <DropdownMenuItem onClick={() => logout()}>
                                        <LogOut className="mr-2 h-4 w-4" />
                                        <span>Log out</span>
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Navbar;

function NavbarDevOptions () {
    const resetTutorials = () => {
        const keys = [
            localStorageKeys.tutorialEditSegmentDismissed,
            localStorageKeys.discoveryThemeBannerDismissed,
            localStorageKeys.tekkrEmailBotDisclaimerDismissed,
        ];
        keys.forEach(key => localStorage.removeItem(key));
    }
    return <>
        <DropdownMenuItem onClick={resetTutorials}>
            <GraduationCapIcon className={"h-4 w-4 me-2"} />
            Reset Tutorials
        </DropdownMenuItem>
    </>
}
