import { Button } from "../../components/ui/button";
import React, { useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import {
    GoogleAuthProvider,
    OAuthProvider,
    signInWithPopup,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile,
} from "firebase/auth";
import { getFirebaseAuth } from "../../auth/firebase";
import { OnboardingPage } from "../common/layout";
import { useAuthController } from "../../auth/auth-provider";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Spinner from "../../components/ui/spinner";
import { FirebaseError } from "firebase/app";
import { cn } from "../../lib/utils";
import CustomerLogos from "../../components/shared/customer-logos";

const emailLoginFormSchema = z.object({
    email: z
        .string()
        .min(2, { message: "Please enter your email address." })
        .max(100, { message: "This email address is too long." }),
    password: z
        .string()
        .min(2, { message: "Please enter your password" })
        .max(50, { message: "This password is too long." }),
});

type EmailLoginFormType = z.infer<typeof emailLoginFormSchema>;

function EmailLoginForm(props: { onLogin: (credentials: EmailLoginFormType) => Promise<void> }) {
    const firebaseErrorMessageMap: Record<string, { message: string; field?: keyof EmailLoginFormType }> = {
        "auth/invalid-credential": {
            message: "Invalid email address and/or password",
        },
        "auth/too-many-requests": {
            message:
                "This account has been disabled temporarily because of too many login attempts. Reset your password or try again later.",
        },
    };
    const form = useForm<EmailLoginFormType>({
        resolver: zodResolver(emailLoginFormSchema),
        defaultValues: {
            email: "",
            password: "",
        },
    });
    const onSubmit = (credentials: EmailLoginFormType) => {
        props.onLogin(credentials).catch((err) => {
            if (err instanceof FirebaseError) {
                const mapped = firebaseErrorMessageMap[err.code];
                if (mapped) {
                    return form.setError(mapped.field ?? "root.error", {
                        message: mapped.message,
                    });
                }
            }
            return form.setError("root.error", {
                message: "Something went wrong :( Please try again later or reach out to us at support@tekkr.io",
            });
        });
    };
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={"space-y-2"}>
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className={"pe-2"}>Email</FormLabel>
                            <FormControl className={"col-span-4"}>
                                <Input type={"email"} placeholder="john@company.com" {...field} />
                            </FormControl>
                            <div></div>
                            <FormMessage className={"col-span-4"} />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className={"pe-2"}>
                                Password{" "}
                                <Link
                                    className={"ms-2 hidden text-sm text-muted-foreground hover:underline"}
                                    to={"/reset-password"}
                                >
                                    Forgot your password?
                                </Link>
                            </FormLabel>
                            <FormControl className={"col-span-4"}>
                                <Input type={"password"} placeholder="password" {...field} />
                            </FormControl>
                            <div></div>
                            <FormMessage className={"col-span-4"} />
                        </FormItem>
                    )}
                />
                {form.formState.errors.root?.error && (
                    <p className={"py-2 text-sm font-semibold text-destructive"}>
                        {form.formState.errors.root?.error.message}
                    </p>
                )}
                <Button type={"submit"} size={"sm"} className={"w-full"}>
                    Log in
                </Button>
            </form>
        </Form>
    );
}

const emailSignupFormSchema = z.object({
    name: z.string().min(2, { message: "Please enter your name." }).max(50, { message: "This name is too long." }),
    email: z
        .string()
        .min(2, { message: "Please enter your email address." })
        .max(100, { message: "This email address is too long." }),
    password: z
        .string()
        .min(6, { message: "Please enter a password (at least 6 characters)" })
        .max(50, { message: "This password is too long." }),
});

type EmailSignupFormType = z.infer<typeof emailSignupFormSchema>;

function EmailSignupForm(props: { onSignup: (credentials: EmailSignupFormType) => Promise<void> }) {
    const firebaseErrorMessageMap: Record<string, { message: string; field?: keyof EmailSignupFormType }> = {
        "auth/email-already-in-use": {
            message: "This email address is already used by another account.",
            field: "email",
        },
    };
    const form = useForm<EmailSignupFormType>({
        resolver: zodResolver(emailSignupFormSchema),
        defaultValues: {
            name: "",
            email: "",
            password: "",
        },
    });
    const onSubmit = (credentials: EmailSignupFormType) => {
        props.onSignup(credentials).catch((err) => {
            if (err instanceof FirebaseError) {
                const mapped = firebaseErrorMessageMap[err.code];
                if (mapped) {
                    return form.setError(mapped.field ?? "root.error", {
                        message: mapped.message,
                    });
                }
            }
            return form.setError("root.error", {
                message: "Something went wrong :( Please try again later or reach out to us at support@tekkr.io",
            });
        });
    };
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={"space-y-2"}>
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className={"pe-2"}>Name</FormLabel>
                            <FormControl className={"col-span-4"}>
                                <Input autoComplete={"off"} placeholder="John Doe" {...field} />
                            </FormControl>
                            <div></div>
                            <FormMessage className={"col-span-4"} />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className={"pe-2"}>Email</FormLabel>
                            <FormControl className={"col-span-4"}>
                                <Input type={"email"} autoComplete={"off"} placeholder="john@company.com" {...field} />
                            </FormControl>
                            <div></div>
                            <FormMessage className={"col-span-4"} />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className={"pe-2"}>Password</FormLabel>
                            <FormControl className={"col-span-4"}>
                                <Input type={"password"} autoComplete={"off"} placeholder="password" {...field} />
                            </FormControl>
                            <div></div>
                            <FormMessage className={"col-span-4"} />
                        </FormItem>
                    )}
                />
                {form.formState.errors.root?.error && (
                    <p className={"py-2 text-sm font-semibold text-destructive"}>
                        {form.formState.errors.root?.error.message}
                    </p>
                )}
                <Button type={"submit"} size={"sm"} className={"w-full"}>
                    Sign up
                </Button>
            </form>
        </Form>
    );
}

enum State {
    "login",
    "create_account",
}

export function GoogleLogo(props: React.HTMLProps<SVGSVGElement>) {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            style={{ display: "block" }}
            {...props}
        >
            <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            ></path>
            <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            ></path>
            <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            ></path>
            <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            ></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
        </svg>
    );
}

export function MicrosoftLogo(props: React.HTMLProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            style={{ display: "block" }}
            {...props}
        >
            <title>MS-SymbolLockup</title>
            <rect x="1" y="1" width="9" height="9" fill="#f25022" />
            <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
            <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
            <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
        </svg>
    );
}

function LoginPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const firebaseAuth = getFirebaseAuth();
    const authController = useAuthController();

    const [state, setState] = useState<State>(State.create_account);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const getDestination = () => {
        return location.state && "from" in location.state ? (location.state.from as string) : "/";
    };

    if (authController.auth) {
        return <Navigate to={getDestination()} />;
    }

    async function runLogin(doLogin: () => Promise<void>) {
        setIsFetching(true);
        try {
            await doLogin();
        } catch (e) {
            setIsFetching(false);
            throw e;
        }
        await firebaseAuth.authStateReady();
        await authController.refetch();
        navigate(getDestination());
    }

    async function googleLogin() {
        await runLogin(async () => {
            await signInWithPopup(firebaseAuth, new GoogleAuthProvider());
        });
    }

    async function microsoftLogin() {
        await runLogin(async () => {
            const provider = new OAuthProvider("microsoft.com");
            await signInWithPopup(firebaseAuth, provider);
        });
    }

    async function emailLogin(credentials: EmailLoginFormType) {
        await runLogin(async () => {
            await signInWithEmailAndPassword(firebaseAuth, credentials.email, credentials.password);
        });
    }

    async function emailSignup(credentials: EmailSignupFormType) {
        await runLogin(async () => {
            const res = await createUserWithEmailAndPassword(firebaseAuth, credentials.email, credentials.password);
            await updateProfile(res.user, {
                displayName: credentials.name,
            });
        });
    }

    return (
        <OnboardingPage>
            <h1 className={"text-center"}>{state === State.create_account ? "Create Account" : "Login to Tekkr"}</h1>
            <div className={"mt-4 flex w-full flex-col gap-3"}>
                {isFetching ? (
                    <div className={"flex h-32 w-full flex-col items-center justify-center gap-2"}>
                        <Spinner />
                        <p className={"text-muted-foreground"}>Signing you in...</p>
                    </div>
                ) : null}

                {state === State.login ? (
                    <div className={cn("mt-4 flex w-full flex-col gap-3", isFetching ? "hidden" : undefined)}>
                        <EmailLoginForm onLogin={(credentials) => emailLogin(credentials)} />
                        <div className={"flex flex-row items-center gap-1"}>
                            <p>Don't have an account?</p>
                            <Button
                                onClick={() => setState(State.create_account)}
                                className={"text-primary hover:text-primary"}
                                variant={"ghost"}
                                size={"sm"}
                            >
                                Create Account
                            </Button>
                        </div>
                    </div>
                ) : null}
                {state === State.create_account ? (
                    <div className={cn("mt-4 flex w-full flex-col gap-3", isFetching ? "hidden" : undefined)}>
                        <EmailSignupForm onSignup={(credentials) => emailSignup(credentials)} />
                        <div className={"flex flex-row items-center gap-1"}>
                            <p>Already have an account?</p>
                            <Button
                                onClick={() => setState(State.login)}
                                className={"text-primary hover:text-primary"}
                                variant={"ghost"}
                                size={"sm"}
                            >
                                Sign In
                            </Button>
                        </div>
                    </div>
                ) : null}

                {!isFetching ? (
                    <>
                        <div className={"mb-2 flex flex-row items-center gap-4"}>
                            <hr className={"flex-grow"} />
                            <div className={"text-xs font-semibold text-muted-foreground"}>or</div>
                            <hr className={"flex-grow"} />
                        </div>
                        <Button onClick={() => googleLogin()} variant={"secondary"} className={"w-full"} size={"sm"}>
                            <GoogleLogo className={"me-3 h-5 w-5"} /> Sign in with Google
                        </Button>
                        <Button onClick={() => microsoftLogin()} variant={"secondary"} className={"w-full"} size={"sm"}>
                            <MicrosoftLogo className={"me-3 h-5 w-5"} /> Sign in with Microsoft
                        </Button>
                        <div className={"mt-4 px-8 text-center text-sm text-muted-foreground"}>
                            By clicking continue, you agree to our{" "}
                            <a
                                className={"hover:underline"}
                                href={"https://www.tekkr.io/terms-of-use"}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                Terms of Service
                            </a>{" "}
                            and{" "}
                            <a
                                className={"hover:underline"}
                                href={"https://www.tekkr.io/privacy-policy"}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                Privacy Policy
                            </a>
                            .
                        </div>
                        <hr className={"mb-4 mt-10"} />
                        <CustomerLogos />
                    </>
                ) : null}
            </div>
        </OnboardingPage>
    );
}

export default LoginPage;
