import { Tooltip, TooltipContent } from "../../../../components/ui/tooltip";
import { AvatarForUser } from "../../../../components/shared/avatar-for-user";
import { DateTime } from "luxon";
import React from "react";
import { useResolvedAttachmentReference } from "../../controller/hooks/attachments";
import { useOrgUser } from "../../../../auth/org-provider";
import { AttachmentTypeView } from "./attachment-type";

interface Props {
    attachmentId: string;
}

export function AttachmentPreviewTooltip(props: React.PropsWithChildren<Props>) {
    const meta = useResolvedAttachmentReference(props.attachmentId);
    const creator = useOrgUser(meta.createdBy);

    return (
        <Tooltip>
            {props.children}
            <TooltipContent className={"px-3 py-3"}>
                <div className={"flex w-64 flex-col gap-2"}>
                    {meta.imageUrl && (
                        <img
                            alt={"Thumbnail"}
                            className={"max-h-64 w-64 rounded-sm object-cover object-top"}
                            referrerPolicy="no-referrer"
                            src={meta.imageUrl}
                        />
                    )}
                    <div className={"line-clamp-3 font-semibold"}>{meta.title}</div>
                    <AttachmentTypeView type={meta.type} />
                    {meta.description && <div className={"font-medium"}>{meta.description}</div>}
                    <hr className={"mt-2"} />
                    <div className={"items-center text-sm font-medium"}>
                        Created by
                        <div
                            className={
                                "mx-1 inline-flex flex-row items-center gap-1 rounded-sm border px-1 py-0.5 align-middle"
                            }
                        >
                            <AvatarForUser user={creator} className={"h-4 w-4"} /> {creator.name}
                        </div>
                        on{" "}
                        {DateTime.fromISO(meta.createdAt).toLocaleString({
                            month: "long",
                            day: "numeric",
                        })}
                    </div>
                </div>
            </TooltipContent>
        </Tooltip>
    );
}
