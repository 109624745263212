"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookStageTitles = void 0;
exports.playbookStageTitles = {
    upcoming: {
        alignment: "Align",
        communication: "Communicate",
        edit: "Edit",
        implementation: "Implement",
        targets: "Set Targets",
        tracking: "Track",
    },
    ongoing: {
        alignment: "Aligning",
        communication: "Communicating",
        edit: "Editing",
        implementation: "Implementing",
        targets: "Setting Targets",
        tracking: "Tracking",
    },
    past: {
        alignment: "Aligned",
        communication: "Communicated",
        edit: "Edited",
        implementation: "Implemented",
        targets: "Targets Set",
        tracking: "Tracked",
    },
};
