import React from "react";
import { StagePageContainer } from "../stage-container";
import { EditScope, useScopedEdit } from "../../../../components/hooks/use-edit";
import { usePlaybookAlignmentEdit } from "../../controller/hooks/alignment-edit";
import {
    PlaybookAlignmentEdit,
    PlaybookAlignmentRoastingBlueprint,
} from "tekkr-common/dist/model/playbook/stages/alignment";
import { usePlaybook } from "../../controller/hooks/playbook";
import { SegmentContainer } from "../../../../components/shared/playbook-view/components/segment";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../components/ui/popover";
import { Button } from "../../../../components/ui/button";
import { cn } from "../../../../lib/utils";
import { CalendarIcon, Check, Hourglass, Timer } from "lucide-react";
import { Calendar } from "../../../../components/ui/calendar";
import { DateTime } from "luxon";
import { TekkrMarkdown } from "../../../../components/shared/markdown/tekkr-markdown";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import EditableTextSegmentContentView from "../../../../components/shared/playbook-view/components/content/types/editable-text";
import { prompts } from "../../../../service/prompts";
import { usePlaybookPageNavigation } from "../../playbook-page";
import { useBlueprint } from "../../controller/hooks/blueprint";

const pageHeaderCopy = {
    header: "Roasting",
    explainer:
        "Time to share the playbook with the world! " +
        "In this step you will invite a broader audience to identify gaps and suggest potential improvements. " +
        "This is also a great way to start building organizational awareness about your initiative and getting people on board.",
};

function RoastingInProgressOverlayContent(props: { deadline: Date; endRoasting: () => void }) {
    const deadlinePassed = DateTime.now() > DateTime.fromJSDate(props.deadline).startOf("day");
    return (
        <div className={"flex flex-col items-center gap-2"}>
            {!deadlinePassed && <Hourglass className={"hourglass-animation"} />}
            {deadlinePassed && (
                <div className={"flex flex-row items-center gap-2 text-confirmation"}>
                    <Check />
                    <p className={"text-sm font-semibold"}>deadline passed</p>
                </div>
            )}
            <h3>Wait for roasting to complete</h3>
            <p className={"text-muted-foreground"}>
                Roasting ends on:{" "}
                {DateTime.fromJSDate(props.deadline).toLocaleString({
                    weekday: "long",
                    month: "short",
                    day: "numeric",
                })}
            </p>
            <p className={"my-4 text-center"}>
                Give everyone in the company some time to review your playbook. This helps raise concerns that you and
                your stakeholders might have missed.
            </p>
            <Button variant={!deadlinePassed ? "outline" : undefined} onClick={props.endRoasting}>
                {!deadlinePassed ? "End Roasting Early" : "End Roasting"}
            </Button>
        </div>
    );
}

function PageContent(props: { edit: PlaybookAlignmentEdit; roasting: PlaybookAlignmentRoastingBlueprint }) {
    if (!props.edit.roastings[props.roasting.id]) {
        props.edit.roastings[props.roasting.id] = {
            asyncMessageEdit: {
                textMarkdown: "",
            },
        };
    }
    const edit = props.edit.roastings[props.roasting.id]!;

    const { state, updateEdit } = useScopedEdit(edit, (e) => ({
        deadline: e.deadline
            ? new Date(e.deadline)
            : DateTime.now().plus({ day: props.roasting.defaultDeadlineDays }).toJSDate(),
        done: e.done ?? false,
        roastingStarted: e.started ?? false,
    }));
    const setDeadline = (d: Date | undefined) => {
        updateEdit((e) => {
            e.deadline = d?.toISOString();
        });
    };
    const setDone = (done: boolean) => {
        if (state.done === done) {
            return;
        }
        updateEdit((e) => {
            e.done = done;
        });
    };
    const setStarted = () => {
        updateEdit((e) => {
            e.started = true;
        });
    };

    const { playbook } = usePlaybook();
    const generateMessage = async () => {
        const deadline = DateTime.fromJSDate(state.deadline).toLocaleString({
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
        });
        return await prompts.alignRoastingMessage({
            playbookId: playbook.id,
            dynamicVars: {
                deadline,
            },
        });
    };

    const playbookNavigation = usePlaybookPageNavigation();
    const endRoasting = () => {
        setDone(true);
        playbookNavigation?.goToNext();
    };

    const onNext = () => {
        setStarted();
        return false; // block default navigation
    };

    const roastingInProgress = state.roastingStarted && !state.done;
    const overlayDialog = roastingInProgress ? (
        <RoastingInProgressOverlayContent deadline={state.deadline} endRoasting={endRoasting} />
    ) : undefined;

    return (
        <div className={"relative"}>
            <StagePageContainer
                className={cn("transition-all duration-500")}
                onNext={onNext}
                overlayDialog={overlayDialog}
                {...pageHeaderCopy}
            >
                <SegmentContainer
                    id={"roasting"}
                    name={"Feedback Collection"}
                    title={"Ask for Playbook Roasting"}
                    isEditing={true}
                    hidden={false}
                    done={state.done}
                    expanded={true}
                    setDone={setDone}
                    options={{ hideControls: true }}
                >
                    <div className={"pb-4"}>
                        <h3>Set Deadline</h3>
                        <p className={"py-2"}>
                            We suggest giving everyone a final opportunity to give feedback to the final playbook
                            version, before implementation starts. We encourage you to make thoughtful adjustments
                            directly in your playbook.
                        </p>
                        <div className={"flex flex-row items-center gap-6"}>
                            <p className={"shrink-0"}>Roasting ends on:</p>
                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-[220px] shrink-0 pl-3 text-left font-normal",
                                            !state.deadline && "text-muted-foreground"
                                        )}
                                    >
                                        {state.deadline ? (
                                            DateTime.fromJSDate(state.deadline!).toLocaleString({
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            })
                                        ) : (
                                            <span>Pick a date</span>
                                        )}
                                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent className="w-auto p-0" align="start">
                                    <Calendar
                                        mode="single"
                                        defaultMonth={state.deadline}
                                        selected={state.deadline}
                                        onSelect={(d) => setDeadline(d)}
                                        disabled={(date) => date < new Date()}
                                        initialFocus
                                    />
                                </PopoverContent>
                            </Popover>
                            <TekkrMarkdown
                                markdown={`::info[Tekkr recommends ${props.roasting.defaultDeadlineDays} days]`}
                            />
                        </div>
                    </div>
                    <hr className={"-mx-10"} />
                    <div className={"flex flex-col gap-4 pt-4"}>
                        <h3>Send Message</h3>
                        <EditableTextSegmentContentView
                            content={{
                                id: "text",
                                type: ContentType.editableText,
                            }}
                            copy={"message"}
                            edit={edit.asyncMessageEdit}
                            isEditing={true}
                            textGenerator={generateMessage}
                        />
                    </div>
                </SegmentContainer>
            </StagePageContainer>
        </div>
    );
}

export function AlignmentRoastingPage(props: { roastingId: string }) {
    const { blueprint } = useBlueprint();
    const { alignmentEdit, updateAlignment } = usePlaybookAlignmentEdit();

    if (!alignmentEdit.roastings) {
        alignmentEdit.roastings = {};
    }

    const roasting = blueprint.alignment!.roastings!.find((it) => it.id === props.roastingId)!;
    return (
        <EditScope onEditUpdated={updateAlignment}>
            <PageContent edit={alignmentEdit} roasting={roasting} />
        </EditScope>
    );
}
