"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.markdownContentEditSchema = exports.markdownContentBlueprintSchema = void 0;
const blueprint_1 = require("../../blueprint");
const zod_1 = require("zod");
exports.markdownContentBlueprintSchema = blueprint_1.contentBlueprintSchema
    .extend({
    type: zod_1.z.literal(blueprint_1.ContentType.markdown),
    markdown: zod_1.z.string(),
    id: zod_1.z.string().optional().describe("an id is required when using editable sections within the markdown."),
    tekkrGuidance: zod_1.z.boolean().optional(),
})
    .strict();
exports.markdownContentEditSchema = zod_1.z.strictObject({
    editableText: zod_1.z.record(zod_1.z.string(), zod_1.z.string()).optional(),
});
