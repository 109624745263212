"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editableTextContentBlueprintSchema = void 0;
const blueprint_1 = require("../../blueprint");
const zod_1 = require("zod");
exports.editableTextContentBlueprintSchema = blueprint_1.editableContentBlueprintSchema
    .extend({
    type: zod_1.z.literal(blueprint_1.ContentType.editableText),
    defaultText: zod_1.z.string().optional(),
})
    .strict();
