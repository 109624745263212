import { OnboardingPage } from "../common/layout";
import React, { useEffect } from "react";
import { useOrg, useOrgController } from "../../auth/org-provider";
import { z } from "zod";
import { CompanyStage, companyStageNames } from "tekkr-common/dist/model/playbook/enums/enums";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import Spinner from "../../components/ui/spinner";
import { ServerCrashIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/auth-provider";

const formSchema = z.object({
    techOrgSize: z.number().min(2),
    companySize: z.number().min(2),
    stage: z.nativeEnum(CompanyStage),
});
function OrgSetupForm(props: { onSaved: () => void }) {
    const { org, refetch: refetchOrg } = useOrgController();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            techOrgSize: org?.meta?.techOrgSize ?? (null as unknown as number),
            companySize: org?.meta?.companySize ?? (null as unknown as number),
            stage: org?.meta?.stage ?? (null as unknown as CompanyStage),
        },
    });

    const mutation = useMutation({
        mutationFn: async (values: z.infer<typeof formSchema>) => {
            await apiClient.updateOrgMeta({
                body: values,
            });
            await refetchOrg();
            props.onSaved();
        },
    });

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit((v) => mutation.mutate(v))} className="flex flex-col space-y-8">
                <FormField
                    disabled={mutation.isPending}
                    control={form.control}
                    name="techOrgSize"
                    render={({ field }) => (
                        <FormItem className={"flex flex-row items-start gap-2"}>
                            <div className={"flex flex-col gap-1"}>
                                <FormLabel className={"w-44 shrink-0 gap-1"}>How many in your tech org?</FormLabel>
                                <FormDescription>
                                    Include everyone who is (indirectly) reporting to the CTO or CPO.
                                </FormDescription>
                                <FormMessage>Please provide a number.</FormMessage>
                            </div>
                            <FormControl>
                                <Input
                                    placeholder="50"
                                    {...field}
                                    type={"number"}
                                    onChange={(value) => field.onChange(value.target.valueAsNumber)}
                                    className={"w-32"}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />
                <FormField
                    disabled={mutation.isPending}
                    control={form.control}
                    name="companySize"
                    render={({ field }) => (
                        <FormItem className={"flex flex-row items-start gap-2"}>
                            <div className={"flex flex-col gap-1"}>
                                <FormLabel className={"w-44 shrink-0 gap-1"}>How many in your company?</FormLabel>
                                <FormDescription>Include all employees of your company.</FormDescription>
                                <FormMessage>Please provide a number.</FormMessage>
                            </div>
                            <FormControl>
                                <Input
                                    placeholder="200"
                                    {...field}
                                    type={"number"}
                                    onChange={(value) => field.onChange(value.target.valueAsNumber)}
                                    className={"w-32"}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="stage"
                    render={({ field }) => (
                        <FormItem className={"flex flex-row items-start gap-2"}>
                            <div className={"flex flex-col gap-1"}>
                                <FormLabel className={"w-44 shrink-0 gap-1"}>What's your funding stage?</FormLabel>
                                <FormDescription>Based on your last fund-raising round.</FormDescription>
                                <FormMessage>Please select a value.</FormMessage>
                            </div>
                            <FormControl>
                                <Select
                                    disabled={mutation.isPending}
                                    value={field.value ?? undefined}
                                    onValueChange={(v) => field.onChange(v)}
                                >
                                    <SelectTrigger className="w-32 shrink-0">
                                        <SelectValue
                                            placeholder={<span className={"text-muted-foreground"}>Select Stage</span>}
                                        />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectLabel>Funding Stage</SelectLabel>
                                            {Object.values(CompanyStage).map((stage) => (
                                                <SelectItem key={stage} value={stage}>
                                                    {companyStageNames[stage]}
                                                </SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                        </FormItem>
                    )}
                />
                <Button type="submit" className={"self-stretch"} disabled={mutation.isPending}>
                    {mutation.isPending ? <Spinner /> : "Save"}
                </Button>
                {mutation.error && (
                    <div
                        className={
                            "flex flex-row items-center gap-3 rounded-md border border-destructive bg-destructive/5 px-3 py-2 text-sm font-medium text-destructive"
                        }
                    >
                        <ServerCrashIcon className={"h-5 w-5"} />
                        We couldn't save your settings, please try again later.
                    </div>
                )}
            </form>
        </Form>
    );
}

export function OrgSetupPage() {
    const org = useOrg();
    const auth = useAuth();

    useEffect(() => {
        document.title = "Set up organization - Tekkr";
    });
    const navigate = useNavigate();

    return (
        <OnboardingPage>
            <div className={"flex w-full flex-col gap-2"}>
                <h1 className={"w-full text-start"}>Welcome, {auth.user?.displayName?.trim().split(" ")[0]} 👋</h1>
                <h3>Tell us more about {org.name}!</h3>
                <p>Understanding who you are allows us to help you make the most out of Tekkr.</p>
                <br />
                <OrgSetupForm
                    onSaved={() => {
                        navigate("/", { replace: true });
                    }}
                />
            </div>
        </OnboardingPage>
    );
}
