"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.publicApiContract = void 0;
const core_1 = require("@ts-rest/core");
const zod_openapi_1 = require("@anatine/zod-openapi");
const zod_1 = require("zod");
const blueprint_1 = require("../playbook/blueprint");
const edit_1 = require("../playbook/edit");
const contributors_1 = require("../playbook/contributors");
const c = (0, core_1.initContract)();
(0, zod_openapi_1.extendZodWithOpenApi)(zod_1.z);
exports.publicApiContract = c.router({
    getSharedPlaybook: {
        method: "GET",
        path: "/shared/playbook/:key",
        responses: {
            200: zod_1.z.object({
                org: zod_1.z.object({
                    id: zod_1.z.string(),
                    logo: zod_1.z
                        .object({
                        urlBlack: zod_1.z.string(),
                        urlWhite: zod_1.z.string(),
                    })
                        .optional(),
                    users: zod_1.z.array(zod_1.z.strictObject({
                        id: zod_1.z.string(),
                        name: zod_1.z.string(),
                        title: zod_1.z.string().optional(),
                    })),
                }),
                blueprint: blueprint_1.playbookBlueprintSchema,
                playbook: zod_1.z.object({
                    edit: edit_1.playbookEditSchema,
                    blueprintId: zod_1.z.string(),
                    createdBy: zod_1.z.string(),
                    createdAt: zod_1.z.string(),
                    contributors: contributors_1.playbookContributorsSchema,
                    imageUrl: zod_1.z.string().optional(),
                }),
                peopleGroups: zod_1.z.record(zod_1.z.string(), zod_1.z.array(zod_1.z.string())).optional(),
            }),
        },
    },
    getSharedPlaybookPreviewHtml: {
        method: "GET",
        path: "/shared/playbook/:key/preview",
        responses: {
            200: c.otherResponse({
                contentType: "text/html",
                body: zod_1.z.string(),
            }),
        },
    },
    oauthCallback: {
        method: "GET",
        path: "/oauth-callback",
        query: zod_1.z.object({
            code: zod_1.z.string(),
            state: zod_1.z.string(),
        }),
        responses: {
            200: c.otherResponse({
                contentType: "text/html",
                body: zod_1.z.string(),
            }),
        },
    },
    onBotEmailReceivedWebhook: {
        method: "POST",
        path: "/on-bot-email-received",
        contentType: "multipart/form-data",
        query: zod_1.z.object({
            key: zod_1.z.string(),
        }),
        body: zod_1.z.any(),
        responses: {
            200: zod_1.z.object({
                ok: zod_1.z.boolean(),
            }),
        },
    },
}, {
    pathPrefix: "/public",
});
