import React, { createContext, useContext, useMemo } from "react";
import { apiClient } from "../service/tekkr-service";
import { Navigate } from "react-router-dom";
import { useAuth, useAuthOptional } from "./auth-provider";
import { useQuery } from "@tanstack/react-query";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import _ from "lodash";
import { Org, OrgUser } from "../service/tekkr-service-types";
import { FullScreenLoader } from "../components/shared/full-screen-loader";
import * as Sentry from "@sentry/react";

export const OrgSelector = {
    getSelectedOrgId: (): string | null => {
        return window.localStorage.getItem("tekkr-selected-org");
    },
    setSelectedOrg: (orgId: string) => {
        window.localStorage.setItem("tekkr-selected-org", orgId);
        Sentry.setExtra("tekkr-org", orgId);
    },
};

export const UseOrgContext = createContext<OrgController>({} as OrgController);
export const useOrg = (): Org => {
    const controller = useContext(UseOrgContext);
    if (!controller?.org) {
        throw new Error("No org available.");
    }
    return controller.org;
};

export function useCurrentOrgUser() {
    const optional = useCurrentOrgUserOptional();
    if (!optional) {
        throw new Error("no current org user context available (most likely org or auth context are missing)");
    }
    return optional;
}

export function useCurrentOrgUserOptional() {
    const currentOrg = useOrgOptional();
    const auth = useAuthOptional();
    if (!currentOrg || !auth) {
        return null;
    }
    const org = auth.account.orgs.find((o) => o.id === currentOrg.id);
    if (!org) {
        return null;
    }
    const user = currentOrg.users.find((u) => u.id === org.userId);
    if (!user) {
        return null;
    }
    return user;
}

export function useOrgOptional(): Org | null {
    const controller = useContext(UseOrgContext);
    return controller.org;
}

export function useOrgUser(id: string): OrgUser {
    const org = useOrg();
    const user = useMemo(() => org.users.find((u) => u.id === id), [id]);
    if (!user) {
        throw new Error(`user with id ${id} does not exist in org.`);
    }
    return user;
}

export function useOrgUserMap(): Record<string, OrgUser> {
    const org = useOrg();
    const map: Record<string, OrgUser> = {};
    org.users.forEach((user: OrgUser) => {
        map[user.id] = user;
    });
    return map;
}

interface OrgController {
    refetch: () => Promise<unknown>;
    org: Org | null;
}
export const useOrgController = (): OrgController => {
    return useContext(UseOrgContext);
};

const orgQuery = () => ({
    queryKey: ["org"],
    queryFn: async () => {
        const response = await apiClient.getOrg();
        const body = response.body as ClientInferResponseBody<typeof apiContract.getOrg, 200>;
        body.users = _.sortBy(body.users, "name");
        return body;
    },
});

export const MockOrgProvider = ({ children, mockOrg }: React.PropsWithChildren & { mockOrg: Org }) => {
    const controller = {
        org: mockOrg,
        refetch: async () => {},
    };
    return <UseOrgContext.Provider value={controller}>{children}</UseOrgContext.Provider>;
};

export const OrgProvider = ({ children }: React.PropsWithChildren) => {
    const auth = useAuth();

    const selectedOrgId = OrgSelector.getSelectedOrgId();
    // set org id to any org if none is set or selection doesn't exist in current account
    const selectedOrgMissing = !auth.account.orgs.find((o) => o.id === selectedOrgId);
    if ((selectedOrgMissing || !selectedOrgId) && auth.account.orgs.length > 0) {
        OrgSelector.setSelectedOrg(auth.account.orgs[0]?.id);
    }
    const { isPending, data, refetch } = useQuery(orgQuery());

    return (
        <UseOrgContext.Provider
            value={{
                org: data ?? null,
                refetch,
            }}
        >
            {!isPending ? children : <FullScreenLoader />}
        </UseOrgContext.Provider>
    );
};
export const RequireOrg = (props: React.PropsWithChildren) => {
    const auth = useAuth();
    const controller = useContext(UseOrgContext);
    if (controller.org) {
        // user has an organization
        return <>{props.children}</>;
    }
    if (auth.account.access.createOrg) {
        return <Navigate to={{ pathname: "/create-organization" }} />;
    } else {
        return <Navigate to={{ pathname: "/no-organization" }} />;
    }
};
