import React, { createContext, useContext } from "react";
import UnlockAccessCtaDialog from "./unlock-access-cta-dialog";
import { TekkrEmailBotDisclaimerDialog } from "./tekkr-email-bot-disclaimer";

interface TekkrEmailBotDisclaimerDialogOptions {
    trackerAddress: string;
    callback: () => void;
}

interface Context {
    showUnlockAllPlaybooksCta(): void;
    showTekkrEmailBotDisclaimer(options: TekkrEmailBotDisclaimerDialogOptions): void;
}
const GlobalDialogsContext = createContext<Context | undefined>(undefined);

export function useGlobalDialogs() {
    return useContext(GlobalDialogsContext);
}

export const GlobalDialogsProvider = (props: React.PropsWithChildren) => {
    const [unlockCtaOpen, setUnlockCtaOpen] = React.useState(false);
    const [emailBotDisclaimerCallback, setEmailBotDisclaimerCallback] =
        React.useState<TekkrEmailBotDisclaimerDialogOptions | null>(null);
    const context: Context = {
        showUnlockAllPlaybooksCta: () => setUnlockCtaOpen(true),
        showTekkrEmailBotDisclaimer: (options) => setEmailBotDisclaimerCallback(options),
    };
    return (
        <GlobalDialogsContext.Provider value={context}>
            <UnlockAccessCtaDialog open={unlockCtaOpen} onOpenChange={setUnlockCtaOpen} />
            {emailBotDisclaimerCallback && (
                <TekkrEmailBotDisclaimerDialog
                    trackerAddress={emailBotDisclaimerCallback.trackerAddress}
                    onDismiss={() => {
                        emailBotDisclaimerCallback!.callback();
                        setEmailBotDisclaimerCallback(null);
                    }}
                />
            )}
            {props.children}
        </GlobalDialogsContext.Provider>
    );
};
