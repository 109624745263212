import { usePlaybook } from "./playbook";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { useEffect } from "react";
import { useGlobalInterval } from "../../../../components/hooks/global-interval";

export type ScheduledPlaybookMeeting = ClientInferResponseBody<typeof apiContract.getScheduledPlaybookMeetings, 200>["data"][0];

interface Options {
    scopeFilter?: string[];
}

export function useScheduledPlaybookMeetings(options?: Options): { meetings: ScheduledPlaybookMeeting[] | null, isPending: boolean, isError: boolean, refetch: () => void } {
    const { playbook } = usePlaybook();
    const query = useQuery({
        queryKey: [playbook.id, "scheduled-meetings"],
        queryFn: async () => {
            const res = await apiClient.getScheduledPlaybookMeetings({
                params: {
                    playbookId: playbook.id,
                },
            });
            const body = res.body as ClientInferResponseBody<typeof apiContract.getScheduledPlaybookMeetings, 200>;
            return body.data;
        },
        refetchInterval: 3_000,
    });

    return {
        isError: query.isError,
        isPending: query.isPending,
        refetch: query.refetch,
        meetings: query.data ? filteredMeetings(query.data, options) : null,
    }
}

function filteredMeetings (meetings: ScheduledPlaybookMeeting[], options?: Options) {
    if (!options?.scopeFilter) {
        return meetings;
    }
    return meetings.filter((m) => {
        return JSON.stringify(m.scope.slice(0, options.scopeFilter!.length)) === JSON.stringify(options.scopeFilter)
    })
}