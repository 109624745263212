import { BlueprintReferenceContentBlueprint } from "tekkr-common/dist/model/playbook/segment/content/types/blueprint-reference/blueprint";
import { ArrowRight, Unlock } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../../../../../ui/button";
import { SegmentContentViewProps } from "../segment-content";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { useOrg } from "../../../../../../auth/org-provider";
import Spinner from "../../../../../ui/spinner";
import { useGlobalDialogs } from "../../../../../../modals/global-dialogs";
import { cn } from "../../../../../../lib/utils";

export default function BlueprintReferenceSegmentContentView(
    props: SegmentContentViewProps<
        BlueprintReferenceContentBlueprint,
        {
            className?: string;
        }
    >
) {
    const org = useOrg();
    const { isPending, data } = useQuery({
        queryKey: ["blueprint-meta", org.id, props.content.blueprintId],
        queryFn: async () => {
            const response = await (
                await apiClient
            ).getBlueprintMetaById({
                params: { id: props.content.blueprintId },
            });
            return response.body as ClientInferResponseBody<typeof apiContract.getBlueprintMetaById, 200>;
        },
    });
    const globalDialogs = useGlobalDialogs();
    return (
        <div
            className={cn(
                "mt-6 flex flex-row items-center justify-between gap-3 rounded-md border px-4 py-3",
                props.className
            )}
        >
            {!!data && (
                <>
                    <div className={"flex flex-row items-center justify-start gap-3 text-sm"}>
                        <img className={"aspect-3/2 h-24 rounded-md object-cover"} src={data.imageUrl} />
                        <div>
                            <div className={"mb-1 text-xs font-semibold text-muted-foreground"}>Playbook</div>
                            <div>
                                <b>{data.title}</b>
                            </div>
                            <div className={"line-clamp-2"}>{data.summary}</div>
                        </div>
                    </div>
                    {data.access === "access" && (
                        <Button asChild>
                            <Link className={"shrink-0"} to={`/blueprint/${props.content.blueprintId}`}>
                                <div className={"flex flex-row items-center gap-2"}>
                                    Learn more
                                    <ArrowRight className={"h-5 w-5"} />
                                </div>
                            </Link>
                        </Button>
                    )}
                    {data.access === "restricted" && (
                        <Button
                            className={"transition-all duration-500 hover:drop-shadow-glow-primary"}
                            onClick={() => globalDialogs?.showUnlockAllPlaybooksCta()}
                        >
                            <div className={"flex flex-row items-center gap-2"}>
                                <Unlock className={"h-5 w-5"} />
                                Get access
                            </div>
                        </Button>
                    )}
                </>
            )}

            {isPending && (
                <div className={"flex h-12 w-full flex-row items-center justify-center gap-2 text-muted-foreground"}>
                    <Spinner />
                    <p className={"text-sm font-semibold"}>loading playbook...</p>
                </div>
            )}
        </div>
    );
}
