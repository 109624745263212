"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlueprintWrapper = void 0;
const blueprint_1 = require("../model/playbook/segment/content/blueprint");
class BlueprintWrapper {
    blueprint;
    inlineEditables = undefined;
    constructor(blueprint) {
        this.blueprint = blueprint;
    }
    forEachContent(visitor) {
        this.forEachContentWhere({}, visitor);
    }
    forEachContentWhere(filter, visitor) {
        for (const segment of this.blueprint.segments) {
            if (filter.segmentId && segment.id !== filter.segmentId) {
                continue;
            }
            for (const content of segment.content) {
                if (filter.type && content.type !== filter.type) {
                    continue;
                }
                visitor({
                    segment,
                    content,
                });
            }
        }
    }
    getInlineEditables() {
        if (!this.inlineEditables) {
            const allEditables = {};
            this.forEachContentWhere({ type: blueprint_1.ContentType.markdown }, ({ segment, content }) => {
                content = content;
                const editables = content.markdown.matchAll(/:editable\[(.*)]{#([a-z0-9\-]+)}/gi);
                for (const editable of editables) {
                    const [, defaultValue, id] = editable;
                    allEditables[id] = {
                        location: {
                            segmentId: segment.id,
                            contentId: content.id,
                        },
                        defaultValue,
                    };
                }
            });
            this.inlineEditables = allEditables;
        }
        return this.inlineEditables;
    }
    getContentWithType(segmentId, contentId, contentType) {
        const s = this.blueprint.segments.find((s) => s.id === segmentId);
        if (!s) {
            throw new Error(`segment with id ${segmentId} not found`);
        }
        const c = s.content.find((c) => "id" in c && c.id && c.id === contentId);
        if (!c) {
            throw new Error(`content with id ${segmentId} not found`);
        }
        if (c.type !== contentType) {
            throw new Error(`content type is ${c.type} (expected ${contentType})`);
        }
        return c;
    }
}
exports.BlueprintWrapper = BlueprintWrapper;
