"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editableContentBlueprintSchema = exports.contentBlueprintSchema = exports.PlaybookStage = exports.ContentType = void 0;
const zod_1 = require("zod");
const schema_refinements_1 = require("../../../../utils/schema-refinements");
var ContentType;
(function (ContentType) {
    ContentType["markdown"] = "markdown";
    ContentType["list"] = "list";
    ContentType["peopleList"] = "people_list";
    ContentType["select"] = "select";
    ContentType["section"] = "section";
    ContentType["blueprintReference"] = "blueprint_reference";
    ContentType["externalLink"] = "external_link";
    ContentType["editableText"] = "editable_text";
    ContentType["categorization"] = "categorization";
    ContentType["toolTemplate"] = "tool_template";
    ContentType["principles"] = "principles";
    ContentType["space"] = "space";
})(ContentType || (exports.ContentType = ContentType = {}));
var PlaybookStage;
(function (PlaybookStage) {
    PlaybookStage["discover"] = "discover";
    PlaybookStage["edit"] = "edit";
    PlaybookStage["read"] = "read";
})(PlaybookStage || (exports.PlaybookStage = PlaybookStage = {}));
exports.contentBlueprintSchema = zod_1.z.object({
    type: zod_1.z.nativeEnum(ContentType),
    visibility: zod_1.z
        .union([
        zod_1.z.object({ hideIn: zod_1.z.array(zod_1.z.nativeEnum(PlaybookStage)) }),
        zod_1.z.object({ onlyIn: zod_1.z.nativeEnum(PlaybookStage) }),
    ])
        .optional(),
});
exports.editableContentBlueprintSchema = exports.contentBlueprintSchema.extend({
    id: zod_1.z.string().regex(schema_refinements_1.formats.kebabCaseRegex),
});
