import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useCurrentOrgUser, useOrg } from "../../../auth/org-provider";
import { apiClient } from "../../../service/tekkr-service";
import PlaybookCard from "../../../components/shared/playbook-card";
import { cn } from "../../../lib/utils";
import { DiscoveryPageSectionTitle } from "../discovery-page";
import { BookUserIcon } from "lucide-react";

export function MyPlaybooksList() {
    const org = useOrg();
    const user = useCurrentOrgUser();
    const { data: playbooks } = useQuery({
        queryKey: ["discovery", "my-playbooks", org.id],
        queryFn: async () => {
            const res = await apiClient.listPlaybooks();
            if (res.status !== 200) throw new Error("failed to fetch playbooks");
            return res.body.data.filter((pb) => {
                return !pb.contributors.driver || pb.contributors.driver === user.id;
            });
        },
    });

    if (!playbooks) {
        return <>loading..</>;
    }

    if (!playbooks.length) {
        return null;
    } else if (playbooks.length === 1) {
        // only one playbook
        return (
            <div>
                <DiscoveryPageSectionTitle icon={BookUserIcon}>Your Playbook</DiscoveryPageSectionTitle>
                <PlaybookCard layout={"horizontal"} data={playbooks[0]} state={playbooks[0].state}></PlaybookCard>
            </div>
        );
    } else if (playbooks.length <= 3) {
        // 2-3 playbooks
        return (
            <div>
                <DiscoveryPageSectionTitle icon={BookUserIcon}>Your Playbooks</DiscoveryPageSectionTitle>
                <div className={cn("grid gap-4", playbooks.length === 2 ? "grid-cols-2" : "grid-cols-3")}>
                    {playbooks &&
                        playbooks.map((playbook) => (
                            <PlaybookCard
                                layout={playbooks.length === 2 ? "horizontal" : "vertical"}
                                data={playbook}
                                state={playbook.state}
                                key={playbook.id}
                            />
                        ))}
                </div>
            </div>
        );
    } else {
        // more than 3
        return (
            <div>
                <DiscoveryPageSectionTitle icon={BookUserIcon}>Your Playbooks</DiscoveryPageSectionTitle>
                <div className={"relative"}>
                    <div className={"flex flex-row gap-4 overflow-x-scroll"}>
                        {playbooks &&
                            playbooks.map((playbook) => (
                                <PlaybookCard
                                    className={"w-64 shrink-0 grow-0"}
                                    layout={"vertical"}
                                    data={playbook}
                                    state={playbook.state}
                                    key={playbook.id}
                                />
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}
