import React from "react";
import { cn } from "../../lib/utils";
import { MoonStar, Sun } from "lucide-react";
import Footer from "../../components/shared/footer";
import { ReactComponent as TekkrScriptLogoDark } from "../../static/tekkr-logo-script-dark.svg";
import { ReactComponent as TekkrScriptLogoLight } from "../../static/tekkr-logo-script-light.svg";
import { useColorScheme } from "../../lib/color-scheme";

const widthClasses = {
    narrower: "max-w-4xl",
    default: "max-w-5xl",
};

export function ContentSpacer(
    props: React.PropsWithChildren & {
        contentWidth?: keyof typeof widthClasses;
    }
) {
    const widthClass = widthClasses[props.contentWidth ?? "default"];
    return (
        <div className={"max-w-4x flex flex-row justify-center px-12 py-9"}>
            <div className={cn("w-full", widthClass)}>
                <div className={"flex flex-col gap-4"}>{props.children}</div>
            </div>
        </div>
    );
}

export function OnboardingPage(props: React.PropsWithChildren) {
    const { isDark, setIsDark } = useColorScheme();
    function toggleDarkMode() {
        setIsDark(!isDark);
    }
    return (
        <div className={"flex h-screen flex-col items-center justify-center"}>
            <div className={"absolute left-0 right-0 top-0 flex flex-row items-center justify-between px-6 py-4"}>
                <div>
                    <TekkrScriptLogoDark className={"hidden dark:block"} />
                    <TekkrScriptLogoLight className={"dark:hidden"} />
                </div>
                {!isDark ? (
                    <MoonStar onClick={toggleDarkMode} className={"cursor-pointer"} />
                ) : (
                    <Sun onClick={toggleDarkMode} className={"cursor-pointer"} />
                )}
            </div>
            <div className={"flex max-w-lg flex-col items-center gap-3 rounded-lg border bg-popover px-16 py-10"}>
                {props.children}
            </div>
            <div className={"absolute bottom-0 left-0 right-0"}>
                <Footer></Footer>
            </div>
        </div>
    );
}
