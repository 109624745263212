import React, { forwardRef, useRef, useState } from "react";
import "react-chat-widget/lib/styles.css";
import "./playbook-chat.css";
import { BotMessageSquare, MaximizeIcon, MinimizeIcon, Send, X } from "lucide-react";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { Form, FormControl, FormField, FormItem } from "../../../components/ui/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "../../../lib/utils";
import { useMutation } from "@tanstack/react-query";
import Spinner from "../../../components/ui/spinner";
import { v4 as uuid } from "uuid";
import { TekkrMarkdown } from "../../../components/shared/markdown/tekkr-markdown";
import { usePlaybook } from "../controller/hooks/playbook";
import { apiClient } from "../../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";

const readyMadePrompts: { title: string; prompt: string }[] = [
    {
        title: "Write a status summary for my stakeholders",
        prompt: "Please write a status summary of this playbook for my stakeholders that I can share with them via chat (such as Slack or Teams). Respond with only the message and make it ready to send. Don't add any placeholders.",
    },
    {
        title: "Summarize this playbook",
        prompt: "Please summarize this playbook in 3-5 sentences.",
    },
    {
        title: "I need to pitch this playbook to my CEO",
        prompt: "Can you write a pitch for me to explain to the CEO of my company why this playbook is important to do? Please reply with a ready-to-send message.",
    },
];

const formSchema = z.object({
    message: z.string().nonempty(),
});

type FormValues = z.infer<typeof formSchema>;

function ChatMessageInputForm(props: { className?: string; messageSender: (message: string) => Promise<void> }) {
    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            message: "",
        },
    });

    const { isPending, mutate: sendMessage } = useMutation({
        mutationFn: async (message: string) => {
            await new Promise((r) => setTimeout(r, 200));
            await props.messageSender(message);
        },
        onSuccess: () => {
            form.reset();
        },
    });

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit((v) => sendMessage(v.message))} className={props.className}>
                <FormField
                    control={form.control}
                    name="message"
                    render={({ field }) => (
                        <FormItem className={"grow"}>
                            <FormControl>
                                <Input disabled={isPending} placeholder={"New Message"} {...field} />
                            </FormControl>
                        </FormItem>
                    )}
                />
                <Button disabled={isPending} variant={"ghost"} type={"submit"}>
                    {isPending ? <Spinner /> : <Send className={"2-5 h-5"} />}
                </Button>
            </form>
        </Form>
    );
}

interface Message {
    from: "me" | "them";
    id: string;
    body: string;
}

function MessageItem(props: { body: string; type: "sent" | "received" }) {
    return (
        <div className={cn("flex flex-row", props.type === "sent" ? "justify-end" : "justify-start")}>
            <div
                className={cn(
                    "flex w-5/6 flex-col gap-1 rounded-md px-2 py-1",
                    props.type === "sent" ? "bg-primary/10" : "bg-foreground/5"
                )}
            >
                <div className={"text-xs font-medium opacity-65"}>
                    {props.type === "sent" ? "You" : "Tekkr Assistant"}
                </div>
                <TekkrMarkdown markdown={props.body} />
            </div>
        </div>
    );
}

const MessageHistory = forwardRef<
    HTMLDivElement,
    {
        messages: Message[];
    }
>((props, ref) => (
    <div ref={ref} className={"flex grow flex-col gap-3 overflow-y-scroll p-4 text-sm"}>
        {props.messages.map((msg) => (
            <MessageItem key={msg.id} body={msg.body} type={msg.from === "me" ? "sent" : "received"} />
        ))}
    </div>
));
MessageHistory.displayName = "MessageHistory";

function PromptSuggestions(props: { messageSender: (message: string) => Promise<void> }) {
    return (
        <div className={"flex grow flex-col items-end justify-end gap-3 overflow-y-scroll p-4 text-sm"}>
            {readyMadePrompts.map((suggestion) => (
                <div
                    onClick={() => props.messageSender(suggestion.prompt)}
                    key={suggestion.title}
                    className={
                        "cursor-pointer rounded-full px-2 py-1 text-sm font-medium text-secondary-foreground outline-dotted outline-input transition-all hover:bg-input"
                    }
                >
                    {suggestion.title}
                </div>
            ))}
        </div>
    );
}

type Scope = { playbook: string };

function useLlmChat(config?: { onMessagesUpdated?: () => void }): {
    messages: Message[];
    sendMessage: (message: string) => Promise<void>;
    startChat: (scope: Scope) => Promise<void>;
    active: boolean;
    launchingChat: boolean;
} {
    const [messages, _setMessages] = useState<Message[]>([]);
    function setMessages(messages: Message[]) {
        _setMessages(messages);
        config?.onMessagesUpdated?.();
    }
    const [chatId, setChatId] = useState<string | null>(null);

    const launchChatMutation = useMutation({
        mutationFn: async (scope: Scope) => {
            await new Promise((r) => setTimeout(r, 500));
            const createChatRes = (
                await apiClient.createChat({
                    body: {
                        scope,
                    },
                })
            ).body as ClientInferResponseBody<typeof apiContract.createChat, 200>;
            setChatId(createChatRes.chatId);
        },
    });

    return {
        active: !!chatId,
        messages,
        sendMessage: async function (message: string) {
            if (!chatId) {
                throw new Error("cannot send message to inactive chat");
            }
            const userMessage: Message = {
                id: uuid(),
                body: message,
                from: "me",
            };
            setMessages([...messages, userMessage]);
            const res = await apiClient.sendChatMessage({
                params: {
                    chatId,
                },
                body: {
                    message,
                },
            });
            const resBody = res.body as ClientInferResponseBody<typeof apiContract.sendChatMessage, 200>;
            const replyMessage: Message = {
                id: uuid(),
                body: resBody.reply,
                from: "them",
            };
            setMessages([...messages, userMessage, replyMessage]);
        },
        startChat: (scope) => launchChatMutation.mutateAsync(scope),
        launchingChat: launchChatMutation.isPending,
    };
}

export function PlaybookChat() {
    const [open, setOpen] = useState(false);
    const [maximized, setMaximized] = useState(false);
    const { playbook } = usePlaybook();
    const messageHistoryRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        setTimeout(() => {
            if (messageHistoryRef.current) {
                messageHistoryRef.current.scrollTop = messageHistoryRef.current.scrollHeight;
            }
        }, 100);
    };
    const {
        messages,
        sendMessage,
        startChat,
        active: chatActive,
        launchingChat,
    } = useLlmChat({
        onMessagesUpdated: scrollToBottom,
    });

    const onFabClick = () => {
        if (!chatActive) {
            void startChat({ playbook: playbook.id });
            setOpen(true);
            return;
        }
        if (open) {
            setMaximized(false);
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    return (
        <>
            <div
                onClick={!launchingChat ? onFabClick : undefined}
                className={
                    "fixed bottom-8 right-8 z-50 flex h-12 w-12 cursor-pointer flex-col items-center justify-center rounded-full bg-primary text-primary-foreground transition-all hover:drop-shadow-glow-primary"
                }
            >
                {launchingChat && <Spinner />}
                {!launchingChat && !open && <BotMessageSquare className={"animate-in zoom-in-75"} />}
                {!launchingChat && open && <X className={"animate-in zoom-in-75"} />}
            </div>
            {open && chatActive && (
                <div
                    className={cn(
                        "fixed bottom-24 right-8 z-50 flex max-w-full flex-col overflow-hidden rounded-xl border-2 bg-background shadow-2xl drop-shadow animate-in fade-in-50 zoom-in-90 slide-in-from-bottom-8 slide-in-from-right-4",
                        maximized ? "top-8 w-2/3" : "h-2/3 w-96"
                    )}
                >
                    <div
                        className={
                            "flex shrink-0 grow-0 flex-row items-center bg-primary p-4 font-medium text-primary-foreground"
                        }
                    >
                        <div className={"grow"}>
                            Playbook Chat
                            <br />
                            <small className={"opacity-85"}>Ask anything about your playbook.</small>
                        </div>
                        <div
                            className={"cursor-pointer px-4 transition-all hover:scale-125"}
                            onClick={() => setMaximized(!maximized)}
                        >
                            {maximized ? (
                                <MinimizeIcon className={"h-5 w-5"} />
                            ) : (
                                <MaximizeIcon className={"h-5 w-5"} />
                            )}
                        </div>
                    </div>
                    {!!messages.length && <MessageHistory ref={messageHistoryRef} messages={messages} />}
                    {!messages.length && <PromptSuggestions messageSender={sendMessage} />}
                    <ChatMessageInputForm
                        messageSender={sendMessage}
                        className={"flex shrink-0 grow-0 flex-row gap-2 p-2"}
                    />
                </div>
            )}
        </>
    );
}
