import React from "react";
import { SegmentContentViewProps } from "../segment-content";
import { useCurrentOrgUser } from "../../../../../../auth/org-provider";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../../../../../service/tekkr-service";
import { usePlaybookOptional } from "../../../../../../pages/playbook/controller/hooks/playbook";
import { ToolTemplateContentBlueprint } from "tekkr-common/dist/model/playbook/segment/content/types/tool-template/blueprint";
import { ToolTemplateContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/tool-template/edit";
import { Button } from "../../../../../ui/button";
import { useScopedEdit } from "../../../../../hooks/use-edit";
import { ReactComponent as GoogleSheetsLogo } from "../../../../../../static/icons/google-sheets.svg";
import { Paperclip, Wrench } from "lucide-react";
import Spinner from "../../../../../ui/spinner";
import { useOAuthServiceAction } from "../../../../connect-oauth-account-button";

function openReference(reference: ToolTemplateContentEdit["reference"]) {
    if (reference.type === "google-sheet") {
        window.open(`https://docs.google.com/spreadsheets/d/${reference.fileId}/edit`, "_blank");
    } else {
        throw new Error(`reference type ${reference.type} not supported`);
    }
}

function ToolReferenceView({
    reference,
    blueprint,
}: {
    reference: ToolTemplateContentEdit["reference"];
    blueprint: ToolTemplateContentBlueprint;
}) {
    if (reference.type === "google-sheet") {
        const url = `https://docs.google.com/spreadsheets/d/${reference.fileId}/edit`;
        return (
            <a
                href={url}
                target={"_blank"}
                rel={"noreferrer"}
                className={
                    "inline-flex min-w-0 cursor-pointer flex-row items-center gap-2 rounded-md bg-accent px-4 py-2 transition-all hover:bg-secondary"
                }
            >
                <Paperclip className={"h-5 w-5 shrink-0 opacity-65"} />
                <GoogleSheetsLogo className={"h-6 w-6 shrink-0"} />
                <div className={"flex min-w-0 shrink grow flex-col"}>
                    <p className={"line-clamp-1 overflow-ellipsis font-semibold"}>{blueprint.title}</p>
                    <p className={"line-clamp-1 overflow-ellipsis text-xs text-muted-foreground"}>{url}</p>
                </div>
            </a>
        );
    } else {
        throw new Error(`reference type ${reference.type} not supported`);
    }
}

function UseToolView(props: { isLoading: boolean; onUseTool: () => void }) {
    const { action: useTool, isPending } = useOAuthServiceAction("google", props.onUseTool);

    if (props.isLoading || isPending) {
        return (
            <div className={"flex flex-row items-center gap-2"}>
                <Spinner />
                <p className={"text-sm font-semibold"}>Setting up tool...</p>
            </div>
        );
    }

    return (
        <div className={"flex flex-col items-start gap-1"}>
            <Button onClick={useTool}>
                <Wrench className={"me-2 h-5 w-5"} />
                Use Tool
            </Button>
            <span className={"text-xs text-muted-foreground"}>
                This will create a file in your Google Drive and link it to this playbook.
            </span>
        </div>
    );
}

export default function ToolTemplateSegmentContentView({
    content,
    edit,
    isEditing,
}: SegmentContentViewProps<ToolTemplateContentBlueprint>) {
    const pb = usePlaybookOptional();

    const { state, updateEdit } = useScopedEdit(edit, () => ({
        reference: edit?.reference,
    }));

    const useTemplateMutation = useMutation({
        mutationFn: async () => {
            const res = await apiClient.useToolTemplate({
                params: {
                    playbookId: pb!.playbook.id,
                },
                body: {
                    service: "google",
                    blueprint: content,
                },
            });
            const body = res.body as ToolTemplateContentEdit;
            updateEdit((e) => {
                e!.reference = body.reference;
            });
            openReference(body.reference);
        },
    });

    return (
        <div className={"relative grid grid-cols-3 items-center justify-between space-x-4 rounded-md border px-4 py-3"}>
            <img
                src={content.imageUrl}
                alt={content.title}
                className={"aspect-3/2 overflow-hidden rounded-md bg-accent object-cover"}
                style={{ objectPosition: "top left" }}
            />
            <div className={"col-span-2"}>
                <h4>{content.title}</h4>
                {content.description ? <p className={"text-sm text-muted-foreground"}>{content.description}</p> : null}
                <div className={"mt-2 flex flex-col gap-2"}>
                    {state.reference && <ToolReferenceView reference={state.reference} blueprint={content} />}
                    {!state.reference && isEditing && (
                        <UseToolView isLoading={useTemplateMutation.isPending} onUseTool={useTemplateMutation.mutate} />
                    )}
                </div>
            </div>
        </div>
    );
}
