import { buildVersion } from "../build";

export interface Config {
    env: "prod" | "dev" | "local" | "test";
    serviceHost: string;
    envTitle?: string;
    buildVersion: string;
    firebaseAuthEmulatorHost?: string;
}

export enum Environment {
    prod = "prod",
    dev = "dev",
    local = "local",
    test = "test",
}

const defaultConfig: Config = {
    env: "prod",
    serviceHost: "https://prod.api.tekkr.io",
    buildVersion,
};

const envConfig: Partial<Record<Environment, Partial<Config> & Pick<Config, "env">>> = {
    [Environment.local]: {
        env: "local",
        serviceHost: "http://localhost:8000",
        envTitle: "LOCAL",
    },
    [Environment.dev]: {
        env: "dev",
        serviceHost: "https://dev.api.tekkr.io",
        envTitle: "DEV",
    },
    [Environment.test]: {
        env: "test",
        firebaseAuthEmulatorHost: process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST,
        serviceHost: process.env.REACT_APP_BACKEND_HOST,
        envTitle: "TEST",
    },
};

export const config: Config = (() => {
    let env = Environment.prod;
    if (window.location.host.includes("tekkr-dev")) {
        env = Environment.dev;
    } else if (window.location.host.includes("localhost")) {
        env = Environment.local;
    }
    if (process.env.REACT_APP_TEKKR_TEST) {
        env = Environment.test;
    }
    const overrides = envConfig[env];
    return {
        ...defaultConfig,
        ...overrides,
    };
})();
