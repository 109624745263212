"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spaceContentBlueprintSchema = void 0;
const blueprint_1 = require("../../blueprint");
const zod_1 = require("zod");
exports.spaceContentBlueprintSchema = blueprint_1.contentBlueprintSchema
    .extend({
    type: zod_1.z.literal(blueprint_1.ContentType.space),
})
    .strict();
