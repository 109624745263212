import React from "react";
import { StagePageContainer } from "../stage-container";
import { Button } from "../../../../components/ui/button";
import { CheckIcon, Hourglass, MessageSquare } from "lucide-react";
import { IconForPlaybookStage } from "../../../../components/shared/icon-playbook-stage";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { usePlaybookPageNavigation } from "../../playbook-page";
import { usePlaybookAlignmentEdit } from "../../controller/hooks/alignment-edit";
import { useQuery } from "@tanstack/react-query";
import { usePlaybook } from "../../controller/hooks/playbook";
import { apiClient } from "../../../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { DateTime, DateTimeFormatOptions } from "luxon";
import { cn } from "../../../../lib/utils";
import _ from "lodash";
import { useBlueprint } from "../../controller/hooks/blueprint";
import { useScheduledPlaybookMeetings } from "../../controller/hooks/scheduled-playbook-meetings";

function MessagesAnimation() {
    return (
        <div className={"flex flex-row items-center gap-0.5"}>
            <MessageSquare className={"animate-conversation-bubble-left h-8 w-8"} />
            <MessageSquare className={"animate-conversation-bubble-right h-8 w-8"} />
        </div>
    );
}

const meetingDateFormat: DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
};

function DialogContent(props: { onProceedClicked: () => void }) {
    const { blueprint } = useBlueprint();
    return (
        <div className={"flex flex-col items-center gap-4"}>
            <MessagesAnimation />
            <h2>Run your Alignment</h2>
            <p className={"text-center"}>
                It's time to run your alignment meetings or wait for feedback on the requests you've sent out. Come back
                here once you have aligned with everyone.
            </p>
            <MeetingList
                className={"max-h-64 self-stretch overflow-y-scroll rounded-md"}
                scopeFilter={["alignment"]}
                tagger={({ scope }) => {
                    if (scope[1] === "review") {
                        return (
                            blueprint.alignment.playbookReviews?.find((r) => r.id === scope[2])?.navigationTitle ??
                            undefined
                        );
                    }
                    return undefined;
                }}
            />
            <Button onClick={props.onProceedClicked} className={"group"}>
                <IconForPlaybookStage
                    stage={PlaybookStage.implementation}
                    className={"me-2 h-5 w-5 transition-all duration-500 group-hover:rotate-12"}
                />
                Start Implementation
            </Button>
        </div>
    );
}

type MeetingItem = ClientInferResponseBody<typeof apiContract.getScheduledPlaybookMeetings, 200>["data"][0];

function MeetingList(props: {
    className?: string;
    scopeFilter?: string[];
    tagger?: (m: MeetingItem) => string | undefined;
}) {
    const { meetings } = useScheduledPlaybookMeetings({
        scopeFilter: props.scopeFilter,
    });

    if (!meetings) {
        return null;
    }

    const sortedMeetings = _.sortBy(meetings, "end");

    return (
        <>
            <div className={cn("flex flex-col gap-4", props.className)}>
                {sortedMeetings.map((d) => {
                    const done = DateTime.fromISO(d.end) < DateTime.now();
                    const tag = props.tagger && props.tagger(d);
                    return (
                        <div
                            key={d.id}
                            className={cn(
                                "flex w-full flex-row items-center gap-4 rounded-md px-4 py-2 text-sm",
                                done ? "bg-confirmation/10 opacity-65" : "border border-muted"
                            )}
                        >
                            <div
                                className={cn(
                                    "flex h-8 w-8 shrink-0 items-center justify-center rounded-full",
                                    done ? "bg-confirmation" : "bg-muted"
                                )}
                            >
                                {done ? <CheckIcon className={"h-4 w-4"} /> : <Hourglass className={"h-4 w-4"} />}
                            </div>
                            <div className={"flex flex-col items-start gap-1"}>
                                <span className={"font-medium"}>{d.title}</span>
                                <div className={"flex flex-row items-center gap-3"}>
                                    <span className={"shrink-0 text-sm text-muted-foreground"}>
                                        {DateTime.fromISO(d.start).toLocaleString(meetingDateFormat)}
                                    </span>
                                    {tag && (
                                        <span className={"line-clamp-1 rounded-full bg-accent px-2 py-0.5"}>{tag}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export function PlaybookReviewHoldPage() {
    const { alignmentEdit, updateAlignment } = usePlaybookAlignmentEdit();

    const nav = usePlaybookPageNavigation();
    const moveOn = () => {
        alignmentEdit.reviewsCompleted = true;
        updateAlignment();
        nav?.goToNext();
    };
    return (
        <StagePageContainer
            hideNavigationButtons={true}
            header={"Alignment"}
            overlayDialog={<DialogContent onProceedClicked={moveOn} />}
            explainer={`Review your playbook to surface and address concerns early.`}
        ></StagePageContainer>
    );
}
