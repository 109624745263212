"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.blueprintReferenceContentBlueprintSchema = void 0;
const blueprint_1 = require("../../blueprint");
const zod_1 = require("zod");
exports.blueprintReferenceContentBlueprintSchema = blueprint_1.contentBlueprintSchema
    .extend({
    type: zod_1.z.literal(blueprint_1.ContentType.blueprintReference),
    blueprintId: zod_1.z.string(),
})
    .strict();
