"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formats = void 0;
const enums_1 = require("../model/playbook/enums/enums");
const seriesStageNames = {
    [enums_1.CompanyStage.seriesA]: "A",
    [enums_1.CompanyStage.seriesB]: "B",
    [enums_1.CompanyStage.seriesC]: "C",
};
const titleCaseConstants = {
    mustLowers: new Set([
        "a",
        "an",
        "the",
        "and",
        "but",
        "or",
        "for",
        "nor",
        "as",
        "at",
        "by",
        "for",
        "from",
        "in",
        "into",
        "near",
        "of",
        "on",
        "onto",
        "to",
        "with",
    ]),
    mustUpper: new Set(["id", "tv"]),
};
exports.formats = {
    friendly: {
        teamSizeApplicability({ min, max }) {
            if (min && max) {
                return `${min} - ${max}`;
            }
            else if (min) {
                return `${min}+`;
            }
            else if (max) {
                return `< ${max}`;
            }
            else {
                return "any size";
            }
        },
        companyStageApplicability(stages) {
            if (stages.length === Object.keys(enums_1.CompanyStage).length || stages.length === 0) {
                return "any stage";
            }
            const result = [];
            if (stages.includes(enums_1.CompanyStage.seed)) {
                result.push("Seed");
            }
            const series = [];
            for (const stage of stages) {
                const name = seriesStageNames[stage];
                if (name) {
                    series.push(name);
                }
            }
            if (series.length > 0) {
                result.push(`Series ${series.sort().join("/")}`);
            }
            return result.join(", ");
        },
        rating(rating) {
            return rating.score.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        },
        commaList(values, options) {
            if (values.length === 0) {
                throw new Error("at least one value required");
            }
            if (values.length > 1) {
                const last = values[values.length - 1];
                const others = values.slice(0, values.length - 1);
                const oxfordComma = values.length > 2;
                return others.join(", ") + (oxfordComma ? ", " : " ") + (options?.conjunction ?? "and") + " " + last;
            }
            else {
                return values[0];
            }
        },
        titleCase: function (str) {
            const length = str.length;
            return str.replace(/\b\w+/g, function (txt, offset) {
                const word = txt.toLowerCase();
                if (offset != 0 && offset != length - word.length && titleCaseConstants.mustLowers.has(word))
                    return word;
                else if (titleCaseConstants.mustUpper.has(word))
                    return word.toUpperCase();
                else
                    return word[0].toUpperCase() + word.substring(1);
            });
        },
    },
};
