import { Calendar, ExternalLink } from "lucide-react";
import React from "react";
import { SelectContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/select/edit";
import { useGlobalEdit } from "../../../components/hooks/use-edit";
import {
    SelectContentBlueprint,
    SelectContentSubject,
} from "tekkr-common/dist/model/playbook/segment/content/types/select/blueprint";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import { getMeetingDurationMinutes } from "../../../lib/edit-utils";
import SelectSegmentContentView from "../../../components/shared/playbook-view/components/content/types/select";
import { AiButton } from "../../../components/shared/ai-button";

interface Props {
    durationEdit: SelectContentEdit;
    title: string;
    scheduleMutation: {
        isPending: boolean;
        mutate: (minutes: number) => unknown;
    };
    reschedule?: boolean;
}

export function GroupsMeetingSchedulingView(props: Props) {
    const durationContentBlueprint: SelectContentBlueprint = {
        id: "duration",
        type: ContentType.select,
        subject: SelectContentSubject.meetingDuration,
        default: "30min",
    };
    const { state } = useGlobalEdit(props.durationEdit, (e) => ({
        durationMinutes: getMeetingDurationMinutes(e, durationContentBlueprint),
    }));
    return (
        <div>
            <SelectSegmentContentView content={durationContentBlueprint} edit={props.durationEdit} isEditing={true} />
            <div className={"my-2 max-w-md rounded-md border p-4 text-sm"}>
                <div className={"flex flex-row items-start gap-4"}>
                    <Calendar className={"mt-1 h-5 w-5 shrink-0"} />
                    <div className={"flex flex-col gap-1"}>
                        <p className={"font-semibold"}>{props.title}</p>
                        <p className={"text-muted-foreground"}>{state.durationMinutes} Minutes</p>
                    </div>
                </div>
                <AiButton
                    variant={props.reschedule ? "outline" : undefined}
                    isLoading={props.scheduleMutation.isPending}
                    onClick={() => props.scheduleMutation.mutate(state.durationMinutes)}
                    className={"mt-4"}
                >
                    <ExternalLink className={"me-2 h-5 w-5"} />
                    <span>{props.reschedule ? "Schedule again" : "Schedule Meeting"}</span>
                </AiButton>
            </div>
        </div>
    );
}
