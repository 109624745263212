"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookStateSchema = void 0;
exports.computePlaybookEditProgress = computePlaybookEditProgress;
exports.computePlaybookTargetReviewProgress = computePlaybookTargetReviewProgress;
exports.computePlaybookAlignmentProgress = computePlaybookAlignmentProgress;
exports.computePlaybookImplementationProgress = computePlaybookImplementationProgress;
exports.computePlaybookCommunicationProgress = computePlaybookCommunicationProgress;
exports.playbookState = playbookState;
const blueprint_1 = require("./segment/blueprint");
const zod_1 = require("zod");
const enums_1 = require("./enums/enums");
const compute_todos_1 = require("./stages/implementation/compute-todos");
function computePlaybookEditProgress(blueprint, edit) {
    if (!edit) {
        return 0;
    }
    let count = 0, done = 0;
    for (const segment of blueprint.segments) {
        if (edit.segments?.[segment.id]?.hidden)
            continue;
        if (segment.style !== blueprint_1.SegmentStyle.card)
            continue;
        count += 1;
        done += edit.segments?.[segment.id]?.done ? 1 : 0;
    }
    return done / count;
}
function computePlaybookTargetReviewProgress(blueprint, edit) {
    let done = 0, count = 0;
    if (!edit) {
        return 0;
    }
    // scheduling of target reviews
    blueprint.targetReview?.expectationReviews?.forEach((tr) => {
        count++;
        if (edit.reviews[tr.id]?.done) {
            done++;
        }
    });
    // drafting of targets (only if there is a review)
    if (blueprint.targetReview?.expectationReviews?.length) {
        count++;
        if (edit.targetDraftDone) {
            done++;
        }
    }
    // setting targets
    count++;
    if (edit.targetSettingDone) {
        done++;
    }
    // communicate targets
    if (blueprint.targetReview?.communication) {
        count++;
        if (edit.communication.done) {
            done++;
        }
    }
    return done / count;
}
function computePlaybookAlignmentProgress(blueprint, edit) {
    if (!edit) {
        return 0;
    }
    let done = 0, count = 0;
    // reviews
    blueprint.alignment?.playbookReviews?.forEach((review) => {
        count++;
        if (edit.playbookReviews[review.id]?.done) {
            done++;
        }
    });
    // roastings
    blueprint.alignment?.roastings?.forEach((roasting) => {
        count++;
        if (edit.roastings[roasting.id]?.done) {
            done++;
        }
    });
    return done / count;
}
function computePlaybookImplementationProgress(pw) {
    if (!pw.playbook.edit) {
        return 0;
    }
    const todos = (0, compute_todos_1.computePlaybookTodos)(pw);
    const count = todos.length;
    const done = todos.filter((t) => (0, compute_todos_1.isCompletedTodoState)(pw.playbook.implementation?.todos?.[t.id]?.status)).length;
    return done / count;
}
function computePlaybookCommunicationProgress(blueprint, communication) {
    if (!communication) {
        return 0;
    }
    let done = 0, count = 0;
    return done / count;
}
exports.playbookStateSchema = zod_1.z.record(zod_1.z.nativeEnum(enums_1.PlaybookStage), zod_1.z.number());
function playbookState(pw) {
    const state = {};
    // edit is always present
    state.edit = computePlaybookEditProgress(pw.blueprint, pw.playbook.edit);
    // playbooks always have at least the set targets stage
    state.targets = computePlaybookTargetReviewProgress(pw.blueprint, pw.playbook.targetsReview);
    const alignment = pw.blueprint.alignment;
    if ((alignment && alignment.playbookReviews?.length) || alignment?.roastings?.length) {
        state.alignment = computePlaybookAlignmentProgress(pw.blueprint, pw.playbook.alignment);
    }
    if (pw.blueprint.todos) {
        state.implementation = computePlaybookImplementationProgress(pw);
    }
    return state;
}
