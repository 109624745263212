import React from "react";
import { StagePageContainer } from "../stage-container";
import { EditScope, useScopedEdit } from "../../../../components/hooks/use-edit";
import { usePlaybookAlignmentEdit } from "../../controller/hooks/alignment-edit";
import {
    AlignmentReviewMode,
    PlaybookAlignmentEdit,
    PlaybookAlignmentReviewBlueprint,
    PlaybookAlignmentReviewEdit,
} from "tekkr-common/dist/model/playbook/stages/alignment";
import { usePlaybook } from "../../controller/hooks/playbook";
import { SegmentContainer } from "../../../../components/shared/playbook-view/components/segment";
import PeopleListSegmentContentView from "../../../../components/shared/playbook-view/components/content/types/people-list";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import { Label } from "../../../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../../components/ui/radio-group";
import EditableTextSegmentContentView from "../../../../components/shared/playbook-view/components/content/types/editable-text";
import { GroupsMeetingSchedulingView } from "../../components/groups-meeting-scheduling-view";
import { useMutation } from "@tanstack/react-query";
import { usePlaybookPeopleGroups } from "../../controller/hooks/people-groups";
import { useOrg } from "../../../../auth/org-provider";
import { MeetingSchedulingMatrix } from "../../components/meeting-scheduling-matrix-view";
import { Org } from "../../../../service/tekkr-service-types";
import SelectSegmentContentView from "../../../../components/shared/playbook-view/components/content/types/select";
import {
    SelectContentBlueprint,
    SelectContentSubject,
} from "tekkr-common/dist/model/playbook/segment/content/types/select/blueprint";
import { getMeetingDurationMinutes, getTimelineDays } from "../../../../lib/edit-utils";
import { AiPlug } from "../../../../components/shared/ai-plug";
import { prompts } from "../../../../service/prompts";
import { datePlusBusinessDays } from "../../../../lib/utils";
import { useBlueprint } from "../../controller/hooks/blueprint";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { useScheduleEvent } from "../../../../lib/event-scheduling";

function AsyncReviewView(props: {
    review: PlaybookAlignmentReviewBlueprint;
    reviewEdit: PlaybookAlignmentReviewEdit
}) {
    const { playbook } = usePlaybook();
    const { blueprint } = useBlueprint();

    const timelineContent: SelectContentBlueprint = {
        id: "deadline",
        type: ContentType.select,
        subject: SelectContentSubject.turnaroundTime,
        default: "3d",
    };
    const generateText = async () => {
        const timelineDays = getTimelineDays(props.reviewEdit.asyncMessage.timeline, timelineContent);
        const peopleGroup = blueprint.peopleGroups!.find((pg) => pg.id === props.review.peopleGroup)!;
        return await prompts.alignPlaybookReviewMessage({
            playbookId: playbook.id,
            dynamicVars: {
                deadline: datePlusBusinessDays(timelineDays).toLocaleString({
                    weekday: "long",
                    year: "numeric",
                    day: "numeric",
                    month: "long",
                }),
                people_group_name: peopleGroup.name,
            },
        });
    };
    return (
        <div className={"flex flex-col gap-4 pt-4"}>
            <h3>Request Asynchronous Review</h3>
            <p>
                Send a message to your stakeholders requesting their feedback. Here's a draft that you can adjust and
                send:
            </p>
            <SelectSegmentContentView
                content={timelineContent}
                options={{ disableGridAlignment: true }}
                edit={props.reviewEdit.asyncMessage.timeline}
                isEditing={true}
            />
            <EditableTextSegmentContentView
                content={{
                    id: "text",
                    type: ContentType.editableText,
                }}
                edit={props.reviewEdit.asyncMessage.message}
                copy={"message"}
                isEditing={true}
                textGenerator={generateText}
            />
        </div>
    );
}

function GroupMeetingView(props: {
    review: PlaybookAlignmentReviewBlueprint;
    reviewEdit: PlaybookAlignmentReviewEdit;
}) {
    const edit = props.reviewEdit.groupMeetingDurationEdit;
    const { playbook } = usePlaybook();
    const { blueprint } = useBlueprint();
    const peopleGroups = usePlaybookPeopleGroups();
    const org = useOrg();

    const meetingTitle = `Playbook Review: ${playbook.edit!.title}`;

    const { state, updateEdit } = useScopedEdit(props.reviewEdit, (e) => ({
        meetingScheduled: e.groupMeetingScheduled,
    }));

    const scheduleEventInExternalTool = useScheduleEvent();
    const scheduleMutation = useMutation({
        mutationFn: async (minutes: number) => {
            if (!state.meetingScheduled) {
                updateEdit((e) => {
                    e.groupMeetingScheduled = true;
                });
            }
            const peopleGroup = blueprint.peopleGroups!.find((pg) => pg.id === props.review.peopleGroup)!;
            const message = await prompts.alignPlaybookReviewGroupMeetingDescription({
                playbookId: playbook.id,
                format: "html",
                dynamicVars: {
                    people_group_name: peopleGroup.name,
                },
            });
            const [inviteeIds] = peopleGroups.byId(props.review.peopleGroup);
            const inviteeIdSet = new Set<string>(inviteeIds);
            const invitees = org.users.filter((u) => inviteeIdSet.has(u.id));

            await scheduleEventInExternalTool(org.officeSuiteProvider, {
                description: message,
                eventName: meetingTitle,
                invitees: invitees.map((u) => u.email),
                meetingDuration: { minute: minutes },
                context: {
                    playbookId: playbook.id,
                    scope: [PlaybookStage.alignment, "review", props.review.id, "group-meeting"],
                },
            });
        },
    });
    return (
        <div className={"flex flex-col gap-4 pt-4"}>
            <h3>Schedule Group Meeting</h3>
            <GroupsMeetingSchedulingView
                reschedule={state.meetingScheduled}
                durationEdit={edit}
                title={meetingTitle}
                scheduleMutation={scheduleMutation}
            />
        </div>
    );
}

function IndividualMeetingsView(props: {
    review: PlaybookAlignmentReviewBlueprint;
    reviewEdit: PlaybookAlignmentReviewEdit;
}) {
    const { playbook } = usePlaybook();
    const org = useOrg();
    const [peopleGroup] = usePlaybookPeopleGroups().byId(props.review.peopleGroup);

    const { state, updateEdit } = useScopedEdit(props.reviewEdit, (e) => ({
        scheduled: e.scheduledIndividualMeetings ?? [],
    }));
    const onScheduledUpdated = (scheduled: string[]) => {
        updateEdit((e) => {
            e.scheduledIndividualMeetings = scheduled;
        });
    };

    const durationSelectContentBlueprint: SelectContentBlueprint = {
        default: "30min",
        descriptionMarkdown: "::info[Tekkr recommends 30 minutes]",
        id: "duration",
        subject: SelectContentSubject.meetingDuration,
        type: ContentType.select,
    };

    const scheduleEventInExternalTool = useScheduleEvent();
    const schedule = async (user: Org["users"][0]) => {
        const message = await prompts.alignPlaybookReviewIndividualMeetingsDescription({
            playbookId: playbook.id,
            format: "html",
        });

        await scheduleEventInExternalTool(org.officeSuiteProvider, {
            description: message,
            eventName: `Playbook Review for "${playbook.edit!.title}"`,
            invitees: [user.email],
            meetingDuration: {
                minute: getMeetingDurationMinutes(
                    props.reviewEdit.individualMeetingsDurationEdit,
                    durationSelectContentBlueprint
                ),
            },
            context: {
                playbookId: playbook.id,
                scope: [PlaybookStage.alignment, "review", props.review.id, "individual-meeting", user.id],
            },
        });
    };

    return (
        <div className={"flex flex-col gap-4 pt-4"}>
            <h3>Schedule 1-on-1 Review Meetings</h3>
            <SelectSegmentContentView
                content={durationSelectContentBlueprint}
                options={{ disableGridAlignment: true }}
                edit={props.reviewEdit.individualMeetingsDurationEdit}
                isEditing={true}
            />
            <MeetingSchedulingMatrix
                users={peopleGroup}
                scheduled={state.scheduled}
                schedule={schedule}
                onScheduledUpdated={onScheduledUpdated}
            />
            <AiPlug>Tekkr will generate a meeting description for you based on the content of this playbook.</AiPlug>
        </div>
    );
}

function PageContent(props: { edit: PlaybookAlignmentEdit; review: PlaybookAlignmentReviewBlueprint }) {
    const { blueprint } = useBlueprint();

    const peopleGroupBlueprint = blueprint.peopleGroups!.find((g) => g.id === props.review.peopleGroup)!;

    if (!props.edit.playbookReviews[props.review.id]) {
        props.edit.playbookReviews[props.review.id] = {
            asyncMessage: {
                message: {},
                timeline: {},
            },
            groupMeetingDurationEdit: {},
            individualMeetingsDurationEdit: {},
            scheduledIndividualMeetings: [],
        };
    }
    const recommendedMode = props.review.recommendedMode ?? AlignmentReviewMode.async;
    const edit = props.edit.playbookReviews[props.review.id]!;
    const { state, updateEdit } = useScopedEdit(edit, () => ({
        done: edit.done ?? false,
        mode: edit.mode ?? recommendedMode,
    }));
    const setDone = (done: boolean) => {
        updateEdit(() => {
            edit.done = done;
        });
    };
    const setReviewMode = (mode: AlignmentReviewMode) => {
        updateEdit(() => {
            edit.mode = mode;
        });
    };

    return (
        <StagePageContainer
            header={props.review.title}
            onNext={() => setDone(true)}
            explainer={
                props.review.description ??
                `Review your playbook with ${peopleGroupBlueprint.name.toLowerCase()} to surface and address concerns early.`
            }
        >
            <SegmentContainer
                id={props.review.id}
                name={"Playbook Review"}
                title={props.review.title}
                isEditing={true}
                hidden={false}
                done={state.done}
                expanded={true}
                setDone={setDone}
                options={{
                    hideControls: true,
                }}
            >
                <div className={"flex flex-col gap-4 pb-4"}>
                    <h3>Review Stakeholders</h3>
                    <PeopleListSegmentContentView
                        content={{
                            id: "people",
                            peopleGroupId: props.review.peopleGroup,
                            communicate: false,
                            title: peopleGroupBlueprint.name,
                            type: ContentType.peopleList,
                        }}
                        edit={{}}
                        isEditing={true}
                        options={{ disableGridAlignment: true }}
                    />
                </div>

                <hr className={"-mx-10"} />

                <div className={"flex flex-col gap-4 py-4"}>
                    <h3>Select Review Mode</h3>
                    <p>Choose your preferred way of conducting this review:</p>
                    <RadioGroup value={state.mode} onValueChange={setReviewMode} className={"flex flex-col gap-3"}>
                        {[
                            {
                                mode: AlignmentReviewMode.async,
                                title: "Asynchronous",
                            },
                            {
                                mode: AlignmentReviewMode.groupMeeting,
                                title: "Group Meeting",
                            },
                            {
                                mode: AlignmentReviewMode.individualMeetings,
                                title: "1-on-1 Meetings",
                            },
                        ].map(({ mode, title }) => (
                            <div key={mode} className="flex items-center space-x-2">
                                <RadioGroupItem value={mode} id={`radio-item-review-mode-${mode}`} />
                                <Label htmlFor={`radio-item-review-mode-${mode}`} className={"font-semibold"}>
                                    {title}
                                    {recommendedMode === mode && (
                                        <span className={"text-sm text-muted-foreground"}> (recommended)</span>
                                    )}
                                </Label>
                            </div>
                        ))}
                    </RadioGroup>
                </div>

                {state.mode && <hr className={"-mx-10"} />}

                {
                    state.mode === AlignmentReviewMode.async && (
                        <AsyncReviewView key={props.review.id} review={props.review} reviewEdit={edit} />
                    ) /*this needs a key, since comps are reused otherwise between pages*/
                }
                {state.mode === AlignmentReviewMode.groupMeeting && (
                    <GroupMeetingView review={props.review} reviewEdit={edit} />
                )}
                {state.mode === AlignmentReviewMode.individualMeetings && (
                    <IndividualMeetingsView review={props.review} reviewEdit={edit} />
                )}
            </SegmentContainer>
        </StagePageContainer>
    );
}

export function AlignmentReviewPage(props: { reviewId: string }) {
    const { blueprint } = useBlueprint();
    const { alignmentEdit, updateAlignment } = usePlaybookAlignmentEdit();

    const review = blueprint.alignment!.playbookReviews!.find((it) => it.id === props.reviewId)!;
    return (
        <EditScope onEditUpdated={updateAlignment}>
            <PageContent edit={alignmentEdit} review={review} />
        </EditScope>
    );
}
