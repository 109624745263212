import React from "react";
import { ContentSpacer } from "../common/layout";
import { PlaybookOverviewAnalyticsTable } from "./components/playbook-table";
import { DummyChart } from "../playbook/stages/track/track-metrics-page";
import { Button } from "../../components/ui/button";
import { LockOpenIcon } from "lucide-react";
import { useGlobalDialogs } from "../../modals/global-dialogs";

export function AnalyticsPage() {
    const globalDialogs = useGlobalDialogs();
    return (
        <ContentSpacer>
            <h1 className={"mt-4 duration-300 animate-in fade-in-75 slide-in-from-top-2"}>Tekkr Analytics</h1>
            <p className={"animate-in fade-in-50 slide-in-from-bottom-1"}>
                See how your playbooks are performing and what you can do to unblock your intiatives.
            </p>

            <div className={"duration-700 animate-in slide-in-from-bottom-4"}>
                <h3 className={"mb-3 mt-4"}>Playbooks In Progress</h3>
                <PlaybookOverviewAnalyticsTable />
            </div>

            <div className={"relative duration-500 animate-in slide-in-from-bottom-2"}>
                <h3 className={"mb-3 mt-4 blur"}>Summary</h3>
                <div className={"grid grid-cols-2 gap-4 opacity-65 blur"}>
                    <DummyChart
                        className={"pointer-events-none"}
                        metric={{
                            name: "Growth (Dummy)",
                            unit: "%",
                            description: "this is a fake metric",
                            values: [7.5, 7.6, 8, 9, 10, 10, 8.5, 9.2, 9],
                            goal: "up",
                            success: "good",
                        }}
                    />
                    <DummyChart
                        className={"pointer-events-none"}
                        metric={{
                            name: "Playbooks Implemented (Dummy)",
                            unit: "# Playbooks",
                            description: "this is a fake metric",
                            values: [15, 14, 14, 14, 14, 12, 12, 12, 11, 11, 11],
                            goal: "up",
                            success: "good",
                        }}
                    />
                </div>
                <div className={"flex w-full flex-col items-center"}>
                    <div
                        className={
                            "absolute top-12 mx-8 flex flex-col items-center gap-2 self-center rounded-lg border bg-secondary/10 p-4 backdrop-blur duration-500 animate-in zoom-in-95 slide-in-from-bottom-1.5"
                        }
                    >
                        <span className={"font-semibold"}>Upgrade to get full access</span>
                        <span className={"max-w-xl text-center text-sm"}>
                            Get access to org-wide playbook and success metrics that help you run multiple playbooks
                            successfully and to unlock the full potential of your tech team!
                        </span>
                        <Button
                            className={"mt-4 transition-all duration-500 hover:drop-shadow-glow-primary"}
                            onClick={() => globalDialogs?.showUnlockAllPlaybooksCta()}
                        >
                            <LockOpenIcon className={"me-2 h-5 w-5"} />
                            Unlock Now
                        </Button>
                    </div>
                </div>
            </div>
        </ContentSpacer>
    );
}
