import { usePlaybook } from "./playbook";
import { useMutation } from "@tanstack/react-query";
import { playbookMutations } from "../queries";
import { useCallback } from "react";
import debounce from "debounce";

export function usePlaybookImplementationEdit() {
    const { playbook } = usePlaybook();

    if (!playbook.implementation) {
        playbook.implementation = {
            todos: {},
        };
    }
    const edit = playbook.implementation!;

    const mutation = useMutation(playbookMutations.updateImplementation(playbook.id));
    const onUpdated = useCallback(
        debounce(() => {
            mutation.mutate(edit);
        }, 500),
        [playbook.id]
    );

    return {
        implementationEdit: edit,
        updateImplementation: onUpdated,
    };
}
