import React from "react";
import { StagePageContainer } from "../stage-container";
import { XAxis, YAxis, Tooltip as ChartTooltip, AreaChart, Area, ReferenceLine, ResponsiveContainer } from "recharts";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import { DateTime } from "luxon";
import { ChevronDown, ChevronUp, Minus, TrendingDown, TrendingUp } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../../components/ui/tooltip";
import { cn } from "../../../../lib/utils";
import { Badge } from "../../../../components/ui/badge";
import { useBlueprint } from "../../controller/hooks/blueprint";

type DummyMetric = Exclude<PlaybookBlueprint["dummyMetrics"], undefined>[0];

const unitMap: Record<string, string> = {
    d: " days",
    w: " weeks",
    h: " hours",
};

function MetricStateIndicator(props: { goal: DummyMetric["goal"]; success: DummyMetric["success"] }) {
    if (props.success === "neutral") {
        return <Minus className={"h-5 w-5 text-orange-300"} />;
    }

    const color = props.success === "bad" ? "text-red-300" : "text-confirmation";

    const up = (props.goal === "up" && props.success === "good") || (props.goal === "down" && props.success === "bad");
    if (up) {
        return <ChevronUp className={cn("h-5 w-5", color)} />;
    } else {
        return <ChevronDown className={cn("h-5 w-5", color)} />;
    }
}

function unitText(metric: DummyMetric) {
    if (!metric.unit) {
        return "";
    }
    return unitMap[metric.unit] ?? metric.unit;
}

export function DummyChart(props: { metric: DummyMetric; className?: string }) {
    const data = props.metric.values
        .map((v, index) => {
            return {
                date: DateTime.now()
                    .minus({ day: 1 + index * 6 })
                    .toMillis(),
                value: v,
            };
        })
        .reverse();

    const start = data[0].value;
    const end = data[data.length - 1].value;
    const trend = start < end ? "up" : start === end ? "unchanged" : "down";
    const change = Math.round(Math.abs(end - start) * 10) / 10;

    return (
        <div className={props.className}>
            <div className={"flex flex-row items-center gap-2"}>
                <h4>{props.metric.name}</h4>
                <Tooltip>
                    <TooltipTrigger asChild>
                        {props.metric.goal === "up" ? (
                            <TrendingUp className={"h-4 w-4 cursor-help"} />
                        ) : (
                            <TrendingDown className={"h-4 w-4 cursor-help"} />
                        )}
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>You want this metric to go {props.metric.goal}</p>
                    </TooltipContent>
                </Tooltip>
            </div>
            <p className={"mb-2 text-wrap text-sm text-muted-foreground"}>{props.metric.description}</p>

            <div className={"mb-8 mt-4"}>
                <h1>
                    {data[data.length - 1].value}
                    {unitText(props.metric)}
                </h1>
                <div className={"flex flex-row items-center gap-2 text-sm"}>
                    <MetricStateIndicator goal={props.metric.goal} success={props.metric.success} />
                    {trend}{" "}
                    {trend !== "unchanged" && (
                        <>
                            {change + unitText(props.metric)} from baseline value{" "}
                            <Badge variant={"secondary"}>
                                {data[0].value}
                                {unitText(props.metric)}
                            </Badge>
                        </>
                    )}
                </div>
            </div>
            <ResponsiveContainer width={"100%"} height={220}>
                <AreaChart data={data} margin={{ top: 0, right: 0, left: -20, bottom: 0 }}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#ba1d68" stopOpacity={0.15} />
                            <stop offset="95%" stopColor="#ba1d68" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <ReferenceLine
                        x={data[1].date}
                        stroke="rgb(var(--muted))"
                        strokeWidth={2}
                        strokeDasharray={"5 7"}
                    />
                    <XAxis
                        tickFormatter={(v) =>
                            DateTime.fromMillis(v).toLocaleString({
                                month: "short",
                                day: "numeric",
                            })
                        }
                        dataKey="date"
                        className={"text-sm font-semibold"}
                        stroke={"rgb(var(--muted-foreground))"}
                    />
                    <YAxis
                        className={"text-sm font-semibold"}
                        stroke={"rgb(var(--muted-foreground))"}
                        unit={props.metric.unit}
                    />
                    <ChartTooltip
                        labelFormatter={(v) =>
                            DateTime.fromMillis(v).toLocaleString({
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                            })
                        }
                        formatter={(v) => [v + unitText(props.metric), props.metric.name]}
                        contentStyle={{
                            backgroundColor: "rgb(var(--background))",
                            border: "none",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                        }}
                        wrapperClassName={"text-foreground rounded-md text-sm font-semibold"}
                    />
                    <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#ba1d68"
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}

export function TrackMetricsPage() {
    const { blueprint } = useBlueprint();
    return (
        <StagePageContainer
            hideNavigationButtons={true}
            header={"Track Metrics"}
            explainer={
                "Monitoring key metrics is essential to measuring your playbook's effectiveness. These data points reveal whether you're hitting your targets and help identify where adjustments can lead to better results."
            }
        >
            <div className={"flex flex-col gap-4 relative"}>
                {blueprint.dummyMetrics?.map((d) => (
                    <div className={"rounded-lg border p-4 blur pointer-events-none"} key={d.name}>
                        <DummyChart metric={d} />
                    </div>
                ))}
                <div className={"absolute border rounded-lg p-8 bg-background/50 max-w-xl self-center mt-12"}>
                    <div className={"flex flex-col items-center text-center gap-3"}>
                        <h3>Coming Soon 🚧</h3>
                        <p>Tracking of success metrics is still under construction. Once done, you'll be able to see the impact of your playbooks reflect in metrics that Tekkr helps you track.</p>
                        <p className={"font-medium"}>Questions? <a className={"underline hover:text-primary font-medium transition-all"} target={"_blank"} href={"https://tekkr.io/contact-us"} rel="noreferrer">Contact us</a>.</p>
                    </div>
                </div>
            </div>
        </StagePageContainer>
    );
}
