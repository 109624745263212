import React from "react";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { useEffect } from "react";
import { usePlaybookPageNavigation } from "../playbook-page";
import { useCurrentOrgUser } from "../../../auth/org-provider";
import { Button } from "../../../components/ui/button";
import { Check, Edit, FilesIcon, Save, Share2Icon, TrashIcon, TriangleAlert } from "lucide-react";
import { triggerConfetti } from "../confetti";
import { computePlaybookEditProgress } from "tekkr-common/dist/model/playbook/state";
import Spinner from "../../../components/ui/spinner";
import { PlaybookActionBarItem, PlaybookHeader } from "../../../components/shared/playbook-view/playbook-header";
import { DateTime } from "luxon";
import PlaybookView from "../../../components/shared/playbook-view/playbook-view";
import { useCloseConfirmation } from "../../../components/hooks/close-confirmation";
import { PlaybookPeopleGroups } from "tekkr-common/dist/model/playbook/people-groups";
import { usePlaybook } from "../controller/hooks/playbook";
import { useUpdatePlaybookEdit } from "../controller/hooks/playbook-edit";
import { EditScope, useEditCallback } from "../../../components/hooks/use-edit";
import ConfirmationDialog from "../../../modals/confirmation-dialog";
import { SharePlaybookDialog } from "../../../modals/share-playbook-dialog";
import { MutationFilters, useIsMutating, useMutationState } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useBlueprint } from "../controller/hooks/blueprint";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../components/ui/tooltip";
import { useDebounce } from "use-debounce";
import { PlaybookAttachmentsSheet } from "../components/playbook-attachments-sheet";
import { usePlaybookAttachments } from "../controller/hooks/attachments";

type EditSession = {
    startingProgress: number;
};

function usePlaybookUpdatingState(): {
    isOk: boolean;
    isFetching: boolean;
    isError: boolean;
    updatedAt?: number;
} {
    const filters: MutationFilters = {
        predicate: (m) => m.meta?.["indicatePlaybookEditing"] === true,
    };
    const state = useMutationState({ filters });
    const isMutating = useIsMutating(filters);

    const _isFetching = isMutating > 0;
    const [isFetching] = useDebounce(_isFetching, _isFetching ? 0 : 350);
    const lastState = state[state.length - 1];

    return {
        isOk: !isFetching && (state.length === 0 || !lastState.error),
        isError: !isFetching && state.length > 0 && !!lastState.error,
        isFetching,
        updatedAt: lastState ? lastState.submittedAt : undefined,
    };
}

function EditSavingStateIndicator() {
    const state = usePlaybookUpdatingState();
    return (
        <div
            className={
                "mx-3 rounded-md px-4 py-1.5 text-sm font-semibold text-accent-foreground duration-300 animate-in fade-in slide-in-from-right-8"
            }
        >
            <div>
                {state.isFetching ? (
                    <div
                        className={
                            "flex flex-row items-center gap-2 text-muted-foreground animate-in slide-in-from-right-0.5"
                        }
                    >
                        <Spinner className={"h-5 w-5"}></Spinner> saving changes
                    </div>
                ) : null}
                {state.isOk && !!state.updatedAt ? (
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div
                                className={
                                    "flex cursor-default flex-row items-center gap-2 text-muted-foreground animate-in slide-in-from-right-0.5"
                                }
                            >
                                <Save className={"h-5 w-5 animate-in zoom-in-50"} /> changes saved
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className={"mt-6"}>
                            <p className={"max-w-sm text-sm"}>
                                All changes that you've made to this playbook have been saved successfully. ✅
                                {state.updatedAt && (
                                    <>
                                        <br />
                                        <span className={"text-xs text-muted-foreground"}>
                                            Last saved:{" "}
                                            {DateTime.fromMillis(state.updatedAt).toLocaleString({
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })}
                                        </span>
                                    </>
                                )}
                            </p>
                        </TooltipContent>
                    </Tooltip>
                ) : null}
                {state.isError && (
                    <div className={"flex flex-row items-center gap-2"}>
                        <TriangleAlert />
                        &nbsp; Changes could not be saved! &nbsp;
                        <u>
                            <a href={"https://www.tekkr.io/contact-us"}>Contact us.</a>
                        </u>
                    </div>
                )}
            </div>
        </div>
    );
}

function EditUpdater(props: { edit: PlaybookEdit }) {
    const updateEdit = useUpdatePlaybookEdit();
    useEditCallback(() => {
        updateEdit(props.edit);
    });
    return <></>;
}

export function PlaybookEditPage() {
    const { blueprint } = useBlueprint();
    const { playbook } = usePlaybook();

    const [editSession, setEditSession] = React.useState<EditSession | undefined>(undefined);

    function startEditSession() {
        setEditSession({
            startingProgress: computePlaybookEditProgress(blueprint, playbook.edit) ?? 0,
        });
    }

    function endEditSession() {
        if (editSession && editSession.startingProgress < 1) {
            const newProgress = computePlaybookEditProgress(blueprint, playbook.edit);
            if (newProgress === 1) {
                setTimeout(() => {
                    playbookNavigation?.showEditCompletedDialog();
                }, 1_500);
                triggerConfetti();
            }
        }
        setEditSession(undefined);
    }

    // wait until data is loaded and then start editing session if playbook was jsut created
    const [query] = useSearchParams();
    useEffect(() => {
        if (query.get("new_playbook") === "true") {
            startEditSession();
        }
    }, []);

    useCloseConfirmation(!!editSession);

    useEffect(() => {
        document.title = `${editSession ? "Editing: " : ""} ${playbook.edit?.title ?? blueprint.title ?? "Playbook"} - Tekkr`;
    }, [playbook, blueprint, editSession]);

    const playbookEdit = (playbook.edit ?? {}) as PlaybookEdit;
    const peopleGroups = (playbook.peopleGroups ?? {}) as PlaybookPeopleGroups;

    if (!playbookEdit.segments) {
        playbookEdit.segments = {};
    }

    const playbookNavigation = usePlaybookPageNavigation();
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
    const [documentSheetOpen, setDocumentSheetOpen] = React.useState(false);

    const { attachments } = usePlaybookAttachments();

    const actionBarItems: PlaybookActionBarItem[] = [
        {
            title: "Documents",
            onClick: () => setDocumentSheetOpen(true),
            badge: attachments.length || undefined,
            icon: FilesIcon,
        },
        { onClick: () => setShareDialogOpen(true), icon: Share2Icon },
    ];

    const orgUser = useCurrentOrgUser();
    if (playbook.contributors.owner === orgUser.id) {
        actionBarItems.push({
            onClick: () => setDeleteDialogOpen(true),
            icon: TrashIcon,
            variant: "destructive",
        });
    }

    const content = (
        <>
            <SharePlaybookDialog open={shareDialogOpen} onOpenChange={setShareDialogOpen}></SharePlaybookDialog>
            <PlaybookAttachmentsSheet open={documentSheetOpen} onOpenChange={setDocumentSheetOpen} />
            <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                title={"Delete this Playbook?"}
                confirmButtonVariant={"destructive"}
                body={
                    <p>
                        Do you really want to delete the playbook "<b>{playbookEdit.title}</b>"?
                    </p>
                }
                confirm={async () => playbookNavigation?.deletePlaybook()}
            ></ConfirmationDialog>
            <PlaybookHeader
                mode={"playbook"}
                blueprint={blueprint}
                playbook={playbook}
                isEditing={!!editSession}
                actionBarItems={actionBarItems}
                onReplaceImage={() => {}}
            />

            <PlaybookView playbookEdit={playbookEdit} peopleGroups={peopleGroups} isEditing={!!editSession} />

            <div className={"w-50 fixed right-24 top-0 z-50 flex h-16 flex-row items-center"}>
                {editSession ? <EditSavingStateIndicator /> : null}
                {editSession ? (
                    <Button size={"sm"} onClick={() => endEditSession()}>
                        <Check className={"h-5 w-5"} />
                        <span className={"ms-2"}>Done Editing</span>
                    </Button>
                ) : (
                    <Button size={"sm"} className={"px-4"} onClick={() => startEditSession()}>
                        <Edit className={"h-5 w-5"} />
                        <span className={"ms-2"}>Edit</span>
                    </Button>
                )}
            </div>
        </>
    );

    return (
        <div className={"px-8 py-6"}>
            <EditScope>
                <EditUpdater edit={playbookEdit} />
                {content}
            </EditScope>
        </div>
    );
}

export default PlaybookEditPage;
