import React, { PropsWithChildren, ReactElement, useContext } from "react";
import { Badge } from "../../../ui/badge";
import { Checkbox } from "../../../ui/checkbox";
import { Button } from "../../../ui/button";
import { ChevronDown, CodeIcon, Eye, EyeOff } from "lucide-react";
import { ContentItemsView } from "./content/segment-content";
import { SegmentBlueprint, SegmentStyle } from "tekkr-common/dist/model/playbook/segment/blueprint";
import { SegmentEdit } from "tekkr-common/dist/model/playbook/segment/edit";
import { Collapsible, CollapsibleContent } from "../../../ui/collapsible";
import { cn } from "../../../../lib/utils";
import { PlaybookViewControllerContext } from "../playbook-view";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { useScopedEdit } from "../../../hooks/use-edit";
import { TekkrMarkdown } from "../../markdown/tekkr-markdown";
import { config } from "../../../../lib/config";
import YAML, { ToStringOptions } from "yaml";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../../../ui/dialog";
import { CopyButton } from "../../copy-button";
import { useLocalStorage } from "@uidotdev/usehooks";
import { localStorageKeys } from "../../../../lib/local-storage-keys";
import AnimateHeight from "react-animate-height";

type SegmentContainerProps = PropsWithChildren & {
    id: string;
    name: string;
    title: string;
    isEditing: boolean;
    hidden: boolean;
    done: boolean;
    expanded: boolean;
    setDone: (done: boolean) => void;
    setHidden?: (hidden: boolean) => void;
    setExpanded?: (expanded: boolean) => void;
    options?: {
        hideControls?: boolean;
    };
    editActions?: ReactElement[];
};

function SegmentDoneCheckbox(props: { done: boolean; setDone: (done: boolean) => void; hidden: boolean; id: string }) {
    return (
        <div
            className={cn(
                "flex items-center space-x-2 duration-300 animate-in fade-in slide-in-from-right-3",
                props.hidden ? "hidden" : null
            )}
        >
            <Checkbox
                variant={props.done ? "confirmation" : "primary"}
                id={`done-segment-${props.id}`}
                checked={props.done}
                onCheckedChange={(done) => props.setDone(!!done)}
            />
            <label
                htmlFor={`done-segment-${props.id}`}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
                done
            </label>
        </div>
    );
}

export function SegmentContainer(props: SegmentContainerProps) {
    const { children, hidden, done, expanded, setExpanded, setDone, setHidden } = props;
    return (
        <>
            <div style={{ scrollMarginTop: "56pt" }} className={"group relative rounded-lg border bg-background"}>
                <div className={"relative overflow-hidden rounded-lg"}>
                    <div className={cn("flex flex-col gap-4 p-5", hidden ? "opacity-45" : null)}>
                        {!props.options?.hideControls && (
                            <div className={"flex flex-row justify-between"}>
                                <Badge
                                    variant={"mini_accent"}
                                    className={
                                        done && !hidden ? "bg-confirmation text-confirmation-foreground" : undefined
                                    }
                                >
                                    {props.name}
                                </Badge>
                                {props.isEditing ? (
                                    <SegmentDoneCheckbox done={done} setDone={setDone} hidden={hidden} id={props.id} />
                                ) : null}
                            </div>
                        )}
                        {!props.options?.hideControls && (
                            <h3
                                className={cn("flex flex-row", !hidden && setExpanded ? "cursor-pointer" : undefined)}
                                onClick={() => setExpanded?.(!expanded)}
                            >
                                <TekkrMarkdown markdown={props.title} className={"w-auto"} />
                                {!!setExpanded && (
                                    <Button
                                        className={cn("ml-2 h-6 w-6 p-1", hidden ? "hidden" : null)}
                                        style={{ verticalAlign: "middle" }}
                                        variant={"ghost"}
                                        size={"icon"}
                                    >
                                        <ChevronDown
                                            className={"transition-all"}
                                            style={{
                                                transform: expanded ? "rotate(-180deg)" : undefined,
                                            }}
                                        />
                                    </Button>
                                )}
                                <div className={"grow"}></div>
                            </h3>
                        )}
                        <Collapsible open={expanded && !hidden} onOpenChange={setExpanded}>
                            <CollapsibleContent className="space-y-2">
                                {!props.options?.hideControls && <hr className={"-mx-10 border-0 border-b"} />}
                                {children}
                            </CollapsibleContent>
                        </Collapsible>
                    </div>
                    <div
                        className={cn(
                            "absolute bottom-0 top-0 w-1",
                            hidden ? "bg-secondary" : done ? "bg-confirmation" : "bg-primary"
                        )}
                    ></div>
                    {props.isEditing && expanded && (setExpanded || !done) && !props.options?.hideControls ? (
                        <Button
                            className={"w-full rounded-none bg-accent duration-300 animate-in fade-in hover:bg-muted"}
                            onClick={() => setDone(true)}
                            variant={"ghost"}
                        >
                            {done ? "Close" : "Mark as done:"} &quot;
                            {props.title}&quot;
                        </Button>
                    ) : null}
                </div>
                {props.isEditing ? (
                    <div
                        className={
                            "invisible absolute -right-14 top-0 flex w-12 flex-col gap-2 opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100"
                        }
                    >
                        {!!setHidden && (
                            <Button
                                onClick={() => setHidden(!hidden)}
                                size={"sm"}
                                variant={"secondary"}
                                className={"h-8 w-8 p-2 opacity-65"}
                            >
                                {hidden ? <Eye /> : <EyeOff />}
                            </Button>
                        )}
                        {props.editActions}
                    </div>
                ) : null}
            </div>
        </>
    );
}

export function TutorialPlaybookSegment() {
    const [, setDismissed] = useLocalStorage(localStorageKeys.tutorialEditSegmentDismissed);
    const [done, setDone] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);

    const hide = () => {
        setHidden(true);
        setTimeout(() => {
            setDismissed(true);
        }, 500);
    };

    return (
        <AnimateHeight duration={500} height={hidden ? 0 : "auto"} className={"relative"}>
            <div
                className={cn(
                    "absolute left-0 right-0 top-full z-40 h-[200%] bg-gradient-to-b from-background to-transparent transition-all",
                    hidden && "bg-transparent"
                )}
            ></div>
            <div
                className={cn(
                    "relative z-50 mb-8 mt-8 scale-105 rounded-lg shadow-lg transition-all",
                    done ? "shadow-confirmation/10" : "shadow-primary/10"
                )}
            >
                <div className={"absolute -right-14 top-0 w-12"}>
                    {done && (
                        <Button onClick={hide} size={"sm"} variant={"secondary"} className={"h-8 w-8 p-2 opacity-65"}>
                            <EyeOff />
                        </Button>
                    )}
                </div>
                <div
                    className={cn("absolute -right-8 -top-8 flex flex-row gap-2 transition-all", !done && "opacity-0")}
                >
                    <i>If a section doesn't apply to your setup, you can hide it</i>
                    <div className={"rotate-180"}>
                        <div className={"rotate-45 animate-bounce text-nowrap direction-alternate-reverse"}>👆</div>
                    </div>
                </div>
                <div className={"flex flex-row overflow-hidden rounded-lg border"}>
                    <div className={cn("w-1 shrink-0", done ? "bg-confirmation" : "bg-primary")}></div>
                    <div className={"flex grow flex-col gap-4 p-5"}>
                        <div className={"flex flex-row justify-between"}>
                            <Badge variant={done ? "confirmation" : "default"}>Tutorial</Badge>
                            <SegmentDoneCheckbox
                                done={done}
                                setDone={setDone}
                                hidden={false}
                                id={"playbook-tutorial"}
                            />
                        </div>
                        <div className={"flex flex-row justify-between gap-3"}>
                            <h3 className={"shrink-0"}>Example Section</h3>
                            <div
                                className={cn(
                                    "-mt-2 flex max-w-sm flex-row justify-end gap-2 transition-all",
                                    done && "opacity-0"
                                )}
                            >
                                <div className={"text-end italic"}>
                                    Set sections to "done" after editing the content to your needs.
                                </div>
                                <div className={"animate-bounce text-nowrap direction-alternate-reverse"}>
                                    👆 <span className={"invisible"}>done</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AnimateHeight>
    );
}

export default function PlaybookSegment(props: {
    playbookEdit?: PlaybookEdit;
    segment: SegmentBlueprint;
    index: number;
    segmentEdit?: SegmentEdit;
    isEditing: boolean;
    expandedByDefault?: boolean;
}) {
    const [expanded, setExpanded] = React.useState(props.expandedByDefault ?? false);

    const viewController = useContext(PlaybookViewControllerContext);

    if (props.segmentEdit && !props.segmentEdit.content) {
        props.segmentEdit.content = {};
    }

    const { state, updateEdit } = useScopedEdit(props.segmentEdit, (e) => ({
        done: e?.done ?? false,
        hidden: e?.hidden ?? false,
    }));

    function setDone(done: boolean) {
        if (done && expanded) {
            setTimeout(() => viewController.goToSegment(props.index), 100);
            setExpanded(false);
        }
        if (!props.segmentEdit || state.done === done) {
            return;
        }
        updateEdit((e) => {
            e!.done = done;
        });
    }

    function setHidden(hidden: boolean) {
        if (!props.segmentEdit) {
            return;
        }
        updateEdit((e) => {
            e!.hidden = hidden;
        });
        setExpanded(false);
    }

    const content = (
        <ContentItemsView isEditing={props.isEditing} content={props.segment.content} edit={props.segmentEdit} />
    );

    if (props.segment.style === SegmentStyle.plain) {
        return <div className={"my-4"}>{content}</div>;
    } else if (props.segment.style === SegmentStyle.card) {
        return (
            <SegmentContainer
                id={props.segment.id}
                name={props.segment.name}
                title={props.segment.title}
                isEditing={props.isEditing}
                hidden={state.hidden}
                done={state.done}
                expanded={expanded}
                setDone={setDone}
                setHidden={setHidden}
                setExpanded={setExpanded}
                editActions={
                    config.env !== "prod"
                        ? [<TkpbYamlPreviewButton key={"yaml-preview"} blueprint={props.segment} />]
                        : []
                }
            >
                {content}
            </SegmentContainer>
        );
    } else {
        throw new Error(`segment style "${props.segment.style}" is not supported.`);
    }
}

const yamlFormatOptions: ToStringOptions = {};

function TkpbYamlPreviewButton(props: { blueprint: SegmentBlueprint }) {
    const yaml = YAML.stringify(props.blueprint, yamlFormatOptions);
    return (
        <Dialog>
            <DialogContent className={"flex h-[90vh] max-w-5xl flex-col"}>
                <DialogHeader className={"shrink-0"}>
                    <DialogTitle>Segment YAML</DialogTitle>
                    <DialogDescription>
                        This is <b>not</b> exactly the YAML that defines this segment. This YAML is generated from the
                        copy of the playbook that's currently stored in our database. If you want to see the original
                        YAML, check the{" "}
                        <a
                            className={"text-primary underline"}
                            target={"_blank"}
                            href={"https://github.com/tekkr-gmbh/content"}
                            rel="noreferrer"
                        >
                            content repository
                        </a>
                        .
                        <br />
                        You can use this as a starting point but it might need some adjustment.
                    </DialogDescription>
                </DialogHeader>
                <div className={"grow overflow-scroll rounded-md bg-accent p-2 text-sm"}>
                    <code className={"whitespace-pre-wrap"}>{yaml}</code>
                </div>
                <div className={"shrink-0"}>
                    <CopyButton onCopy={() => ({ raw: yaml, html: yaml })} />
                </div>
            </DialogContent>
            <DialogTrigger asChild>
                <Button onClick={() => {}} size={"sm"} variant={"secondary"} className={"h-8 w-8 p-2 opacity-65"}>
                    <CodeIcon />
                </Button>
            </DialogTrigger>
        </Dialog>
    );
}
