import React from "react";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { StagePageContainer } from "./stage-container";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";
import { ArrowRight } from "lucide-react";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import { useBlueprint } from "../controller/hooks/blueprint";

interface StageIntro {
    body: string;
    youWillSteps: string[];
}

const stageIntros: Partial<Record<PlaybookStage, (blueprint: PlaybookBlueprint) => StageIntro>> = {
    [PlaybookStage.targets]: (blueprint) => {
        const youWillSteps: string[] = [];
        if (!blueprint.targetReview.expectationReviews?.length) {
            youWillSteps.push("Set targets to define the purpose of this initiative");
        } else {
            youWillSteps.push("Draft Targets");
            youWillSteps.push("Review and finalize targets with stakeholders");
        }
        if (blueprint.targetReview.communication) {
            youWillSteps.push("Communicate targets to stakeholders");
        }
        return {
            body: "Setting targets is about ensuring you're solving the right problem.\nStart with your hypothesis of what needs to be fixed, then validate it with your stakeholders. Their input will help you understand the problem better and adjust your targets based on real needs.",
            youWillSteps,
        };
    },
    [PlaybookStage.alignment]: () => ({
        body: "Getting everyone on board is crucial for your playbook's success. Understanding concerns and addressing them early builds trust and increases adoption. Your role is to drive this process effectively and create alignment, so that you can proceed to implement the required changes.",
        youWillSteps: [
            "Identify key stakeholders for alignment",
            "Schedule required alignment interactions",
            "Present your playbook and gather feedback",
            "Finalize your Playbook",
        ],
    }),
    [PlaybookStage.implementation]: () => ({
        body: "This is where your playbook comes to life.\nThis phase is also the one in which many initiatives struggle to get to the finish line. Tekkr will help you manage and finish the initiative successfully.",
        youWillSteps: [
            "Surface dependencies",
            "Define todos, due-date and ownership",
            "Communicate todos to contributors",
        ],
    }),
    [PlaybookStage.communication]: () => ({
        body: "Work isn't finished until it's communicated. And the goal of this section is to turn your playbook into lasting change by announcing it and establishing it as your company's new way of working.",
        youWillSteps: [
            "Announce and celebrate the establishment of your playbook",
            "Create and share easy-to-follow guides and support materials",
            "Share appreciation for the different persons involved",
        ],
    }),
    [PlaybookStage.tracking]: () => ({
        body: "Track and quantify your playbook's impact through meaningful metrics.\nThis helps you continuously refine your approach and clearly demonstrates success to stakeholders through tangible results.",
        youWillSteps: ["Track playbook metrics", "Share results with stakeholders"],
    }),
};

export function PlaybookStageIntroPage(props: { stage: PlaybookStage }) {
    const title = playbookStageTitles.upcoming[props.stage];
    const { blueprint } = useBlueprint();
    const intro = stageIntros[props.stage]!(blueprint);

    return (
        <StagePageContainer header={title}>
            <p className={"mt-4 font-semibold"}>{intro.body}</p>
            <h2 className={"mt-6"}>In this step you will:</h2>
            <div className={"flex flex-col gap-2"}>
                {intro.youWillSteps.map((s) => (
                    <div key={s} className={"flex flex-row items-center gap-3"}>
                        <ArrowRight className={"h-4 w-4"} />
                        <p>{s}</p>
                    </div>
                ))}
            </div>
            <br />
            <br />
        </StagePageContainer>
    );
}
