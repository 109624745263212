"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookAlignmentEditSchema = exports.playbookAlignmentBlueprintSchema = exports.AlignmentReviewMode = void 0;
const zod_1 = require("zod");
const schema_refinements_1 = require("../../../utils/schema-refinements");
const edit_1 = require("../segment/content/types/editable-text/edit");
const edit_2 = require("../segment/content/types/select/edit");
var AlignmentReviewMode;
(function (AlignmentReviewMode) {
    AlignmentReviewMode["async"] = "async";
    AlignmentReviewMode["groupMeeting"] = "group_meeting";
    AlignmentReviewMode["individualMeetings"] = "individual_meetings";
})(AlignmentReviewMode || (exports.AlignmentReviewMode = AlignmentReviewMode = {}));
const playbookAlignmentReviewSchema = zod_1.z.strictObject({
    id: zod_1.z.string().regex(schema_refinements_1.formats.kebabCaseRegex),
    title: zod_1.z.string(),
    navigationTitle: zod_1.z.string(),
    description: zod_1.z.string().optional(),
    peopleGroup: zod_1.z.string(),
    recommendedMode: zod_1.z
        .nativeEnum(AlignmentReviewMode)
        .optional()
        .describe(`decides which mode is the recommended option (this will be highlighted in the app). Default is \`${AlignmentReviewMode.async}.\``),
});
const playbookAlignmentRoastingSchema = zod_1.z.strictObject({
    id: zod_1.z.string().regex(schema_refinements_1.formats.kebabCaseRegex),
    defaultDeadlineDays: zod_1.z.number().int().min(1),
});
exports.playbookAlignmentBlueprintSchema = zod_1.z.strictObject({
    playbookReviews: zod_1.z.array(playbookAlignmentReviewSchema).optional(),
    roastings: zod_1.z.array(playbookAlignmentRoastingSchema).optional(),
});
const playbookAlignmentReviewEditSchema = zod_1.z.strictObject({
    done: zod_1.z.boolean().optional(),
    mode: zod_1.z.nativeEnum(AlignmentReviewMode).optional(),
    asyncMessage: zod_1.z.object({
        message: edit_1.editableTextContentEditSchema,
        timeline: edit_2.selectContentEditSchema,
    }),
    groupMeetingDurationEdit: edit_2.selectContentEditSchema,
    individualMeetingsDurationEdit: edit_2.selectContentEditSchema,
    groupMeetingScheduled: zod_1.z.boolean().optional(),
    scheduledIndividualMeetings: zod_1.z.array(zod_1.z.string()).optional(),
});
const playbookAlignmentRoastingEditSchema = zod_1.z.strictObject({
    done: zod_1.z.boolean().optional(),
    started: zod_1.z.boolean().optional(),
    deadline: zod_1.z.string().optional(),
    asyncMessageEdit: edit_1.editableTextContentEditSchema,
});
exports.playbookAlignmentEditSchema = zod_1.z.strictObject({
    playbookReviews: zod_1.z.record(zod_1.z.string(), playbookAlignmentReviewEditSchema),
    reviewsCompleted: zod_1.z.boolean().optional(),
    roastings: zod_1.z.record(zod_1.z.string(), playbookAlignmentRoastingEditSchema),
});
