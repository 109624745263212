import { cn } from "../../lib/utils";
import React from "react";

export default function LoadingCard(props: { className?: string }) {
    return (
        <div className={"rounded-lg border bg-popover p-4 text-center opacity-45"}>
            <p className={cn("font-semibold text-muted-foreground", props.className)}>loading ...</p>
        </div>
    );
}
