import { cn, getInitialsForName } from "../../../lib/utils";
import { Building, Lock, LockIcon, LockOpen, Users2 } from "lucide-react";
import Rating from "../rating";
import { Link } from "react-router-dom";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { Button } from "../../ui/button";
import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { formats } from "tekkr-common/dist/utils/formatting";
import { PlaybookCategoryBadges } from "../playbook-category-badges";
import { useGlobalDialogs } from "../../../modals/global-dialogs";
import { useOrg } from "../../../auth/org-provider";

export type BlueprintMeta = ClientInferResponseBody<typeof apiContract.listBlueprints, 200>["data"][0];

interface BlueprintCardProps {
    layout: "vertical" | "horizontal";
    variant?: "compact";
    data: BlueprintMeta;
    linkTo?: string;
}

function BlueprintCard(props: BlueprintCardProps) {
    const globalDialogs = useGlobalDialogs();
    const org = useOrg();
    const limitedAccess = !("unrestricted" in org.playbookAccess);
    const locked = props.data.access !== "access";
    const card = (
        <div
            data-testid="blueprint-card"
            className={cn(
                "group relative overflow-hidden rounded-lg border bg-background p-4 transition-all duration-300 animate-in fade-in slide-in-from-bottom-3 hover:bg-accent",
                props.layout === "vertical" ? "min-w-48" : null
            )}
        >
            <div className={cn("flex gap-4", props.layout === "horizontal" ? "flex-row" : "flex-col")}>
                <div
                    className={cn(
                        "relative aspect-3/2 overflow-hidden rounded-lg bg-accent",
                        props.layout === "horizontal" ? "w-1/3 shrink-0" : null
                    )}
                >
                    <img
                        alt={"Playbook Cover"}
                        className={"h-full w-full border-0 object-cover"}
                        src={props.data.imageUrl}
                    />
                    {limitedAccess && !locked && (
                        <div
                            className={
                                "absolute left-0 right-0 top-0 flex h-5 w-full flex-row items-center justify-center overflow-hidden text-nowrap bg-primary text-center text-xs font-bold tracking-wider text-white"
                            }
                        >
                            INCLUDED
                        </div>
                    )}
                </div>
                <div className={cn("flex flex-col gap-2", props.layout === "vertical" ? "h-80" : undefined)}>
                    <div>
                        <PlaybookCategoryBadges categories={props.data.categories} />
                        <h4 className={cn("mt-2 line-clamp-3")}>{props.data.title}</h4>
                    </div>
                    <div className={"flex flex-row items-center gap-2"}>
                        <Rating rating={props.data.rating.score}></Rating>
                        <div className={"max-h-4 overflow-hidden text-xs text-muted-foreground"}>
                            {formats.friendly.rating(props.data.rating)} (used&nbsp;by&nbsp;
                            {props.data.rating.reviewCount}
                            &nbsp;companies)
                        </div>
                    </div>
                    {props.variant !== "compact" && (
                        <div className={"flex flex-row items-center"}>
                            <span className={"text-muted-foreground"}>By</span>
                            &nbsp;&nbsp;
                            <Avatar className={"inline-block h-5 w-5"}>
                                <AvatarImage src={props.data.author.imageUrl}></AvatarImage>
                                <AvatarFallback>{getInitialsForName(props.data.author.name)}</AvatarFallback>
                            </Avatar>
                            &nbsp;
                            <span>{props.data.author.name}</span>
                        </div>
                    )}
                    {props.variant !== "compact" && (
                        <p className={"line-clamp-5 shrink overflow-hidden overflow-ellipsis"}>{props.data.summary}</p>
                    )}
                    <div className={"grow"}></div>
                    <hr />
                    <div className={"my-1 flex flex-row gap-4"}>
                        <div className={"flex flex-row items-center gap-1"}>
                            <Users2 className={"h-4 w-4 text-muted-foreground"} />
                            <span className={"text-sm text-muted-foreground"}>
                                {formats.friendly.teamSizeApplicability(props.data.teamSize)}
                            </span>
                        </div>
                        <div className={"flex flex-row items-center gap-1"}>
                            <Building className={"h-4 w-4 text-muted-foreground"} />
                            <span className={"text-sm text-muted-foreground"}>
                                {formats.friendly.companyStageApplicability(props.data.companyStages)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {locked ? (
                <div
                    className={cn(
                        "absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center gap-4 bg-background/80 p-8 opacity-0 backdrop-blur duration-200 group-hover:opacity-100",
                        props.variant === "compact" && "gap-2 text-sm"
                    )}
                >
                    {props.variant !== "compact" && <Lock />}
                    <h3 className={cn("text-center", props.variant === "compact" && "text-base")}>
                        Get access to all playbooks
                    </h3>
                    <p className={"max-w-sm text-center"}>
                        Unlock your tech team's potential using Tekkr's expert-curated, actionable playbooks.
                    </p>
                    <Button className={"transition-all duration-300 hover:drop-shadow-glow-primary"}>
                        <LockOpen className={"mr-2 h-5 w-5"} />
                        Unlock now
                    </Button>
                </div>
            ) : null}
        </div>
    );

    if (!locked) {
        return <Link to={props.linkTo ?? `/blueprint/${props.data.id}`}>{card}</Link>;
    } else {
        return (
            <div onClick={() => globalDialogs?.showUnlockAllPlaybooksCta()} className={"cursor-pointer"}>
                {card}
            </div>
        );
    }
}

export default BlueprintCard;
