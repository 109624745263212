import { Progress } from "../../../ui/progress";
import React, { useState } from "react";
import { useCurrentOrgUserOptional, useOrg } from "../../../../auth/org-provider";
import { useColorScheme } from "../../../../lib/color-scheme";
import { computePlaybookEditProgress } from "tekkr-common/dist/model/playbook/state";
import { usePlaybook } from "../../../../pages/playbook/controller/hooks/playbook";
import { useBlueprint } from "../../../../pages/playbook/controller/hooks/blueprint";
import { DateTime } from "luxon";
import { AvatarForUser } from "../../avatar-for-user";
import { cn } from "../../../../lib/utils";
import { ChevronDown } from "lucide-react";
import { SelectPlaybookDriverDialog } from "../../../../modals/select-playbook-driver";
import { DialogTrigger } from "../../../ui/dialog";
import { UserDropdown } from "../../user-dropdown";
import { PopoverTrigger } from "../../../ui/popover";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../../../service/tekkr-service";
import ConfirmationDialog, { ConfirmationDialogConfig } from "../../../../modals/confirmation-dialog";

function Stat(props: React.PropsWithChildren<{ title: string }>) {
    return (
        <div className={"flex flex-col gap-2"}>
            <div className={"text-xs font-medium text-muted-foreground"}>{props.title}</div>
            {props.children}
        </div>
    );
}

const noPersonContent = (
    <>
        <div className={"h-6 w-6 rounded-full bg-accent text-center text-muted-foreground"}>-</div>
        <span className={"text-sm text-muted-foreground"}>None</span>
    </>
);

function Person(props: { userId?: string; isEditing: boolean }) {
    const org = useOrg();
    const user = org.users.find((u) => u.id === props.userId)!;
    return (
        <div
            className={cn(
                "flex flex-row items-center gap-2 rounded-md transition-all",
                props.isEditing && "cursor-pointer bg-input px-2 py-1.5 hover:bg-secondary/80"
            )}
        >
            {!props.userId ? (
                noPersonContent
            ) : (
                <>
                    <AvatarForUser user={user} className={"h-6 w-6"} />
                    <span className={"text-sm"}>{user!.name}</span>
                </>
            )}
            <ChevronDown className={cn("h-3 w-3 opacity-65 transition-all", props.isEditing ? "ms-1" : "w-0")} />
        </div>
    );
}

interface Props {
    isEditing: boolean;
}

const confirmationBody = (
    <>
        <p>Are you sure you want to change the owner of this playbook?</p>
        <p className={"text-sm font-medium text-muted-foreground"}>
            You cannot undo this change - only the new owner can transfer ownership again.
        </p>
    </>
);

function DriverStat(props: { isEditing: boolean; isOwner: boolean }) {
    const { playbook, refetch: refetchPlaybook } = usePlaybook();
    const [selectDriverDialogOpen, setSelectDriverDialogOpen] = useState(false);
    const onDriverUpdated = () => {
        refetchPlaybook();
    };

    if (!props.isOwner) {
        return (
            <Stat title={"Driver"}>
                <Person isEditing={props.isEditing} userId={playbook.contributors.driver} />
            </Stat>
        );
    }

    return (
        <Stat title={"Driver"}>
            <SelectPlaybookDriverDialog
                playbookId={playbook.id}
                playbookOwner={playbook.contributors.owner}
                currentDriver={playbook.contributors.driver ?? null}
                open={selectDriverDialogOpen}
                onDriverUpdated={onDriverUpdated}
                onOpenChange={setSelectDriverDialogOpen}
            >
                <DialogTrigger disabled={!props.isEditing}>
                    <Person isEditing={props.isEditing} userId={playbook.contributors.driver} />
                </DialogTrigger>
            </SelectPlaybookDriverDialog>
        </Stat>
    );
}

function OwnerStat(props: { isEditing: boolean; isOwner: boolean }) {
    const org = useOrg();
    const { playbook, refetch: refetchPlaybook } = usePlaybook();

    const [userIdToConfirm, setUserIdToConfirm] = useState<string | null>(null);

    const mutation = useMutation({
        mutationFn: async (userId: string) => {
            await apiClient.updatePlaybookContributors({
                params: {
                    playbookId: playbook.id,
                },
                body: {
                    owner: userId,
                },
            });
            await refetchPlaybook();
            setUserIdToConfirm(null);
        },
    });

    const onUserSelected = (id: string) => {
        if (id === playbook.contributors.owner) {
            // same user as before
            return;
        }
        setUserIdToConfirm(id);
    };

    if (!props.isOwner) {
        return (
            <Stat title={"Owner"}>
                <Person isEditing={props.isEditing} userId={playbook.contributors.owner} />
            </Stat>
        );
    }

    const confirmChangeOwnerDialog: ConfirmationDialogConfig = {
        title: "Change Playbook Owner?",
        body: confirmationBody,
        confirmButtonText: "Change Owner",
        confirm: async () => {
            await mutation.mutateAsync(userIdToConfirm!)
        },
    };

    return (
        <Stat title={"Owner"}>
            <ConfirmationDialog
                {...confirmChangeOwnerDialog}
                onClose={() => setUserIdToConfirm(null)}
                open={!!userIdToConfirm}
            >
                <UserDropdown users={org.users} onSelect={onUserSelected}>
                    <PopoverTrigger disabled={!props.isEditing}>
                        <Person isEditing={props.isEditing} userId={playbook.contributors.owner} />
                    </PopoverTrigger>
                </UserDropdown>
            </ConfirmationDialog>
        </Stat>
    );
}

export default function PlaybookStats(props: Props) {
    const { playbook } = usePlaybook();
    const { blueprint } = useBlueprint();
    const org = useOrg();
    const { isDark } = useColorScheme();

    const orgUser = useCurrentOrgUserOptional();
    const isOwner = !!orgUser && orgUser.id === playbook.contributors.owner;

    const progress = computePlaybookEditProgress(blueprint, playbook?.edit);
    const isEditing = props.isEditing && isOwner;

    return (
        <div className={"duration-300 animate-in fade-in slide-in-from-bottom-4"}>
            <hr />
            <div className={"mt-3 flex flex-row justify-between"}>
                <div>
                    {org.logo && (
                        <img alt={"org logo"} className={"h-10"} src={isDark ? org.logo.urlWhite : org.logo.urlBlack} />
                    )}
                </div>
                <div className={"flex flex-col items-end gap-2"}>
                    <Progress value={progress * 100} className={"h-2.5 w-24"} color={"confirmation"}></Progress>
                    <div className={"text-muted-foreground"}>
                        {progress === 0 ? "Not started" : Math.round(progress * 100) + "%"}
                    </div>
                </div>
            </div>
            <div className={cn("flex flex-row gap-8 py-4 transition-all", isEditing && "gap-6")}>
                <OwnerStat isEditing={isEditing} isOwner={isOwner} />
                <DriverStat isEditing={isEditing} isOwner={isOwner} />
                <Stat title={"Created on"}>
                    <span className={"text-sm"}>
                        {DateTime.fromISO(playbook.createdAt).toLocaleString({
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                        })}
                    </span>
                </Stat>
            </div>
        </div>
    );
}
