import { Popover, PopoverContent } from "../ui/popover";
import { PlusCircle } from "lucide-react";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../ui/command";
import { AvatarForUser } from "./avatar-for-user";
import CreatePersonDialog from "../../modals/create-person-dialog";
import { DialogTrigger } from "../ui/dialog";
import React from "react";

interface Person {
    id: string;
    name: string;
    title?: string;
}

interface Props {
    users: Person[];
    onSelect?: (id: string) => void;
}

export function UserDropdown(props: React.PropsWithChildren<Props>) {
    const [open, setOpen] = React.useState(false);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            {props.children}
            <PopoverContent className="w-[200px] p-0">
                <Command>
                    <CommandInput placeholder="Search..." />
                    <CommandList>
                        <CommandEmpty>Nothing found.</CommandEmpty>
                        <CommandGroup>
                            {props.users.map((person) => (
                                <CommandItem
                                    className={"cursor-pointer flex-col items-start"}
                                    key={person.id}
                                    value={person.name + " " + (person.title ?? "")}
                                    onSelect={() => {
                                        setOpen(false);
                                        props.onSelect?.(person.id);
                                    }}
                                >
                                    <div className={"flex flex-row items-center gap-2"}>
                                        <AvatarForUser user={person} className="h-6 w-6" />
                                        <div>
                                            {person.name}
                                            <br />
                                            <div className={"text-xs text-muted-foreground"}>{person.title}</div>
                                        </div>
                                    </div>
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                    <hr />
                    <CommandGroup>
                        <CreatePersonDialog
                            context={"playbook"}
                            onPersonCreated={(id) => {
                                props.onSelect?.(id);
                                setOpen(false);
                            }}
                        >
                            <DialogTrigger>
                                <CommandItem className={"cursor-pointer gap-3"}>
                                    <PlusCircle className={"h-4 w-4"} />
                                    <div>Add Person</div>
                                </CommandItem>
                            </DialogTrigger>
                        </CreatePersonDialog>
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
