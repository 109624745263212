import { Checkbox } from "../../../../../ui/checkbox";
import React, { useState } from "react";

import { useIdentifierList } from "../../../../../hooks/set-list";
import { ChecklistContentBlueprint } from "tekkr-common/dist/model/playbook/segment/content/types/checklist/blueprint";
import { RadioGroup, RadioGroupItem } from "../../../../../ui/radio-group";
import { Button } from "../../../../../ui/button";
import { X, Link as LinkIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { TekkrMarkdown } from "../../../../markdown/tekkr-markdown";
import { SegmentContentViewProps } from "../segment-content";
import { useScopedEdit } from "../../../../../hooks/use-edit";
import { cn } from "../../../../../../lib/utils";

type Item = ChecklistContentBlueprint["items"][0];

function ListItemContent(props: { item: Item; onClick?: () => void; isEditing: boolean }) {
    return (
        <div>
            <div className={"flex flex-col items-start"}>
                {props.item.link ? (
                    <Link target={"_blank"} to={props.item.link}>
                        <div>
                            <span className={"underline"}>{props.item.title}</span>
                            <LinkIcon className={"ms-2 inline h-3 w-3 opacity-65"} />
                        </div>
                    </Link>
                ) : (
                    <div onClick={props.onClick} className={cn(props.isEditing && "cursor-pointer")}>
                        <TekkrMarkdown markdown={props.item.title} />
                    </div>
                )}

                {props.item.description ? (
                    <TekkrMarkdown className={"text-sm text-muted-foreground"} markdown={props.item.description} />
                ) : null}
            </div>
        </div>
    );
}

function CustomItemInput(props: { onAddItem: (name: string) => void; mode: "radio" | "box" }) {
    const [title, setTitle] = useState("");
    const addItem = (title: string) => {
        props.onAddItem(title.trim());
        setTitle("");
    };
    return (
        <div className={"flex flex-row items-center space-x-2 ps-2"}>
            {props.mode === "box" ? <Checkbox checked={false} className={"mt-1"} disabled={true} /> : null}
            {props.mode === "radio" ? <RadioGroupItem disabled={true} key={"$$input$$"} value={"$$input$$"} /> : null}
            <input
                className={"ms-1.5 min-w-56 max-w-full border-b border-b-accent bg-background !outline-none"}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={"add item"}
            ></input>
            <Button
                className={"ms-1.5 text-sm"}
                size={"sm2"}
                variant={"outline"}
                disabled={title.trim().length < 2}
                onClick={() => addItem(title)}
            >
                add
            </Button>
        </div>
    );
}

export default function ListSegmentContentView({
    content,
    edit,
    isEditing,
}: SegmentContentViewProps<ChecklistContentBlueprint>) {
    const { state, updateEdit } = useScopedEdit(edit, (e) => ({
        customItems: e?.customItems ?? [],
    }));

    const [selected, add, remove, replace] = useIdentifierList(
        edit && "selectedItems" in edit ? edit.selectedItems : [],
        (selected) => {
            if (edit) {
                updateEdit(() => {
                    edit.selectedItems = selected;
                });
            }
        }
    );

    const head = (
        <div className={"mb-2"}>
            {content.title ? <h4>{content.title}</h4> : null}
            {content.description ? <p>{content.description}</p> : null}
            {isEditing && content.prompt && (
                <div className={"mb-1 mt-2 text-muted-foreground duration-300 animate-in fade-in"}>
                    <TekkrMarkdown markdown={content.prompt} />
                </div>
            )}
        </div>
    );

    function addCustomItem(title: string) {
        if (edit) {
            updateEdit(() => {
                edit.customItems = [...(edit.customItems ?? []), { title }];
            });
        }
    }
    function removeCustomItem(index: number) {
        if (edit && edit.customItems) {
            const ci = edit.customItems;
            updateEdit(() => {
                edit.customItems = [...ci.slice(0, index), ...ci.slice(index + 1)];
            });
        }
    }

    if (!isEditing) {
        const items = content.items;
        if (items.length === 0) {
            return <></>;
        }
        let selectedItems = items;
        if (edit) {
            selectedItems = items.filter((i) => selected.includes(i.id));
        }
        const isSingleModeCustom = content.singleMode && (edit?.customItems?.length ?? 0) > 0;
        if (isSingleModeCustom) {
            selectedItems = [];
        }
        return (
            <div>
                {head}
                <ul>
                    {selectedItems.map((item) => (
                        <li key={item.id} className={"mt-1 flex flex-row ps-2"}>
                            &bull;&nbsp;&nbsp;
                            <ListItemContent item={item} isEditing={isEditing} />
                        </li>
                    ))}
                    {edit?.customItems?.map((item, index) => (
                        <li key={index} className={"mt-1 flex flex-row ps-2"}>
                            &bull;&nbsp;&nbsp;
                            <ListItemContent item={item as Item} isEditing={isEditing} />
                        </li>
                    ))}
                </ul>
                {selectedItems.length === 0 && (edit?.customItems?.length ?? 0) === 0 && (
                    <span className={"text-sm font-semibold text-muted-foreground"}>none selected</span>
                )}
            </div>
        );
    } else {
        const options = content.singleMode ? (
            <RadioGroup
                value={state.customItems.length > 0 ? "$$custom$$" : selected[0]}
                onValueChange={(e) => replace([e])}
                className={"mt-2"}
            >
                {content.items.map((item) => (
                    <div key={item.id} className="ms-2 flex items-center space-x-2">
                        <RadioGroupItem disabled={state.customItems.length > 0} value={item.id} />
                        <ListItemContent item={item} isEditing={isEditing} onClick={() => replace([item.id])} />
                    </div>
                ))}
                {edit?.customItems?.map((item) => (
                    <div key={item.title} className="ms-2 flex items-center space-x-2">
                        <RadioGroupItem key={"$$custom$$"} value={"$$custom$$"} />
                        <ListItemContent item={item as Item} isEditing={isEditing} />
                        <Button onClick={() => removeCustomItem(0)} variant={"ghost"} className={"h-6 w-6 p-0.5"}>
                            <X />
                        </Button>
                    </div>
                ))}
                {content.extendable && state.customItems.length === 0 ? (
                    <CustomItemInput mode={"radio"} onAddItem={(item) => addCustomItem(item)} />
                ) : null}
            </RadioGroup>
        ) : (
            <>
                {content.items.map((item) => {
                    const onCheckedChange = (checked: boolean) => (checked ? add(item.id) : remove(item.id));
                    const checked = selected.includes(item.id);
                    return (
                        <div key={item.id} className="my-2 flex items-start space-x-2 ps-2">
                            <Checkbox checked={checked} className={"mt-1"} onCheckedChange={onCheckedChange} />
                            <ListItemContent
                                item={item}
                                isEditing={isEditing}
                                onClick={() => onCheckedChange(!checked)}
                            />
                        </div>
                    );
                })}
                {state.customItems.map((item, index) => (
                    <div key={index} className="my-2 flex items-start space-x-2 ps-2">
                        <Checkbox checked={true} className={"mt-1"} disabled={true} />
                        <ListItemContent item={item as Item} isEditing={isEditing} />
                        <Button onClick={() => removeCustomItem(index)} variant={"ghost"} className={"h-6 w-6 p-0.5"}>
                            <X />
                        </Button>
                    </div>
                ))}
                {content.extendable && <CustomItemInput mode={"box"} onAddItem={(item) => addCustomItem(item)} />}
            </>
        );
        return (
            <div>
                {head}
                {options}
            </div>
        );
    }
}
