"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaybookWrapper = void 0;
const blueprint_1 = require("../model/playbook/segment/content/blueprint");
const blueprint_wrapper_1 = require("./blueprint-wrapper");
class PlaybookWrapper extends blueprint_wrapper_1.BlueprintWrapper {
    playbook;
    blueprint;
    constructor(playbook, blueprint) {
        super(blueprint);
        this.playbook = playbook;
        this.blueprint = blueprint;
    }
    getTitle() {
        return this.playbook.edit?.title ?? this.blueprint.title;
    }
    getSegmentAndEdit(segmentId) {
        const segment = this.blueprint.segments.find((s) => s.id === segmentId);
        if (!segment) {
            throw new Error(`segment with id ${segmentId} does not exist in blueprint ${this.blueprint.id}`);
        }
        const edit = this.playbook.edit?.segments?.[segmentId];
        return { segment, edit };
    }
    getContentAndEdit(segmentId, contentId, type) {
        const { segment } = this.getSegmentAndEdit(segmentId);
        const content = segment.content.find((c) => "id" in c && c.type === type && c.id === contentId);
        if (!segment) {
            throw new Error(`content with id ${content} and type ${type} does not exist in segment ${segmentId} of blueprint ${this.blueprint.id}`);
        }
        const edit = this.playbook.edit?.segments?.[segmentId]?.content?.[contentId];
        return {
            content: content,
            edit: edit,
        };
    }
    forEachContentWhere(filter, visitor) {
        super.forEachContentWhere(filter, ({ segment, content }) => {
            const segmentEdit = this.playbook.edit?.segments?.[segment.id];
            const contentEdit = "id" in content && content.id ? segmentEdit?.content?.[content.id] : undefined;
            visitor({
                segment,
                content,
                segmentEdit,
                contentEdit,
            });
        });
    }
}
exports.PlaybookWrapper = PlaybookWrapper;
