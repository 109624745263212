import React, { HTMLProps } from "react";
import { cn } from "../../lib/utils";

export function TekkrGuidanceContainer(props: React.PropsWithChildren<HTMLProps<HTMLDivElement>>) {
    return (
        <div className={cn("relative rounded-md border border-primary bg-primary/10 px-4 pb-4 pt-5", props.className)}>
            <div className={"absolute -top-2.5 h-5 rounded-md bg-primary px-2 py-0.5 text-xs font-semibold text-white"}>
                Guidance from Tekkr
            </div>
            {props.children}
        </div>
    );
}
