import { FrownIcon, LucideIcon, OctagonXIcon, Satellite, ServerCrashIcon } from "lucide-react";
import HelpFooter from "../../components/shared/help-footer";
import React from "react";

function ErrorPageContent(props: { title: string; subtitle: string; message: string; icon?: LucideIcon }) {
    const Icon = props.icon ?? Satellite;
    return (
        <div className={"flex flex-col items-center gap-3 p-8 text-center"}>
            <Icon className={"h-10 w-10"} />
            <h1>{props.title}</h1>
            <p>{props.subtitle}</p>
            <p className={"max-w-xl text-muted-foreground"}>{props.message}</p>
            <br />
            <br />
            <HelpFooter variant={"secondary"} />
        </div>
    );
}

export function NoAccessErrorPageContent() {
    return (
        <ErrorPageContent
            icon={OctagonXIcon}
            title={"You don't have access to this."}
            subtitle={"We're flattered that you really want to see it, but:"}
            message={
                "You or your organization don't seem to have access to this resource that you're trying to access. If you think this is wrong, please contact us."
            }
        />
    );
}

export function NotFoundErrorPageContent() {
    return (
        <ErrorPageContent
            title={"404 - Houston, we have a problem."}
            subtitle={"Looks like you've launched into the unknown."}
            message={
                "We can’t seem to find the page you’re looking for. Maybe it’s hiding out on the dark side of the moon, or perhaps a tiny asteroid got in the way! No worries, we’ll guide you back on course."
            }
        />
    );
}

export function InternalErrorPageContent() {
    return (
        <ErrorPageContent
            icon={ServerCrashIcon}
            title={"Oops, something went wrong!"}
            subtitle={"Our systems are currently orbiting chaos."}
            message={
                "It seems our servers have gone off course. Maybe they’re taking an unexpected detour through a wormhole! Don’t worry, we’re working hard to get everything back on track."
            }
        />
    );
}
