import React, { useCallback } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../service/tekkr-service";
import { usePlaybook } from "../pages/playbook/controller/hooks/playbook";
import Spinner from "../components/ui/spinner";
import { useDropzone } from "react-dropzone";
import { Cross, Download, HandIcon, ImageIcon, XIcon } from "lucide-react";
import { cn } from "../lib/utils";

type Props = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onImageUploaded?: () => void;
};

const dropzoneContent = {
    default: (
        <>
            <Download />
            <p className={"text-medium"}>Drag-and-drop an image here or click to select.</p>
        </>
    ),
    invalid: (
        <>
            <HandIcon />
            <p className={"text-medium"}>Invalid file type. Please select an image file.</p>
        </>
    ),
    active: (
        <>
            <Download className={"animate-bounce text-primary"} />
            <p className={"text-medium text-primary"}>Drop image here.</p>
        </>
    ),
};

export function UploadPlaybookImageDialog(props: React.PropsWithChildren<Props>) {
    const { playbook } = usePlaybook();
    const [file, setFile] = React.useState<File | null>(null);

    const { mutate: upload, isPending } = useMutation({
        mutationFn: async () => {
            if (!file) {
                throw new Error("no file selected");
            }
            await apiClient.replacePlaybookImage({
                params: {
                    playbookId: playbook.id,
                },
                body: {
                    image: file,
                },
            });
            props.onOpenChange(false);
            props?.onImageUploaded?.();
        },
    });

    const onDrop = useCallback((files: File[]) => {
        const [f] = files;
        setFile(f);
    }, []);
    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: { "image/*": [] },
    });

    let dropzoneView = dropzoneContent.default;
    if (isDragActive) {
        dropzoneView = isDragReject ? dropzoneContent.invalid : dropzoneContent.active;
    }

    return (
        <Dialog open={props.open || isPending} onOpenChange={!isPending ? props.onOpenChange : undefined}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Replace Playbook Image</DialogTitle>
                    <DialogDescription>Upload an image here, to customize the look of your playbook.</DialogDescription>
                </DialogHeader>
                {isPending && (
                    <div className={"flex flex-col items-center gap-2"}>
                        <Spinner />
                        Uploading Image...
                    </div>
                )}

                {!isPending && (
                    <>
                        <div
                            {...getRootProps()}
                            className={cn(
                                "flex h-32 w-full cursor-pointer flex-col items-center justify-center gap-3 rounded-lg p-6 outline-dotted outline-muted-foreground transition-all hover:bg-accent",
                                isDragActive && "bg-primary/20 outline-primary",
                                isDragReject && "bg-destructive/20 text-destructive outline-destructive"
                            )}
                        >
                            <input {...getInputProps()} accept="image/*" />
                            {(!file || isDragActive) && dropzoneView}
                            {file && !isDragActive && (
                                <div className={"flex w-full flex-row gap-4"}>
                                    <img
                                        className={"h-24 w-24 rounded-md object-cover"}
                                        alt={"Image Preview"}
                                        src={URL.createObjectURL(file)}
                                    />
                                    <div className={"flex h-full grow flex-col justify-center"}>
                                        <div className={"line-clamp-1 flex flex-row items-center font-semibold"}>
                                            <ImageIcon className={"me-2 h-4 w-4"} />
                                            {file.name}
                                        </div>
                                        <div className={"text-sm text-muted-foreground"}>
                                            Drop a different file or click to replace.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {file && <Button onClick={() => upload()}>Upload</Button>}
                    </>
                )}
            </DialogContent>
            {props.children}
        </Dialog>
    );
}
