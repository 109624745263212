import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { BotIcon, CheckIcon } from "lucide-react";

function capitalizeFirstLetter(val: string): string {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export function TekkrEmailBotDisclaimerDialog(props: { trackerAddress: string; onDismiss: () => void }) {
    const name = capitalizeFirstLetter(props.trackerAddress.split("-")[0]);
    return (
        <Dialog open={true}>
            <DialogContent disableClose={true}>
                <div className={"flex flex-col items-center p-8"}>
                    <div
                        className={
                            "flex scale-105 flex-row items-center gap-2 rounded-sm bg-accent px-2 py-1 text-sm font-semibold drop-shadow-glow-primary"
                        }
                    >
                        <div className={"flex h-6 w-6 items-center justify-center rounded-full bg-background"}>
                            {name.slice(0, 1)}
                        </div>
                        Tekkr Bot &lt;{props.trackerAddress}&gt;
                    </div>
                </div>

                <DialogHeader className={"gap-2"}>
                    <DialogTitle className={"flex flex-row items-center justify-center gap-2 text-xl"}>
                        <BotIcon />
                        We're inviting our bot {name}!
                    </DialogTitle>
                </DialogHeader>
                <span className={"text-center"}>
                    {name} and other Tekkr bots will keep track of the meetings you schedule for your playbooks. This
                    way, Tekkr can track progress and show you the meetings you have already scheduled.
                </span>
                <div className={"mt-4 flex flex-col items-center"}>
                    <Button onClick={props.onDismiss} className={"px-8"}>
                        <CheckIcon className={"me-2 h-5 w-5"} /> Got It
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
