import { IdentifierListInterface } from "../../../../components/hooks/set-list";
import { createContext, useContext } from "react";
import { useContentEditingNotifier } from "../callbacks";
import { PlaybookPeopleGroups } from "tekkr-common/dist/model/playbook/people-groups";
import { useOrgOptional } from "../../../../auth/org-provider";
import { OrgUser } from "../../../../service/tekkr-service-types";

type PeopleGroupsContext = {
    peopleGroups?: PlaybookPeopleGroups;
    updatePeopleGroup: (id: string, userIds: string[]) => void;
};

export const PeopleGroupsContext = createContext<PeopleGroupsContext | null>(null);

type HookReturnType = {
    byId: (id: string) => IdentifierListInterface;
    getOrgUsersInPeopleGroups: (groups: string[]) => OrgUser[];
};

const errop = () => {
    throw new Error("not supported");
};

// used when there is no context is available (to not break blueprint reading mode)
const dummyHookReturn: HookReturnType = {
    byId(): IdentifierListInterface {
        return [[], errop, errop, errop];
    },
    getOrgUsersInPeopleGroups(): OrgUser[] {
        return [];
    },
};

export function usePlaybookPeopleGroups(): HookReturnType {
    const ctx = useContext(PeopleGroupsContext);
    const notifier = useContentEditingNotifier();
    const org = useOrgOptional();

    if (!ctx) {
        return dummyHookReturn;
    }

    const { peopleGroups, updatePeopleGroup } = ctx;

    return {
        byId: (id: string) => {
            const list = peopleGroups?.[id] ?? [];
            // this is a drop-in replacement for useIdentifierList
            return [
                list,
                (userId: string) => {
                    updatePeopleGroup(id, [...list, userId]);
                    notifier?.onPeopleGroupsUpdated?.();
                },
                (userId: string) => {
                    updatePeopleGroup(
                        id,
                        list.filter((u) => u !== userId)
                    );
                    notifier?.onPeopleGroupsUpdated?.();
                },
                (replacement: string[]) => {
                    updatePeopleGroup(id, replacement);
                    notifier?.onPeopleGroupsUpdated?.();
                },
            ];
        },
        getOrgUsersInPeopleGroups: (groups: string[]): OrgUser[] => {
            const all = new Set<string>();
            groups.forEach((g) => {
                peopleGroups?.[g]?.forEach((userId) => {
                    all.add(userId);
                });
            });
            if (!org) {
                throw new Error("no org in context");
            }
            return org.users.filter((u) => all.has(u.id));
        },
    };
}
