import {
    BoldItalicUnderlineToggles,
    CreateLink,
    headingsPlugin,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    markdownShortcutPlugin,
    MDXEditor,
    MDXEditorMethods,
    quotePlugin,
    toolbarPlugin,
} from "@mdxeditor/editor";
import React from "react";
import { cn } from "../../../lib/utils";

function EditorToolbar() {
    return (
        <div className={"flex w-full flex-col"}>
            <div className={"my-2 flex flex-row items-center gap-2"}>
                <BoldItalicUnderlineToggles />
                <CreateLink />
            </div>
            <hr className={"mx-2"} />
        </div>
    );
}

type EditorProps = {
    markdown: string;
    onChange: (markdown: string) => void;
    editorRef?: React.LegacyRef<MDXEditorMethods>;
    hideToolbar?: boolean;
    className?: string;
    placeholder?: string;
};

export function TekkrMarkdownEditor(props: EditorProps) {
    const onChange = (markdown: string) => {
        const modified = markdown.replaceAll("\n\n\n", "\n\n&#xA;\n");
        props.onChange(modified);
    };
    return (
        <MDXEditor
            ref={props.editorRef}
            className={cn("rounded-md bg-input px-1 text-foreground", props.className)}
            onChange={onChange}
            markdown={props.markdown ?? ""}
            contentEditableClassName={"mdxeditor-prose"}
            placeholder={props.placeholder}
            plugins={[
                headingsPlugin(),
                listsPlugin(),
                quotePlugin(),
                markdownShortcutPlugin(),
                linkPlugin(),
                linkDialogPlugin(),
                ...(!props.hideToolbar
                    ? [
                          toolbarPlugin({
                              toolbarContents: EditorToolbar,
                          }),
                      ]
                    : []),
            ]}
        />
    );
}
