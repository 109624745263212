import { Dialog, DialogContent } from "../components/ui/dialog";
import React from "react";
import CustomerLogos from "../components/shared/customer-logos";
import { Calendar, CheckCircle, UserPlus } from "lucide-react";
import { Button } from "../components/ui/button";
import TeamAvatars from "../components/shared/team-avatars";

export default function UnlockAccessCtaDialog(
    props: React.PropsWithChildren & {
        open?: boolean;
        onOpenChange?: (open: boolean) => void;
    }
) {
    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            {props.children}
            <DialogContent className="flex flex-col items-center gap-8 p-12 text-center sm:max-w-lg">
                <div className={"flex flex-col gap-2"}>
                    <TeamAvatars className={"mb-2"} />
                    <h2>Unlock all Tekkr Playbooks</h2>
                    <p className={"text-muted-foreground"}>
                        Access the all-in-one solution for Tech Leaders to align efforts, remove bottlenecks and achieve
                        business milestones faster.
                    </p>
                </div>

                <div className={"flex flex-col gap-2 rounded-md border p-4 text-start text-sm"}>
                    <p className={"font-semibold"}>What's included?</p>
                    <p className={"flex flex-row items-center gap-3"}>
                        <CheckCircle className={"inline h-5 w-5 text-muted-foreground"} /> Access to all Tekkr Playbooks
                    </p>
                    <p className={"flex flex-row items-center gap-3"}>
                        <CheckCircle className={"inline h-5 w-5 text-muted-foreground"} /> Dedicated implementation
                        support
                    </p>
                    <p className={"flex flex-row items-center gap-3"}>
                        <CheckCircle className={"inline h-5 w-5 text-muted-foreground"} /> Built-in tracking tools that
                        give real-time insights
                    </p>
                    <p className={"flex flex-row items-center gap-3"}>
                        <UserPlus className={"inline h-5 w-5 text-muted-foreground"} /> Tekkr Leadership Advisory
                        (Optional)
                    </p>
                </div>

                <div className={"flex flex-row justify-center gap-4"}>
                    <a href={"https://tekkr.io/request-access"} target={"_blank"} rel="noreferrer">
                        <Button className={"transition-all duration-500 hover:drop-shadow-glow-primary"}>
                            <Calendar className={"me-2 inline h-5 w-5"} /> Book a Call
                        </Button>
                    </a>
                    <a href={"https://tekkr.io/pricing"} target={"_blank"} rel="noreferrer">
                        <Button variant={"outline"}>Learn more</Button>
                    </a>
                </div>

                <CustomerLogos className={"mt-6"} />
            </DialogContent>
        </Dialog>
    );
}
