import React from "react";

/**
 * use this to show something for a set duration of time after a trigger.
 * Calling the show() function again while visible resets the timer.
 * @param timeout duration to set "visible" to true for in ms, default 800
 */
export function useFlashVisibility(timeout: number = 800): [visible: boolean, show: () => void] {
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
    const onShow = () => {
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }
        const tid = setTimeout(() => {
            setTimeoutId(null);
        }, timeout);
        setTimeoutId(tid);
    };
    return [timeoutId !== null, onShow];
}
