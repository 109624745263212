import React from "react";
import { ArrowRight } from "lucide-react";

export function ArrowMarkdownComponent(props: React.PropsWithChildren) {
    return (
        <div className={"my-1 flex flex-row items-start gap-1"}>
            <ArrowRight className={"mr-1 inline-block shrink-0 p-1"} />
            <div>{props.children}</div>
        </div>
    );
}
