import { PaperclipIcon } from "lucide-react";
import React from "react";
import { getAttachmentType, PlaybookAttachmentTypes } from "../../controller/hooks/attachments";
import { cn } from "../../../../lib/utils";

export function AttachmentTypeView(props: { type: PlaybookAttachmentTypes; className?: string }) {
    const { AttachmentTypeIcon, attachmentTypeCopy } = getAttachmentType(props.type);
    return (
        <div className={cn("flex flex-row gap-2 text-xs opacity-65", props.className)}>
            <div>
                {AttachmentTypeIcon ? (
                    <AttachmentTypeIcon className={"h-4 w-4 shrink-0 opacity-65"} />
                ) : (
                    <PaperclipIcon className={"h-4 w-4 shrink-0"} />
                )}
            </div>
            <div>{attachmentTypeCopy.description}</div>
        </div>
    );
}
