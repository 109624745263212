"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.principlesContentEditSchema = exports.principlesContentBlueprintSchema = void 0;
const zod_1 = require("zod");
const blueprint_1 = require("../../blueprint");
exports.principlesContentBlueprintSchema = blueprint_1.editableContentBlueprintSchema
    .extend({
    type: zod_1.z.literal(blueprint_1.ContentType.principles),
})
    .strict();
exports.principlesContentEditSchema = zod_1.z.strictObject({
    principles: zod_1.z.array(zod_1.z.strictObject({
        id: zod_1.z.string(),
        title: zod_1.z.string(),
        subtitle: zod_1.z.string(),
        description: zod_1.z.string().optional(), // markdown
        positiveExamples: zod_1.z.string().optional(), // markdown
        negativeExamples: zod_1.z.string().optional(), // markdown
    })),
});
