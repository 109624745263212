import PlaybookStats from "./components/playbook-stats";
import React, { useRef } from "react";
import { Badge } from "../../ui/badge";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { PlaybookCategory } from "tekkr-common/dist/model/playbook/enums/enums";
import { playbookCategoryConfig } from "tekkr-common/src/model/playbook/enums/enums";
import Contenteditable from "../contenteditable";
import { cn } from "../../../lib/utils";
import { useScopedEdit } from "../../hooks/use-edit";
import { Button } from "../../ui/button";
import { DeleteIcon, ImageIcon } from "lucide-react";
import { UploadPlaybookImageDialog } from "../../../modals/upload-playbook-image";
import { DialogTrigger } from "../../ui/dialog";
import { usePlaybookOptional } from "../../../pages/playbook/controller/hooks/playbook";
import BlueprintStats from "./components/blueprint-stats";

interface Props {
    blueprint: PlaybookBlueprint;
    playbook?: {
        edit?: PlaybookEdit;
        imageUrl?: string;
    };
    isEditing?: boolean;
    actionBarItems?: PlaybookActionBarItem[];
    onReplaceImage?: () => void;
    mode: "blueprint" | "playbook";
}

function CategoryTagsBar(props: { categories: PlaybookCategory[] }) {
    return (
        <div className={"flex w-full flex-row items-center justify-between"}>
            <div className={"flex flex-row flex-wrap gap-2"}>
                {props.categories.map((category) => {
                    const config = playbookCategoryConfig[category];
                    return (
                        <Badge key={category} style={{ backgroundColor: config.color }}>
                            {config.title}
                        </Badge>
                    );
                })}
            </div>
        </div>
    );
}

export interface PlaybookActionBarItem {
    title?: string;
    onClick: () => void;
    icon: typeof DeleteIcon;
    variant?: "destructive";
    badge?: number;
}

function PlaybookActionsBar(props: { actions: PlaybookActionBarItem[] }) {
    return (
        <div className={"flex flex-row items-center gap-2"}>
            {props.actions.map((action) => {
                const Icon = action.icon;
                return (
                    <Button
                        onClick={action.onClick}
                        className={"group relative gap-2 border-input p-2 text-muted-foreground duration-300"}
                        size={"sm"}
                        variant={action.variant === "destructive" ? "outline-destructive" : "outline"}
                        key={action.title}
                    >
                        <Icon className={"h-5 w-5"} />
                        {action.title && <span className={"font-semibold"}>{action.title}</span>}
                        {action.badge && (
                            <div
                                className={
                                    "absolute -right-1 -top-1/4 rounded-full bg-muted-foreground px-1.5 text-background transition-all group-hover:bg-foreground"
                                }
                            >
                                {action.badge}
                            </div>
                        )}
                    </Button>
                );
            })}
        </div>
    );
}

function TitleInput(props: { playbookEdit?: PlaybookEdit; blueprint: PlaybookBlueprint; isEditing: boolean }) {
    const { state, updateEdit } = useScopedEdit(props.playbookEdit, (e) => ({
        title: e?.title ?? props.blueprint.title,
    }));
    const updateTitle = (title: string) => {
        updateEdit(() => {
            if (props.playbookEdit) {
                props.playbookEdit.title = title;
            }
        });
    };
    let err: string | null = null;
    if (state.title.length > 100) {
        err = "This title is too long.";
    } else if (state.title.length < 1) {
        err = "Please enter a title for this playbook.";
    }

    const scaleContainerRef = useRef<HTMLDivElement>(null);
    const backgroundContainerRef = useRef<HTMLDivElement>(null);

    const titleClasses = "font-extrabold text-3xl";

    return (
        <div className={"duration-300 animate-in slide-in-from-bottom-2"}>
            <div
                ref={backgroundContainerRef}
                className={cn(
                    props.isEditing && "bg-input",
                    "-my-2 rounded-sm py-2 transition-all duration-200",
                    err ? "border border-destructive" : undefined
                )}
            >
                <div
                    style={{
                        transform: props.isEditing ? "scale(0.975)" : undefined,
                    }}
                    ref={scaleContainerRef}
                    className={"transition-all"}
                >
                    {props.isEditing ? (
                        <Contenteditable
                            className={cn(titleClasses, "outline-none")}
                            value={state.title}
                            onChange={updateTitle}
                        ></Contenteditable>
                    ) : (
                        <div className={titleClasses}>{state.title}</div>
                    )}
                </div>
            </div>
            {err && <p className={"mt-2 text-sm font-semibold text-destructive"}>{err}</p>}
        </div>
    );
}

export function PlaybookHeader(props: React.PropsWithChildren & Props) {
    const [imageDialogOpen, setImageDialogOpen] = React.useState(false);

    const pb = usePlaybookOptional();

    return (
        <div className={"flex flex-col gap-4"}>
            <div className={"flex flex-row items-center justify-between duration-300 animate-in fade-in zoom-in-95"}>
                <CategoryTagsBar categories={props.blueprint.categories} />
                {props.actionBarItems && <PlaybookActionsBar actions={props.actionBarItems} />}
            </div>
            <TitleInput
                playbookEdit={props.playbook?.edit}
                blueprint={props.blueprint}
                isEditing={props.isEditing ?? false}
            />
            {props.mode === "blueprint" && <BlueprintStats pb={props.blueprint} />}
            {props.mode === "playbook" && <PlaybookStats isEditing={props.isEditing ?? false} />}
            {props.children}
            <div className={"relative w-full"}>
                <img
                    alt={"Playbook Cover"}
                    className={
                        "max-h-[45vh] min-h-96 w-full rounded-lg border-0 object-cover duration-700 animate-in fade-in slide-in-from-bottom-8"
                    }
                    src={props.playbook?.imageUrl ?? props.blueprint.imageUrl}
                />
                {props.isEditing && props.onReplaceImage !== undefined && (
                    <UploadPlaybookImageDialog
                        open={imageDialogOpen}
                        onOpenChange={setImageDialogOpen}
                        onImageUploaded={() => pb?.refetch()}
                    >
                        <DialogTrigger asChild>
                            <Button
                                onClick={props.onReplaceImage}
                                variant={"outline-glass"}
                                className={
                                    "absolute bottom-2 right-2 gap-2 border-none bg-black/45 text-white animate-in fade-in-50 slide-in-from-bottom-4 hover:bg-black/55 hover:text-white"
                                }
                            >
                                <ImageIcon className={"h-5 w-5"} />
                                <span className={"drop-shadow-2xl"}>Replace Image</span>
                            </Button>
                        </DialogTrigger>
                    </UploadPlaybookImageDialog>
                )}
            </div>
            <hr className={"my-6"} />
        </div>
    );
}
