import { Sheet, SheetContent, SheetDescription, SheetTitle } from "../../../components/ui/sheet";
import {
    getAttachmentType,
    getPlaybookAttachmentTypes,
    PlaybookAttachmentTypes,
    usePlaybookAttachments,
    useResolvedAttachmentReference,
} from "../controller/hooks/attachments";
import React from "react";
import { usePlaybook } from "../controller/hooks/playbook";
import { PlaybookAttachment } from "tekkr-common/dist/model/playbook/attachments";
import { AttachmentTypeView } from "./attachments/attachment-type";
import { Link } from "react-router-dom";
import { CreateAttachmentInterface } from "./attachments/create-attachment-interface";
import { Button } from "../../../components/ui/button";
import { ChevronDown, FilePlus2, PaperclipIcon, TrashIcon, X } from "lucide-react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import Spinner from "../../../components/ui/spinner";
import { cn } from "../../../lib/utils";
import { RemoveAttachmentDialog } from "./attachments/remove-attachment-dialog";
import { DialogTrigger } from "../../../components/ui/dialog";
import { AttachmentPreviewTooltip } from "./attachments/attachment-preview-tooltip";
import { TooltipTrigger } from "../../../components/ui/tooltip";
import * as SheetPrimitive from "@radix-ui/react-dialog";
import { useOrg } from "../../../auth/org-provider";

function PlaybookDocumentItem(props: { attachment: PlaybookAttachment }) {
    const meta = useResolvedAttachmentReference(props.attachment.id);
    return (
        <div className={"flex flex-row items-stretch gap-4 rounded-lg border p-2 transition-all hover:bg-accent"}>
            <Link to={meta.url} target={"_blank"} className={"flex grow flex-row gap-4"}>
                <AttachmentPreviewTooltip attachmentId={props.attachment.id}>
                    <TooltipTrigger>
                        <div className={"h-24 w-24 shrink-0 overflow-hidden rounded-md bg-accent"}>
                            {meta.imageUrl && (
                                <img
                                    alt={"Thumbnail"}
                                    className={"h-24 w-24 bg-white object-cover object-top"}
                                    referrerPolicy="no-referrer"
                                    src={meta.imageUrl}
                                />
                            )}
                        </div>
                    </TooltipTrigger>
                </AttachmentPreviewTooltip>
                <div className={"flex grow flex-col items-start justify-center gap-1"}>
                    <AttachmentTypeView
                        type={meta.type}
                        className={
                            "origin-top-left scale-90 rounded-full bg-muted px-2 py-1 font-semibold text-foreground"
                        }
                    />
                    <div className={"font-semibold"}>{meta.title}</div>
                    {meta.description && (
                        <div className={"line-clamp-1 text-sm font-medium text-muted-foreground"}>
                            {meta.description}
                        </div>
                    )}
                </div>
            </Link>
            <RemoveAttachmentDialog attachmentTitle={meta.title} attachmentId={props.attachment.id}>
                <div className={"flex shrink-0 flex-col"}>
                    <DialogTrigger asChild>
                        <Button
                            variant={"ghost"}
                            className={"h-8 w-8 px-0 py-0 text-muted-foreground hover:text-foreground"}
                        >
                            <TrashIcon className={"h-4 w-4"} />
                        </Button>
                    </DialogTrigger>
                </div>
            </RemoveAttachmentDialog>
        </div>
    );
}

function PlaybookDocumentList() {
    const { attachments } = usePlaybookAttachments();
    return (
        <div className={"flex grow flex-col gap-3"}>
            {attachments?.map((document) => <PlaybookDocumentItem attachment={document} key={document.id} />)}
        </div>
    );
}

function AddDocumentButton() {
    const org = useOrg();
    const documentTypes = getPlaybookAttachmentTypes(org.officeSuiteProvider);
    return (
        <CreateAttachmentInterface
            render={({ createAttachment, isPending }) => (
                <div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button disabled={isPending} size={"sm"} className={"relative"}>
                                <div className={cn("contents", isPending && "invisible")}>
                                    <FilePlus2 className={"me-2 h-5 w-5"} /> Add Document{" "}
                                    <ChevronDown className={"ms-2 h-4 w-4"} />
                                </div>
                                <Spinner className={cn("absolute", !isPending && "invisible")} />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className={"w-auto space-y-1"}>
                            {documentTypes.map((type) => {
                                const { AttachmentTypeIcon, attachmentTypeCopy } = getAttachmentType(type);
                                return (
                                    <DropdownMenuItem
                                        onClick={() =>
                                            createAttachment({
                                                type,
                                                target: undefined,
                                            })
                                        }
                                        key={type}
                                        className={"flex flex-row gap-2 text-sm font-medium text-foreground"}
                                    >
                                        {AttachmentTypeIcon ? (
                                            <AttachmentTypeIcon className={"h-5 w-5"} />
                                        ) : (
                                            <PaperclipIcon className={"h-5 w-5"} />
                                        )}
                                        {attachmentTypeCopy.description}
                                    </DropdownMenuItem>
                                );
                            })}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            )}
        />
    );
}

interface Props {
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

export function PlaybookAttachmentsSheet(props: React.PropsWithChildren<Props>) {
    const { pw } = usePlaybook();
    return (
        <Sheet open={props.open} onOpenChange={props.onOpenChange}>
            {props.children}
            <SheetContent hideClose={true} className={"flex flex-col overflow-y-scroll px-6 pt-0 sm:max-w-xl"}>
                <div className={"sticky top-0 z-50 flex flex-col gap-2 bg-background/90 pb-3 pt-6 backdrop-blur"}>
                    <SheetTitle>Playbook Documents</SheetTitle>
                    <SheetDescription>
                        These documents are part of the playbook &quot;
                        {pw.getTitle()}&quot;. You can attach documents directly to this playbook or attach them
                        throughout the content.
                    </SheetDescription>
                    <AddDocumentButton />
                    <SheetPrimitive.Close className="absolute right-0 top-6 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
                        <X className="h-4 w-4" />
                        <span className="sr-only">Close</span>
                    </SheetPrimitive.Close>
                </div>
                <PlaybookDocumentList />
            </SheetContent>
        </Sheet>
    );
}
