import { useEditableTextInMarkdownEdit } from "../../playbook-view/components/content/types/markdown";
import { cn } from "../../../../lib/utils";
import Contenteditable, { ContenteditableRef } from "../../contenteditable";
import { Edit3 } from "lucide-react";
import React, { useRef } from "react";

export function InlineEditableMarkdownComponent(props: { id: string; text: string }) {
    const segmentContext = useEditableTextInMarkdownEdit(props.id, props.text);

    if (!segmentContext) {
        return <>{props.text}</>;
    }

    return <InlineEditableTextField {...segmentContext} defaultValue={props.text} />;
}

type TextFieldProps = {
    isEditing: boolean;
    value?: string;
    setValue: (value: string) => void;
    defaultValue?: string;
    placeholder?: string;
};

export function InlineEditableTextField(props: React.HTMLProps<HTMLDivElement> & TextFieldProps) {
    const { isEditing, value, setValue } = props;
    const isEmpty = value !== undefined && value.trim().length === 0 && !props.placeholder;

    const contenteditableRef = useRef<ContenteditableRef>(null);

    return (
        <div className={cn("inline cursor-text", props.className)}>
            <div
                className={cn(
                    "-mt-0.5 inline flex-row items-center gap-2 py-0.5 caret-primary transition-all",
                    isEditing && "rounded-md bg-input px-2 hover:bg-muted/70",
                    isEditing && isEmpty && "bg-red-500/20 outline outline-red-500"
                )}
            >
                {isEditing && (
                    <>
                        <Contenteditable
                            ref={contenteditableRef}
                            className={cn("inline focus:outline-none", isEditing && "font-semibold")}
                            value={(value !== undefined ? value : props.defaultValue) ?? ""}
                            onChange={setValue}
                        ></Contenteditable>
                    </>
                )}
                {isEditing && props.placeholder && !value && (
                    <span
                        onClick={() => contenteditableRef.current?.focus()}
                        className={cn("opacity-65", isEmpty && "text-red-500")}
                    >
                        {props.placeholder}
                    </span>
                )}
                {!isEditing && <span>{value}</span>}
                <Edit3
                    className={cn(
                        "inline h-3 w-0 -translate-x-12 opacity-0 transition-all animate-in",
                        isEditing && "ms-2 w-3 translate-x-0 opacity-100",
                        !isEditing && "hidden"
                    )}
                />
            </div>
        </div>
    );
}
