import React from "react";
import { SparklesIcon } from "lucide-react";
import { useOrg } from "../../../auth/org-provider";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../service/tekkr-service";
import BlueprintCard from "../../../components/shared/cards/blueprint-card";
import { DiscoveryPageSectionTitle } from "../discovery-page";
import Spinner from "../../../components/ui/spinner";

export function PlaybookRecommendations() {
    const org = useOrg();

    const { data: recommendations, error } = useQuery({
        queryKey: ["discovery", "recommendations", org.id],
        queryFn: async () => {
            const res = await apiClient.getRecommendedBlueprints();
            if (res.status !== 200) throw new Error("failed to fetch recommendations");
            return res.body;
        },
    });

    if (error) {
        // we just hide this view if something goes wrong
        return null;
    }

    return (
        <div>
            <DiscoveryPageSectionTitle icon={SparklesIcon}>Recommended for {org.name}</DiscoveryPageSectionTitle>
            {!recommendations && (
                <div
                    className={
                        "flex flex-row justify-center gap-3 rounded-md p-4 text-sm font-medium opacity-65 outline outline-accent"
                    }
                >
                    <Spinner />
                    Loading Recommendations...
                </div>
            )}
            {recommendations && (
                <div className={"grid grid-cols-1 items-start gap-4 lg:grid-cols-2"}>
                    <div className={"rounded-lg bg-background drop-shadow-glow-primary"}>
                        <div className={"flex flex-col bg-accent"}>
                            <BlueprintCard layout={"horizontal"} data={recommendations.mainRecommendation} />
                            <div className={"p-4 text-sm"}>
                                <span className={"font-medium"}>"{recommendations.justification}"</span>
                                <span className={"opacity-65"}>&nbsp;- Tekkr Assistant</span>
                            </div>
                        </div>
                    </div>
                    <div className={"mt-4 flex flex-col gap-2 lg:-mt-7"}>
                        <span className={"text-sm font-semibold"}>More Recommended Playbooks</span>
                        {recommendations.otherRecommendations.map((bp) => (
                            <BlueprintCard key={bp.id} variant={"compact"} layout={"horizontal"} data={bp} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
