import React from "react";
import { useColorScheme } from "../../lib/color-scheme";
import Spinner from "../ui/spinner";
import { ReactComponent as TekkrLogoLight } from "../../static/tekkr-logo-script-light.svg";
import { ReactComponent as TekkrLogoDark } from "../../static/tekkr-logo-script-dark.svg";

export function FullScreenLoader() {
    const { isDark } = useColorScheme();
    return (
        <div
            className={
                "fixed bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center gap-4 duration-700 animate-in fade-in-0"
            }
        >
            {isDark ? <TekkrLogoDark /> : <TekkrLogoLight />}
            <Spinner />
        </div>
    );
}
