import { Button } from "../ui/button";
import { Mail } from "lucide-react";
import React from "react";
import TeamAvatars from "./team-avatars";

export default function HelpFooter(props: { variant?: "secondary" }) {
    return (
        <div className={"flex w-full flex-col gap-2"}>
            <TeamAvatars />
            <p>
                Need help?
                <br />
                <span className={"text-center text-sm text-muted-foreground"}>
                    Our ground control is ready to assist!
                </span>
            </p>
            <div className={"mt-4 flex flex-col items-center"}>
                <Button variant={props.variant} asChild>
                    <a href={"https://www.tekkr.io/contact-us"}>
                        <Mail className={"mr-2 h-4 w-4"} />
                        Write a message
                    </a>
                </Button>
            </div>
        </div>
    );
}
