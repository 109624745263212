import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import React, { ReactElement } from "react";
import Spinner from "../components/ui/spinner";
import { useMutation } from "@tanstack/react-query";

export interface ConfirmationDialogConfig {
    title: string;
    body: ReactElement;
    confirm: () => Promise<unknown>;
    confirmButtonText?: string;
    confirmButtonVariant?: "destructive";
    open?: boolean;
    onClose?: () => void;
}

export default function ConfirmationDialog(props: React.PropsWithChildren<ConfirmationDialogConfig>) {
    const { isPending, mutate } = useMutation({
        mutationFn: props.confirm,
    });
    const ConfirmButtonWrapper = props.onClose ? React.Fragment : DialogClose;
    return (
        <Dialog open={isPending || props.open} onOpenChange={!isPending ? props.onClose : undefined}>
            {props.children}
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle className={"text-start"}>{props.title}</DialogTitle>
                </DialogHeader>
                {!isPending && (
                    <>
                        {props.body}
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant={"secondary"}>Cancel</Button>
                            </DialogClose>
                            <ConfirmButtonWrapper asChild={true}>
                                <Button onClick={() => mutate()} variant={props.confirmButtonVariant}>
                                    {props.confirmButtonText ?? "Yes"}
                                </Button>
                            </ConfirmButtonWrapper>
                        </DialogFooter>
                    </>
                )}
                {isPending && (
                    <div className={"flex w-full flex-col items-center justify-center p-6"}>
                        <Spinner />
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
}
