import {
    ExternalLinkContentBlueprint,
    ExternalLinkStyle,
} from "tekkr-common/dist/model/playbook/segment/content/types/external-link/blueprint";
import { Link } from "react-router-dom";
import { BookOpen, CircleDollarSign, Link as LinkIcon } from "lucide-react";
import { SegmentContentViewProps } from "../segment-content";
import React from "react";
import { useGlobalDialogs } from "../../../../../../modals/global-dialogs";

function ExternalLinkToTool({ content }: { content: ExternalLinkContentBlueprint }) {
    return (
        <div
            className={
                "relative grid grid-cols-3 items-center justify-between space-x-4 rounded-md border px-4 py-3 transition-all hover:bg-accent"
            }
        >
            <img
                src={content.imgUrl}
                alt={content.title}
                className={"aspect-3/2 overflow-hidden rounded-md bg-accent object-cover"}
                style={{ objectPosition: "top left" }}
            />
            <div className={"col-span-2 pe-8"}>
                <h4>{content.title}</h4>
                {content.description ? <p className={"text-muted-foreground"}>{content.description}</p> : null}
            </div>
            <LinkIcon className={"absolute right-4 top-4 h-4 w-4 opacity-65"} />
        </div>
    );
}

function ExternalLinkToBook({ content }: { content: ExternalLinkContentBlueprint }) {
    return (
        <div className={"group relative flex flex-row items-stretch gap-2 transition-all"}>
            <img
                src={content.imgUrl}
                alt={content.title}
                className={"h-24 max-w-24 shrink-0 overflow-hidden rounded-sm bg-accent object-cover"}
                style={{ objectPosition: "top left" }}
            />
            <div
                className={
                    "relative flex w-full flex-col justify-center rounded-md px-3 py-1 pe-8 transition-all group-hover:bg-accent"
                }
            >
                <BookOpen className={"mb-2 h-5 w-5 text-muted-foreground"} />
                <h4>{content.title}</h4>
                {content.description ? <p className={"text-muted-foreground"}>{content.description}</p> : null}
                <div
                    className={
                        "absolute bottom-2 right-4 flex hidden flex-row items-center gap-2 text-xs text-muted-foreground opacity-0 transition-all duration-500 group-hover:opacity-65"
                    }
                >
                    <CircleDollarSign className={"inline h-4 w-4"} /> this is a referral link
                </div>
            </div>
        </div>
    );
}

export default function ExternalLinkSegmentContentView(props: SegmentContentViewProps<ExternalLinkContentBlueprint>) {
    const globalDialogs = useGlobalDialogs();
    const onClick =
        props.content.link === "https://app.tekkr.io/unlock"
            ? () => {
                  globalDialogs?.showUnlockAllPlaybooksCta();
              }
            : undefined;

    const content = (
        <>
            {props.content.style === ExternalLinkStyle.tool && <ExternalLinkToTool content={props.content} />}
            {props.content.style === ExternalLinkStyle.book && <ExternalLinkToBook content={props.content} />}
        </>
    );

    if (onClick) {
        return (
            <div onClick={onClick} className={"cursor-pointer"}>
                {content}
            </div>
        );
    } else {
        return (
            <Link target={"_blank"} to={props.content.link} className={"my-2"}>
                {content}
            </Link>
        );
    }
}
