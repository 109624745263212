import { ChevronsUpDown, Users2, X } from "lucide-react";
import React from "react";
import { Button } from "../../../../../ui/button";
import { PopoverTrigger } from "../../../../../ui/popover";
import { useIdentifierList } from "../../../../../hooks/set-list";
import { PeopleListContentBlueprint } from "tekkr-common/dist/model/playbook/segment/content/types/people-list/blueprint";
import { useOrg } from "../../../../../../auth/org-provider";
import { cn } from "../../../../../../lib/utils";
import CreatePersonDialog from "../../../../../../modals/create-person-dialog";
import { TekkrMarkdown } from "../../../../markdown/tekkr-markdown";
import { SegmentContentViewProps } from "../segment-content";
import { usePlaybookPeopleGroups } from "../../../../../../pages/playbook/controller/hooks/people-groups";
import { useScopedEdit } from "../../../../../hooks/use-edit";
import { AvatarForUser } from "../../../../avatar-for-user";
import { UserDropdown } from "../../../../user-dropdown";

interface Person {
    id: string;
    name: string;
    title?: string;
}

interface Props {
    options?: {
        hideTitle?: boolean;
        disableGridAlignment?: boolean;
        placeholder?: string;
    };
}

export default function PeopleListSegmentContentView({
    edit,
    content,
    isEditing,
    options,
}: SegmentContentViewProps<PeopleListContentBlueprint, Props>) {
    const { updateEdit } = useScopedEdit(edit, () => null);
    let startingList: string[] = [];
    if (!content.peopleGroupId && edit && "selectedUsers" in edit) {
        startingList = edit.selectedUsers ?? [];
    }
    const localList = useIdentifierList(startingList, (selected) => {
        if (edit) {
            updateEdit(() => {
                edit.selectedUsers = selected;
            });
        }
    });
    const peopleGroups = usePlaybookPeopleGroups();

    const [selected, add, remove] = content.peopleGroupId ? peopleGroups.byId(content.peopleGroupId) : localList;

    const org = useOrg();
    const orgPeople: Record<string, Person> = Object.fromEntries(org.users.map((u) => [u.id, u]));

    return (
        <div className={!options?.disableGridAlignment ? "grid grid-cols-4" : "flex flex-row items-center gap-2"}>
            <CreatePersonDialog context={"playbook"}></CreatePersonDialog>
            {!options?.hideTitle && (
                <div className={"me-4 flex-shrink-0 py-2 align-top font-semibold"}>{content.title}:</div>
            )}
            <div className={cn("col-span-3", content.description && "rounded-md border p-2")}>
                <div className={"flex w-full flex-row flex-wrap gap-2"}>
                    {isEditing && (!content.limit || selected.length < content.limit) ? (
                        <UserDropdown
                            users={Object.values(orgPeople).filter((p) => !selected.includes(p.id))}
                            onSelect={(id) => add(id)}
                        >
                            <PopoverTrigger asChild>
                                <Button className="w-42 h-9 justify-between bg-input text-foreground hover:bg-input hover:text-muted-foreground">
                                    {options?.placeholder ?? "Add someone"}
                                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                        </UserDropdown>
                    ) : null}
                    {Array.from(selected.entries()).map(([, p]) => {
                        const person = orgPeople[p] ?? {
                            id: "deleted",
                            displayName: "Deleted User",
                        };
                        return (
                            <div
                                key={p}
                                className={
                                    "flex h-9 cursor-default flex-row items-center gap-2 rounded-md bg-input px-2"
                                }
                            >
                                <AvatarForUser className={"h-6 w-6"} user={person} />
                                <span className={"text-sm"}>{person.name}</span>
                                {isEditing ? (
                                    <X onClick={() => remove(p)} className={"h-4 w-4 cursor-pointer"} />
                                ) : null}
                            </div>
                        );
                    })}
                    {!isEditing && selected.length === 0 ? (
                        <div
                            className={
                                "flex h-9 cursor-default flex-row items-center gap-2 rounded-md border border-secondary p-3 text-muted-foreground"
                            }
                        >
                            <Users2 className={"h-4 w-4"} />
                            <div>To be selected</div>
                        </div>
                    ) : null}
                </div>
                {content.description && (
                    <TekkrMarkdown className={"mt-2 w-full"} markdown={content.description}></TekkrMarkdown>
                )}
            </div>
        </div>
    );
}
