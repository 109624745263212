"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookTargetsSchema = void 0;
const zod_1 = require("zod");
exports.playbookTargetsSchema = zod_1.z.array(zod_1.z.union([
    zod_1.z.strictObject({
        id: zod_1.z.string(),
    }),
    zod_1.z.strictObject({
        title: zod_1.z.string(),
        description: zod_1.z.string(),
    }),
]));
