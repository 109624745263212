import { PlaybookWrapper } from "tekkr-common/dist/lib/playbook-wrapper";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import { PeopleListContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/people-list/edit";
import { PeopleListContentBlueprint } from "tekkr-common/dist/model/playbook/segment/content/types/people-list/blueprint";

interface AffectedStakeholder {
    userId: string;
    affectedIn: {
        segmentId: string;
        contentId: string;
    }[];
}

export function getAffectedStakeholders(pw: PlaybookWrapper, currentUserId: string): AffectedStakeholder[] {
    const resMap: Record<string, AffectedStakeholder> = {};
    pw.forEachContentWhere({ type: ContentType.peopleList }, ({ content, contentEdit, segment }) => {
        const peopleListContent = content as PeopleListContentBlueprint;
        const peopleListEdit = contentEdit as PeopleListContentEdit | undefined;
        if (!peopleListEdit || !peopleListEdit.selectedUsers || !peopleListContent.communicate) {
            return;
        }
        for (const person of peopleListEdit.selectedUsers) {
            if (person === currentUserId) {
                // skip the current user
                continue;
            }
            if (!resMap[person]) {
                resMap[person] = { userId: person, affectedIn: [] };
            }
            resMap[person].affectedIn.push({
                segmentId: segment.id,
                contentId: peopleListContent.id,
            });
        }
    });
    return Object.values(resMap);
}
