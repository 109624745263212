import React from "react";
import { StagePageContainer } from "../stage-container";
import { getAffectedStakeholders } from "./communication";
import { usePlaybook } from "../../controller/hooks/playbook";
import { useCurrentOrgUser, useOrgUserMap } from "../../../../auth/org-provider";
import { PlaybookWrapper } from "tekkr-common/dist/lib/playbook-wrapper";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import { OrgUser } from "../../../../service/tekkr-service-types";
import { MessagesSquare } from "lucide-react";
import { TekkrCard } from "../../../../components/shared/tekkr-card";
import { prompts } from "../../../../service/prompts";
import EditableTextSegmentContentView from "../../../../components/shared/playbook-view/components/content/types/editable-text";
import { EditScope, useScopedEdit } from "../../../../components/hooks/use-edit";
import { usePlaybookCommunicationEdit } from "../../controller/hooks/communication-edit";
import { PlaybookCommunication } from "tekkr-common/dist/model/playbook/stages/communication";
import { Badge } from "../../../../components/ui/badge";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Dialog, DialogContent } from "../../../../components/ui/dialog";
import PlaybookSegment from "../../../../components/shared/playbook-view/components/segment";
import { noop } from "lodash";
import { TekkrMarkdown } from "../../../../components/shared/markdown/tekkr-markdown";
import { useBlueprint } from "../../controller/hooks/blueprint";

interface Responsibility {
    segmentId: string;
    contentId: string;
    contentTitle: string;
    segmentTitle: string;
    segmentName: string;
}

function SegmentPreviewDialog(props: { segmentId: string; onClose: () => void }) {
    const { blueprint } = useBlueprint();
    const { playbook } = usePlaybook();
    const segmentBlueprint = blueprint.segments.find((s) => s.id === props.segmentId)!;
    const segmentEdit = playbook.edit?.segments?.[props.segmentId];
    return (
        <Dialog open={true} onOpenChange={(open) => (open ? noop() : props.onClose())}>
            <DialogContent className={"mt-8 max-w-3xl border-transparent bg-transparent"}>
                <div className={"max-h-screen pb-24 pt-8"}>
                    <div className={"max-h-full overflow-y-scroll rounded-lg"}>
                        <PlaybookSegment
                            expandedByDefault={true}
                            segment={segmentBlueprint}
                            segmentEdit={segmentEdit}
                            index={0}
                            isEditing={false}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

function ResponsibilityChip(props: { responsibility: Responsibility }) {
    const [showPreview, setShowPreview] = React.useState(false);
    return (
        <div className={"mt-1 flex flex-row items-center overflow-clip"}>
            {showPreview && (
                <SegmentPreviewDialog
                    segmentId={props.responsibility.segmentId}
                    onClose={() => setShowPreview(false)}
                />
            )}
            <div
                onClick={() => setShowPreview(true)}
                className={
                    "flex cursor-pointer flex-row items-center gap-3 rounded-xl bg-accent py-2 pe-4 ps-3 transition-all hover:bg-input"
                }
            >
                <Badge variant={"background"}>{props.responsibility.segmentName}</Badge>
                <div className={"flex flex-col items-start"}>
                    <TekkrMarkdown className={"font-semibold"} markdown={props.responsibility.segmentTitle} />
                    <span className={"text-xs"}>{props.responsibility.contentTitle}</span>
                </div>
            </div>
        </div>
    );
}

function AffectedStakeholderView(props: {
    playbookWrapper: PlaybookWrapper;
    communicationEdit: PlaybookCommunication;
    user: OrgUser;
    affectedIn: { segmentId: string; contentId: string }[];
}) {
    const { playbookWrapper: pw, user } = props;

    const affectedStakeholdersEdit = props.communicationEdit.affectedStakeholders;
    if (!affectedStakeholdersEdit[user.id]) {
        affectedStakeholdersEdit[user.id] = {
            done: false,
            message: {},
        };
    }

    const { state, updateEdit } = useScopedEdit(affectedStakeholdersEdit[user.id], (e) => ({
        done: e.done,
    }));
    const toggleDone = () => {
        updateEdit((e) => {
            e.done = !e.done;
        });
    };

    const responsibilities: Responsibility[] = props.affectedIn.map((affectedIn) => {
        const { content } = props.playbookWrapper.getContentAndEdit(
            affectedIn.segmentId,
            affectedIn.contentId,
            ContentType.peopleList
        );
        const { segment } = props.playbookWrapper.getSegmentAndEdit(affectedIn.segmentId);
        return {
            ...affectedIn,
            contentTitle: content.title,
            segmentTitle: segment.title,
            segmentName: segment.name,
        };
    });

    const generateMessage = async () => {
        return await prompts.communicateInformAffectedStakeholderMessage({
            playbookId: pw.playbook.id,
            dynamicVars: {
                responsibilities_list: responsibilities
                    .map((r) => `- ${r.contentTitle} for/in ${r.segmentTitle}`)
                    .join("\n"),
                affected_user_name: props.user.title ? `${props.user.name} (${props.user.title})` : props.user.name,
            },
        });
    };

    return (
        <TekkrCard cardStyle={state.done ? "confirmation" : "primary"}>
            <div className={"flex flex-row items-center justify-between"}>
                <h3 className={"flex flex-row items-center"}>
                    <MessagesSquare className={"me-2 h-5 w-5"} />
                    Inform {props.user.name}
                </h3>
                <div className={"flex flex-row items-center gap-2"} onClick={toggleDone}>
                    <Checkbox variant={state.done ? "confirmation" : "primary"} checked={state.done} />
                    <p className={"cursor-pointer"}>done</p>
                </div>
            </div>
            <p className={"mt-2"}>{user.name} is mentioned in the following places in your playbook:</p>
            <div className={"mb-3 mt-1 flex flex-row flex-wrap gap-2 text-sm"}>
                {responsibilities.map((r) => (
                    <ResponsibilityChip key={r.segmentId + r.contentId} responsibility={r} />
                ))}
            </div>
            <hr className={"-mx-10 my-5"} />
            <h4 className={"mt-4"}>Send a Message</h4>
            <p className={"mb-3"}>
                You should send them a message letting them know about these new responsibilities. You can use the
                message below as your starting point:
            </p>
            <EditableTextSegmentContentView
                content={{
                    type: ContentType.editableText,
                    id: "message",
                }}
                edit={affectedStakeholdersEdit[user.id].message}
                isEditing={true}
                copy={"message"}
                textGenerator={generateMessage}
            />
        </TekkrCard>
    );
}

export function InformStakeholdersCommunicationPage() {
    const { pw } = usePlaybook();
    const { communicationEdit, updateCommunication } = usePlaybookCommunicationEdit();
    const users = useOrgUserMap();
    const currentUserId = useCurrentOrgUser().id;
    const affectedStakeholders = getAffectedStakeholders(pw, currentUserId);
    const onEditUpdated = () => {
        updateCommunication();
    };
    const onNext = () => {
        communicationEdit.informStakeholdersDone = true;
        updateCommunication();
    };
    return (
        <EditScope onEditUpdated={onEditUpdated}>
            <StagePageContainer
                onNext={onNext}
                header={"Inform Affected Stakeholders"}
                explainer={
                    "You have selected people to have specific responsibilities in this playbook. To ensure everyone is aware of what they need to do, it is important that you communicate these clearly."
                }
            >
                <div className={"flex flex-col gap-4"}>
                    {affectedStakeholders.map((as) => {
                        const user = users[as.userId];
                        return (
                            <AffectedStakeholderView
                                communicationEdit={communicationEdit}
                                playbookWrapper={pw}
                                user={user}
                                affectedIn={as.affectedIn}
                                key={as.userId}
                            />
                        );
                    })}
                </div>
            </StagePageContainer>
        </EditScope>
    );
}
